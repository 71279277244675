import { useEffect, useState } from "react";

import { ViewState } from "@devexpress/dx-react-scheduler";
import {
	Appointments,
	AppointmentTooltip,
	DateNavigator,
	DayView,
	MonthView,
	Scheduler,
	Toolbar,
	WeekView,
} from "@devexpress/dx-react-scheduler-material-ui";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { FaRegCalendar, FaRegClock, FaUser } from "react-icons/fa6";
import { IoClose, IoPencil } from "react-icons/io5";
import { SlNotebook } from "react-icons/sl";

import ExternalViewSwitcher from "./ExternalViewSwitcher";
import { formatTimeRange, formatTimezone } from "../../../utils";
import { NowRedLine } from "./NowRedLine";

import "./schedule_calendar.scss";

export function ScheduleCalendar({
	data,
	onClickReschedule, // function can be undefinded
}) {
	const [currentViewName, setCurrentViewName] = useState("Week");

	useEffect(() => {
		if (currentViewName === "Day" || currentViewName === "Week") {
			setTimeout(() => {
				const redLinePivot = document.getElementById("red-line-pivot");
				redLinePivot?.scrollIntoView();
			}, 0);
		}
	}, [currentViewName]);

	return (
		<div className="my-orka-schedule">
			<Scheduler height={600} data={data}>
				<ExternalViewSwitcher
					currentViewName={currentViewName}
					setCurrentViewName={setCurrentViewName}
				/>
				<ViewState currentViewName={currentViewName} defaultCurrentDate={new Date()} />
				<DayView startDayHour={0} endDayHour={24} cellDuration={60} />
				<WeekView startDayHour={0} endDayHour={24} cellDuration={60} />
				<Toolbar />
				<DateNavigator />
				<MonthView />
				<Appointments
					appointmentContentComponent={({ data }) => {
						const { timeText } = formatTimeRange(
							data.startDate,
							data.endDate
						);

						return (
							<div style={{ padding: 4 }}>
								<Typography className="appointment-text">
									{timeText}
								</Typography>
								<Typography className="appointment-text">{`${data.user.firstName} ${data.user.lastName}`}</Typography>
								<Typography className="appointment-text">
									{data.lesson.title}
								</Typography>
							</div>
						);
					}}
					appointmentComponent={({ data, children, ...props }) => {
						return (
							<Appointments.Appointment
								style={{
									backgroundColor: data.isInstructor ? "#64949A" : undefined,
								}}
								data={data}
								{...props}
							>
								{children}
							</Appointments.Appointment>
						);
					}}
				/>
				<AppointmentTooltip
					headerComponent={({ onHide, appointmentData }) => (
						<div className="tooltip-header">
							{appointmentData.canReschedule && (
								<Tooltip title="Rescedule">
									<IconButton onClick={() => {
										onClickReschedule?.(appointmentData);
										onHide();
									}}>
										<IoPencil className="normal-icon" />
									</IconButton>
								</Tooltip>
							)}
							<Tooltip title="Close">
								<IconButton onClick={onHide}>
									<IoClose className="normal-icon" />
								</IconButton>
							</Tooltip>
						</div>
					)}
					contentComponent={({ appointmentData }) => {
						const { dateText, timeText } = formatTimeRange(
							appointmentData.startDate,
							appointmentData.endDate
						);

						const studentName = `${appointmentData.user.firstName} ${appointmentData.user.lastName}`;

						return (
							<Grid container className="tooltip-content">
								<Grid className="icon-container" item xs={1}>
									<SlNotebook />
								</Grid>
								<Grid className="text-container" item xs={11}>
									<Typography className="normal-text bold">
										{appointmentData.lesson.course.name}
									</Typography>
								</Grid>
								<Grid className="icon-container" item xs={1} />
								<Grid className="text-container" item xs={11}>
									<Typography className="normal-text text">
										{appointmentData.lesson.title}
									</Typography>
								</Grid>
								<Grid className="icon-container" item xs={1}>
									<FaRegCalendar />
								</Grid>
								<Grid className="text-container" item xs={11}>
									<Typography className="normal-text text">
										{dateText}
									</Typography>
								</Grid>
								<Grid className="icon-container" item xs={1}>
									<FaRegClock />
								</Grid>
								<Grid className="text-container" item xs={11}>
									<Typography className="normal-text text">
										{timeText}
									</Typography>
								</Grid>
								<Grid className="icon-container" item xs={1}>
									<FaUser />
								</Grid>
								<Grid className="text-container" item xs={11}>
									<Typography className="normal-text text">
										{studentName}
									</Typography>
								</Grid>
							</Grid>
						);
					}}
				/>
			</Scheduler>
			<Typography className="normal-text">
				{`Note: Displayed date and time is in ${formatTimezone(
					-new Date().getTimezoneOffset() / 60
				)}`}
			</Typography>
			<NowRedLine currentViewName={currentViewName} />
		</div>
	);
}