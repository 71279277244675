import { Typography } from "@mui/material";
import NavbarOrka from "../../paginas/navegacion/Navbar";

import "./not_found.scss";

export function NotFound({ text }) {
	return (
		<>
			<NavbarOrka />
			<div className="not-found-container">
				<Typography className="not-found-text">{text}</Typography>
			</div>
		</>
	);
}
