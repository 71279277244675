import { InputAdornment, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";

export function CourseInfoInput({
	control,
	name,
	label,
	labelClassName,
	multiline,
	placeholder,
	inputClassName,
	rules,
	disallowInvalidInput,
	description,
	descriptionClassName,
	prefix,
	suffix,
	disabled,
}) {
	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({ field: { onChange, value }, fieldState: { error } }) => {
				return (
					<>
						<Typography component="p" className={labelClassName}>
							{label}
						</Typography>
						{description && (
							<Typography component="p" className={descriptionClassName}>
								{description}
							</Typography>
						)}
						<TextField
							helperText={error ? error.message : null}
							size="small"
							autoComplete="off"
							id={name}
							error={!!error}
							onChange={(e) => {
								if (disallowInvalidInput && rules?.pattern) {
									if (
										e.target.value !== "" &&
										!rules.pattern.value.test(e.target.value)
									) {
										return;
									}
								}
								onChange(e);
							}}
							value={value}
							fullWidth
							maxRows={5}
							multiline={multiline}
							placeholder={placeholder}
							variant="standard"
							disabled={disabled}
							InputProps={{
								disableUnderline: true,
								className: inputClassName,
								classes: {
									input: "input-field-inner",
								},
								startAdornment: prefix && (
									<InputAdornment position="start">
										<Typography className={inputClassName}>{prefix}</Typography>
									</InputAdornment>
								),
								endAdornment: suffix && (
									<InputAdornment position="end">
										<Typography className={inputClassName}>{suffix}</Typography>
									</InputAdornment>
								),
								onKeyDown(e) {
									if (!multiline && e.key === "Enter") {
										e.preventDefault();
									}
								},
							}}
						/>
					</>
				);
			}}
		/>
	);
}
