
const ReviewItemI = ({commentRating,course, date, user}) => {

    const {name} = course;
    const {firstName, lastName} = user;

    const formatDate = (date) => {
        const currentDate = new Date(date);
        return  currentDate.toLocaleString("en-US", {month: "long", year: "numeric"});
    }

    return(
        <div className="review-item_reviews">
            <div className="review-item_box">
                <h3 className="review-item-reviews-h3">{name}</h3>
                <p className="review-item-reviews-p">{formatDate(date)}, {firstName + ' ' + lastName}</p>
            </div>

            <p className="review-item-reviews-comment">
                {commentRating}
            </p>
        </div>
    )

}

export default ReviewItemI;