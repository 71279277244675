import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";

import { useMyOwnedCoursesStudentInit } from "../../../../hooks/useMyOwnedCoursesStudent";
import { NotFound } from "../../../common/NotFound/NotFound";
import NavbarOrka from "../../navegacion/Navbar";
import { CourseAviationStory } from "./components/CourseAviationStory/CourseAviationStory";
import { CourseBasicInformation } from "./components/CourseBasicInformation/CourseBasicInformation";
import { CourseLessons } from "./components/CourseLessons/CourseLessons";

import "./owned_course.scss";

export function OwnedCourse() {
	const params = useParams();
	const slug = params.slug ?? "";
	const { isNotFound, isLoading } = useMyOwnedCoursesStudentInit(slug);

	if (isLoading) {
		return null;
	}

	if (isNotFound) {
		return <NotFound text="Course not found" />;
	}

	return (
		<div style={{ height: "100vh" }}>
			<NavbarOrka chatScreen isHiThere isInHome />
			<Grid container className="owned-course-container">
				<Grid item xs={12} sm={12} md={6} lg={6} xl={7}>
					<CourseAviationStory />
					<CourseBasicInformation />
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
					<CourseLessons />
				</Grid>
			</Grid>
		</div>
	);
}
