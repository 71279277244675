import React from "react";

import { roles } from "../../../constants";
import { useAccount } from "../../../hooks/useAccount";
import AccountInformationPageInstructor from "./AccountInformationPageInstructor";
import AccountInformationPageStudent from "./AccountInformationPageStudent";

export default function Profile() {
	const { account } = useAccount();

	if (!account) {
		return null;
	}

	if (account.role === roles.STUDENT) {
		return <AccountInformationPageStudent />;
	}

	if (account.role === roles.INSTRUCTOR) {
		return <AccountInformationPageInstructor />;
	}
}
