import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { apis, callApi } from "../../../../../api";

const cacheTime = 10 * 60 * 1000;

export const ChatService = {
	isInitialized: false,
	initializedAt: null,
	clearDataTimeout: null,
	listeners: [],
	chatList: [],

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	saveChatList(chatList) {
		this.chatList = chatList;
		this.emitChanges();
	},

	updateChatItem(newChatItem) {
		const index = this.chatList.findIndex(
			(chatItem) => chatItem.CourseID === newChatItem.CourseID
		);

		if (index === -1) {
			return;
		}

		this.chatList[index] = { ...this.chatList[index], ...newChatItem };
		this.chatList = [...this.chatList];
		this.emitChanges();
	},

	clearData() {
		this.chatList = [];
		this.isInitialized = false;
		this.initializedAt = null;
		if (this.clearDataTimeout) {
			clearTimeout(this.clearDataTimeout);
			this.clearDataTimeout = null;
		}
		this.emitChanges();
	},

	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

export const ChatMessagesService = {
	listeners: [],
	messages: [],

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	saveMessages(messages) {
		this.messages = messages;
		this.emitChanges();
	},

	addMessage(message) {
		this.messages = [...this.messages, message];
		this.emitChanges();
	},

	clearData() {
		this.messages = [];
		this.emitChanges();
	},

	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

export function useChatInit() {
	const params = useParams();
	const chatId = params.chatId;

	useEffect(() => {
		const getChatList = async () => {
			try {
				const response = await callApi({
					method: "GET",
					url: apis.INBOX,
				});

				if (!response) {
					return;
				}

				ChatService.saveChatList(response);
			} catch (error) {
				return [];
			}
		};

		if (ChatService.clearDataTimeout) {
			clearTimeout(ChatService.clearDataTimeout);
			ChatService.clearDataTimeout = null;
		}

		if (
			!ChatService.isInitialized ||
			ChatService.initializedAt ||
			Date.now() - ChatService.initializedAt > cacheTime
		) {
			getChatList();
		}

		return () => {
			ChatService.clearData();
		};
	}, []);

	useEffect(() => {
		const getChatMessages = async () => {
			if (!chatId) {
				return;
			}

			const [, courseCode, , summaryId] = chatId.split("_");

			const response = await callApi({
				method: "GET",
				url: `${apis.INBOX}/${courseCode}/summary/${summaryId}`,
			});

			if (!response?.message) {
				return;
			}

			ChatMessagesService.saveMessages(response.message);
		};
		if (!chatId) {
			return;
		}

		getChatMessages();

		return () => {
			ChatMessagesService.clearData();
		};
	}, [chatId]);
}

export function useChatList() {
	const [chatList, setChatList] = useState(ChatService.chatList);

	useEffect(() => {
		const updateData = () => {
			setChatList(ChatService.chatList);
		};

		ChatService.addEventListener(updateData);

		return () => {
			ChatService.removeEventListener(updateData);
		};
	}, []);

	return { chatList };
}

export function useChatMessages() {
	const [messages, setMessages] = useState(ChatMessagesService.messages);

	useEffect(() => {
		const updateData = () => {
			setMessages(ChatMessagesService.messages);
		};

		ChatMessagesService.addEventListener(updateData);

		return () => {
			ChatMessagesService.removeEventListener(updateData);
		};
	}, []);

	const sendMessage = async (body) => {
		try {
			const response = await callApi({
				method: "POST",
				url: apis.MESSAGES,
				body,
			});

			console.log(response);
			if (!response) {
				return;
			}

			const { chat, status } = response;
			ChatMessagesService.addMessage(chat);
			ChatService.updateChatItem(status);
		} catch (error) {}
	};

	return { messages, sendMessage };
}
