export function OrkaFooter() {
  return (
    <div className="PieOrka">
      <hr style={{border: '1.4px solid #64949a'}}/>
      <div className="box__PieOrka-content">
        <div className="address">
          <div className="orkafooter AktivGroteskEx-Black">Orka</div>
          <p className="orkafoter-number">843.310.1471</p>
        </div>

        <div className="socialMedia">
          <p className="normal">#FlyORKA</p>
          <div className="iconos Green">
            <a href="https://www.facebook.com/flyorka" target="_blank">
              <i className="fa-brands fa-facebook-f mx-1"></i>
            </a>
            <a href="https://www.instagram.com/fly.orka/" target="_blank">
              <i className="fa-brands fa-instagram mx-1"></i>
            </a>
            <a href="https://www.linkedin.com/company/flyorka/?viewAsMember=true" target="_blank">
              <i className="fa-brands fa-linkedin-in mx-1"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
