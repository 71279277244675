import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { apis, callApi } from "../../../../api";
import { useAccount } from "../../../../hooks/useAccount";
import { StripeService, useStripe } from "../../../../hooks/useStripe";

export function StripeSuccess() {
	const { account } = useAccount();
	const { stripeData } = useStripe();
	const navigate = useNavigate();

	useEffect(() => {
		const onboardAccount = async () => {
			if (!account?.userId) {
				return;
			}

			if (!stripeData) {
				return;
			}

			if (stripeData.status === "ACTIVE") {
				const nextRoute = localStorage.getItem("nextRoute") ?? "/myorka";
				localStorage.removeItem("nextRoute");
				navigate(nextRoute, { replace: true });
				return;
			}

			try {
				const response = await callApi({
					method: "PATCH",
					url: apis.ONBOARD_ACCOUNT,
					body: {
						userCode: account.userId,
					},
				});
	
				if (!response?.account) {
					navigate("/myorka", { replace: true });
					return;
				}

				if (response.account.status === "REQUEST") {
					const nextRoute = localStorage.getItem("nextRoute") ?? "/myorka";
					localStorage.removeItem("nextRoute");
					navigate("/stripe", {
						replace: true,
						state: {
							nextRoute,
						},
					});
					return;
				}
	
				StripeService.updateStripeData(response.account);
				const nextRoute = localStorage.getItem("nextRoute") ?? "/myorka";
				localStorage.removeItem("nextRoute");
				navigate(nextRoute, { replace: true });
			} catch (error) {
				navigate("/myorka", { replace: true });
			}
		};

		onboardAccount();
	}, [account.userId, navigate, stripeData]);

	return <div />;
}
