import React, { useState, useEffect } from "react";

import { reqApi } from "../../../../../api";
import { Link } from "react-router-dom";
import { useLocalStorage } from "../../../CreateAccount/useLocalStorage";

function BodyItem(props) {

  const { getData ,role, course, email } = props;
  
  const [token] = useLocalStorage("token");
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteCourseId, setFavoriteCourseId] = useState(0);
  let [approvedOption, setOptionApproved] = useState('Approved');

  useEffect(() => {

    if (favoriteCourseId > 0) {

      const objectCourseFavorite = {
        courseId: favoriteCourseId,
        isFavorite: isFavorite
      };

      console.log('objectCourseFavorite ----> ', objectCourseFavorite);

      requestPatch('/courses/changeFavoriteCourseState', objectCourseFavorite);

    }

  }, [favoriteCourseId, isFavorite]);

  const requestPatch = async (url, data) => {

    try {

      const resp = await reqApi.patch(url, data,{
        headers: {Authorization: `Bearer ${token}`}
      });

      if(resp.status === 200){
        console.log('resp: ', resp);
        console.log(' FAVORITE TRUE / FALSE');
      }

      await getData();

    
    } catch (error) {
      console.log('There was an error to change approved attribute ---> ', error);
    }
  }

  const requestPatchApprovingRejectingCourse = async(url, data) => {

    try {

      const resp = await reqApi.patch(url, data,{
        headers: {Authorization: `Bearer ${token}`}
      });

      if(resp.status === 200){
        console.log('resp: ', resp);
        console.log('Aprovado / Rechazado');
      }

      await getData();

    
    } catch (error) {
      console.log('There was an error to change approved attribute ---> ', error);
    }
  }

  const handleApprovingRejectingCourse = (id) => {

    const objectCourseToApproved = {
      courseId: id,
      approved: approvedOption
    };

    console.log('objectCourseToApproved ----> ', objectCourseToApproved);

    requestPatchApprovingRejectingCourse('/courses/approving', objectCourseToApproved);

  }

  return (
    <div className="d-flex moderator-accordion-body_item flex-column">
      {role === 'TEACHER' ?
        <>
          {course?.map(({ id, name, favorite, approved, type }) => (
            <div className="d-flex justify-content-between" key={id}>
              <p className="moderator-accordion-title gray">{name}</p>

              <div className="d-flex movil-moderator-icons">
                {/* {isInstructor && ()} */}
                <div className="movil-moderator-flex">
                  <Link to={type === 'Online' ? `/PreviewOnline?courseId=${id}&isFromHome=${true}` : `/PreviewOnsite?courseId=${id}&isFromHome=${true}`}>
                    <i
                      className="fa-solid fa-magnifying-glass px-3 gray font-20 cursor-pointer"
                      //onClick={() => handleGoToCourse(id)}
                    ></i>
                  </Link>
                  <i
                    className={`${favorite ? "blueStarIconModerator": "gray"} fa-solid fa-star px-3 font-20 cursor-pointer `}
                    onClick={() => { setFavoriteCourseId(id); setIsFavorite(favorite ? false : true) }}
                  ></i>
                </div>
                <a href={`mailto:${email}`}>
                  <i className="fa-solid fa-envelope px-3 gray font-20 cursor-pointer"></i>
                </a>
                <i 
                className={`fa-solid fa-ban px-3 font-20 cursor-pointer ${approved === 'Rejected' ? 'blueStarIconModerator': 'gray'}`}
                onClick={
                  ()=>{
                      handleApprovingRejectingCourse(id); 
                      setOptionApproved(approved === 'Rejected' ? 'Approved': 'Rejected') 
                  }}
                ></i>
              </div>
            </div>
          ))}
        </>
        :
        <>
          {course?.map(({id,courseId, course, approved }) => (            
            <div className="d-flex justify-content-between" key={id}>
              <p className="moderator-accordion-title gray">{course.name}</p>
              <div className="d-flex movil-moderator-icons">
                <a href={`mailto:${email}`}>
                  <i className="fa-solid fa-envelope px-3 gray font-20 cursor-pointer"></i>
                </a>
                <i 
                className={`fa-solid fa-ban px-3 font-20 cursor-pointer ${course.approved === 'Rejected' ? 'blueStarIconModerator': 'gray'}`} 
                onClick={()=>{
                  handleApprovingRejectingCourse(courseId); 
                  setOptionApproved(course.approved === 'Rejected' ? 'Approved': 'Rejected'); 
                }}
                ></i>
              </div>
            </div>
          ))}
        </>
      }
    </div>
  );
}

export default BodyItem;
