import { useEffect, useState } from "react";

import { apis, callApi } from "../api";

const cacheTime = 10 * 60 * 1000;

export const StudentSummariesService = {
	isInitialized: false,
	initializedAt: null,
	clearDataTimeout: null,
	listeners: [],
	summaries: [],

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	saveSummaries(newSummaries) {
		this.summaries = newSummaries;
		this.emitChanges();
	},

	addNewBooking(summaryId, newBooking) {
		const summaryIndex = this.summaries.findIndex(
			(summary) => summary.id === summaryId
		);

		if (summaryIndex === -1) {
			return;
		}

		this.summaries[summaryIndex].unscheduledBookings = this.summaries[
			summaryIndex
		].unscheduledBookings.filter((booking) => booking.id !== newBooking.id);
		this.summaries[summaryIndex].upcomingBookings.push(newBooking);
		this.summaries = [...this.summaries];
		this.emitChanges();
	},

	clearData() {
		this.summaries = [];
		this.isInitialized = false;
		this.initializedAt = null;
		if (this.clearDataTimeout) {
			clearTimeout(this.clearDataTimeout);
			this.clearDataTimeout = null;
		}
		this.emitChanges();
	},

	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

export function useStudentSummariesInit() {
	const [isLoading, setIsLoading] = useState(
		!StudentSummariesService.isInitialized
	);

	useEffect(() => {
		const getSummaries = async () => {
			try {
				const response = await callApi({
					method: "GET",
					url: apis.SUMMARY_BY_STUDENT_TOKEN,
				});

				if (!response?.summaries) {
					return;
				}

				StudentSummariesService.saveSummaries(response.summaries);
			} catch (error) {
			} finally {
				StudentSummariesService.isInitialized = true;
				StudentSummariesService.initializedAt = Date.now();
				setIsLoading(false);
			}
		};

		if (StudentSummariesService.clearDataTimeout) {
			clearTimeout(StudentSummariesService.clearDataTimeout);
			StudentSummariesService.clearDataTimeout = null;
		}

		if (
			!StudentSummariesService.isInitialized ||
			!StudentSummariesService.initializedAt ||
			Date.now() - StudentSummariesService.initializedAt > cacheTime
		) {
			getSummaries();
		}
	}, []);

	useEffect(() => {
		return () => {
			if (
				!StudentSummariesService.isInitialized ||
				!StudentSummariesService.initializedAt
			) {
				return;
			}

			if (Date.now() - StudentSummariesService.initializedAt > cacheTime) {
				StudentSummariesService.clearData();
			} else {
				StudentSummariesService.clearDataTimeout = setTimeout(() => {
					StudentSummariesService.clearData();
				}, cacheTime - Date.now() + StudentSummariesService.initializedAt);
			}
		};
	}, []);

	return { isLoading };
}

export function useStudentSummaries() {
	const [summaries, setSummaries] = useState(StudentSummariesService.summaries);

	useEffect(() => {
		const updateSummaries = () => {
			setSummaries(StudentSummariesService.summaries);
		};

		StudentSummariesService.addEventListener(updateSummaries);

		return () => {
			StudentSummariesService.removeEventListener(updateSummaries);
		};
	}, []);

	return { summaries };
}
