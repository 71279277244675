import { useEffect, useState } from "react";
import { apis, callApi } from "../api";

export const InstructorSummariesService = {
	listeners: [],
	summaries: [],

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	saveSummaries(newSummaries) {
		this.summaries = newSummaries;
		this.emitChanges();
	},

	savePassedBookingBySummary(summaryId, bookingId, partialNewSummary, partialNewPassedBooking) {
		const summaryIndex = this.summaries.findIndex(
			(summary) => summary.id === summaryId
		);

		if (summaryIndex === -1) {
			return;
		}

		const passedBookingIndex = this.summaries[
			summaryIndex
		].passedBookings.findIndex((booking) => booking.id === bookingId);

		if (passedBookingIndex === -1) {
			return;
		}

		this.summaries[summaryIndex].passedBookings[passedBookingIndex] = {
			...this.summaries[summaryIndex].passedBookings[passedBookingIndex],
			...partialNewPassedBooking,
		};

		this.summaries[summaryIndex] = {
			...this.summaries[summaryIndex],
			...partialNewSummary,
		};

		this.summaries = [...this.summaries];
		this.emitChanges();
	},

	clearData() {
		this.summaries = [];
		this.emitChanges();
	},

	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

export function useInstructorSummaries() {
	const [summaries, setSummaries] = useState(
		InstructorSummariesService.summaries
	);

	useEffect(() => {
		const updateSummaries = () => {
			setSummaries(InstructorSummariesService.summaries);
		};

		InstructorSummariesService.addEventListener(updateSummaries);

		return () => {
			InstructorSummariesService.removeEventListener(updateSummaries);
		};
	}, []);

	const updateBooking = async (summaryId, bookingId, body) => {
		try {
			const response = await callApi({
				method: "PATCH",
				url: `${apis.UPDATE_MY_CLASSES}/${bookingId}`,
				body,
			});

			if (!response) {
				return;
			}

			const newSummary = response.summary;

			InstructorSummariesService.savePassedBookingBySummary(
				summaryId,
				bookingId,
				{
					airHours: newSummary.airHours,
					simulatorHours: newSummary.simulatorHours,
					milesFlown: newSummary.milesFlown,
					flightSummary: newSummary.flightSummary,
				},
				body,
			);
		} catch (error) {}
	};

	return { summaries, updateBooking };
}
