import { Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { apis, callApi } from "../../../api";
import useScreenSize from "../../../hooks/useScreenSize";
import { rules } from "../../../utils";
import { CommonInput } from "../../common";
import NavbarOrka from "../navegacion/Navbar";

import "./reset_password.scss";

export default function ResetPassword() {
	const params = useParams();
	const token = params.token;

	const { control, handleSubmit } = useForm({
		defaultValues: {
			email: "",
			password: "",
			confirmPassword: "",
		},
		mode: "onChange",
	});

	const [isTokenInvalid, setIsTokenInvalid] = useState(false);

	const navigate = useNavigate();

	const { width } = useScreenSize();
	const isMobile = width <= 768;

	const onClickResetPassword = async (data) => {
		try {
			const { email, password } = data;

			await callApi({
				method: "POST",
				url: apis.RESET_PASSWORD,
				body: {
					email,
					password,
					serviceToken: token,
				},
			});

			toast.success("Reset password successfully, now you can sign in again!");
			navigate("/sign-in", { replace: true });
		} catch (error) {
			if (error?.response?.status === 404) {
				if (error.response.data?.message === "User not found") {
					alert("This account does not exist!");
					return;
				}

				if (error.response.data?.message === "Invalid or Expired Token") {
					setIsTokenInvalid(true);
					return;
				}
			}
			alert("Something went wrong, please try again later!");
		}
	};

	if (isTokenInvalid) {
		return (
			<center>
				<h4>
					Reset password token is invalid, now you can safely close this window!
				</h4>
			</center>
		);
	}

	return (
		<div style={{ height: "100vh" }}>
			<NavbarOrka isHiThere isInHome />
			<div className="reset-password-container">
				<Grid container>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<form
							className="reset-password-form"
							onSubmit={handleSubmit(onClickResetPassword)}
						>
							<Typography className="reset-password-text">
								RESET PASSWORD
							</Typography>
							<CommonInput
								control={control}
								name="email"
								type="email"
								placeholder="Email"
								rules={{
									required: {
										value: true,
										message: "Email is required.",
									},
									pattern: rules.email.pattern,
								}}
							/>
							<CommonInput
								control={control}
								name="password"
								type="password"
								placeholder="Password*"
								rules={{
									required: {
										value: true,
										message: "Password is required.",
									},
									pattern: rules.password.pattern,
								}}
							/>
							<CommonInput
								control={control}
								name="confirmPassword"
								type="password"
								placeholder="Confirm password*"
								rules={{
									required: {
										value: true,
										message: "Confirm password is required.",
									},
									pattern: rules.password.pattern,
									validate: (value, formValues) => {
										if (value === formValues.password) {
											// It must return undefined; or return; here
											return;
										}
										return "Confirm password is not match.";
									},
								}}
							/>

							<Button
								className="normal-button primary-button login-button"
								type="submit"
							>
								Reset password
							</Button>
						</form>
					</Grid>

					{!isMobile && (
						<Grid item md={8} lg={8} xl={8}>
							<div style={{ display: "flex", justifyContent: "flex-end" }}>
								<img
									src="../imagenes/botones2.png"
									alt="botones2"
									style={{ width: "91.667%" }}
								/>
							</div>
						</Grid>
					)}
				</Grid>
			</div>
		</div>
	);

	return (
		<form onSubmit={handleSubmit(onClickResetPassword)}>
			<center>
				<h1>Reset password</h1>
				<div className="field" style={{ width: "40%", marginTop: "20px" }}>
					<CommonInput
						control={control}
						name="email"
						placeholder="Email*"
						rules={{
							required: {
								value: true,
								message: "Email is required.",
							},
							pattern: rules.email.pattern,
						}}
					/>
				</div>

				<div className="field" style={{ width: "40%", marginTop: "20px" }}>
					<CommonInput
						control={control}
						name="password"
						type="password"
						placeholder="Password*"
						rules={{
							required: {
								value: true,
								message: "Password is required.",
							},
							pattern: rules.password.pattern,
						}}
					/>
				</div>

				<div className="field" style={{ width: "40%", marginTop: "20px" }}>
					<CommonInput
						control={control}
						name="confirmPassword"
						type="password"
						placeholder="Confirm password*"
						rules={{
							required: {
								value: true,
								message: "Confirm password is required.",
							},
							pattern: rules.password.pattern,
							validate: (value, formValues) => {
								if (value === formValues.password) {
									// It must return undefined; or return; here
									return;
								}
								return "Confirm password is not match.";
							},
						}}
					/>
				</div>

				<div className="field" style={{ marginTop: "20px" }}>
					<button className="btn btn-light" type="submit" id="BtnCreateAccount">
						<span className="textContinue">Reset password</span>
					</button>
				</div>
			</center>
		</form>
	);
}
