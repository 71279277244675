import { IconButton, Typography } from "@mui/material";
import { FaChevronLeft } from "react-icons/fa6";
import { PassedBookingSummaryItem } from "./PassedBookingSummaryItem";

export function StudentProgressDetail({ studentProgress, clearSelect }) {
	return (
		<div className="my-orka-course-students-container">
			<div className="title-container">
				<IconButton className="back-icon" onClick={clearSelect}>
					<FaChevronLeft className="normal-icon" />
				</IconButton>
				<Typography component="h5" className="title">
					{`${studentProgress.student.firstName} ${studentProgress.student.lastName} - ${studentProgress.course.name}`}
				</Typography>
			</div>

			{studentProgress.passedBookings.map((passedBooking) => (
				<PassedBookingSummaryItem key={passedBooking.id} booking={passedBooking} />
			))}
		</div>
	);
}
