import { useCallback, useEffect } from "react";

import { Button, Typography } from "@mui/material";
import classNames from "classnames";
import { useForm, useWatch } from "react-hook-form";

import { apis, callApi } from "../../../../../../api";
import { courseType } from "../../../../../../constants";
import { useGoogleMap } from "../../../../../../hooks/useGoogleMap";
import { USDFormatter, validators } from "../../../../../../utils";
import {
	useMyCourseInstructor,
	useMyCourseLessonsInstructor
} from "../../hooks/useMyCourseInstructor";
import { CourseInfoInput } from "../CourseInfoInput";
import { CourseLocation } from "./CourseLocation";
import { CourseWhatIsIncludedInfo } from "./CourseWhatIsIncludedInfo";

function SaveChangeButton({ control, onClickCancel }) {
	const { course } = useMyCourseInstructor();
	const {
		name,
		description,
		important,
		workload,
		experience,
		instructor,
		coaching,
		mentorship,
		lifetimeAccess,
		discount,
		location,
	} = useWatch({ control });

	const disabled =
		course?.name?.trim() === name.trim() &&
		course?.description?.trim() === description.trim() &&
		course?.important?.trim() === important.trim() &&
		course?.workload?.trim() === workload.trim() &&
		course?.experience?.trim() === experience.trim() &&
		course?.instructor?.trim() === instructor.trim() &&
		course?.coaching === coaching &&
		course?.mentorship === mentorship &&
		course?.lifetimeAccess === lifetimeAccess &&
		course?.discount === Number(discount) &&
		(course?.location ?? "") === (location ?? "");

	return (
		<div className="update-button-container">
			<Button
				variant="contained"
				className="update-button normal-button disabled-button"
				onClick={onClickCancel}
			>
				Cancel
			</Button>
			<Button
				variant="contained"
				className={classNames("update-button normal-button primary-button", {
					"disabled-button": disabled,
				})}
				type="submit"
				disabled={disabled}
			>
				Save
			</Button>
		</div>
	);
}

export function CourseBasicInformation() {
	const { course, updateCourseBasicInformation } = useMyCourseInstructor();
	const { lessons } = useMyCourseLessonsInstructor();

	const { isLoaded } = useGoogleMap();

	const calculatePrice = useCallback(() => {
		let price = 0;
		for (let i = 0; i < lessons.length; i++) {
			price += lessons[i].totalCost ?? 0;
		}
		return price;
	}, [lessons]);

	const calculateTotalFlightTime = useCallback(() => {
		let totalFlightTime = 0;
		for (let i = 0; i < lessons.length; i++) {
			totalFlightTime += lessons[i].flightTime ?? 0;
		}
		return totalFlightTime / 60;
	}, [lessons]);

	const calculateTotalTeachingTime = useCallback(() => {
		let totalTeachingTime = 0;
		for (let i = 0; i < lessons.length; i++) {
			totalTeachingTime += lessons[i].teachingTime ?? 0;
		}
		return totalTeachingTime / 60;
	}, [lessons]);

	const { control, handleSubmit, setValue } = useForm({
		defaultValues: {
			name: "",
			description: "",
			important: "",
			workload: "",
			experience: "",
			instructor: "",
			totalTeachingTime: 0,
			totalFlightTime: 0,
			coaching: false,
			mentorship: false,
			lifetimeAccess: false,
			price: 0,
			discount: "",
			location: null,
			latitude: null,
			longitude: null,
			timezone: null,
		},
	});

	useEffect(() => {
		if (course) {
			setValue("name", course.name);
			setValue("description", course.description);
			setValue("important", course.important);
			setValue("workload", course.workload);
			setValue("experience", course.experience);
			setValue("instructor", course.instructor);
			setValue("totalTeachingTime", calculateTotalTeachingTime());
			setValue("totalFlightTime", calculateTotalFlightTime());
			setValue("coaching", course.coaching);
			setValue("mentorship", course.mentorship);
			setValue("lifetimeAccess", course.lifetimeAccess);
			setValue("price", USDFormatter.format(calculatePrice()));
			setValue("discount", `${course.discount}`);
			setValue("location", course.location);
			setValue("latitude", course.latitude);
			setValue("longitude", course.longitude);
			setValue("timezone", course.timezone);
		}
	}, [
		course,
		setValue,
		calculatePrice,
		calculateTotalTeachingTime,
		calculateTotalFlightTime,
	]);

	const basicInfoFormFields = [
		{
			name: "name",
			placeholder: "Your crafted course",
			label: "COURSE NAME*",
			labelClassName: "input-yellow-label",
			inputClassName: "input-field",
			rules: {
				required: {
					value: true,
					message: "Course name is required.",
				},
			},
			endGroup: true,
		},
		{
			name: "description",
			placeholder:
				"How would you describe your course in a great one sentence?",
			label: "COURSE DESCRIPTION*",
			labelClassName: "input-yellow-label",
			inputClassName: "input-field",
			multiline: true,
		},
		{
			name: "important",
			placeholder:
				"What problems are you helping your students overcome? What result does your course help someone achieve? What knowledge, skills, behaviors, and competencies are you transferring?",
			label: "Why is this important to you?",
			labelClassName: "input-normal-label",
			inputClassName: "input-field",
			multiline: true,
		},
		{
			name: "workload",
			placeholder: "How many days or months are required to complete the work?",
			label: "What is the Workload?",
			labelClassName: "input-normal-label",
			inputClassName: "input-field",
			multiline: true,
		},
		{
			name: "experience",
			placeholder:
				"What types of content and activities are included in this course?",
			label: "How is the learning experience?",
			labelClassName: "input-normal-label",
			inputClassName: "input-field",
			multiline: true,
			endGroup: true,
		},
		{
			name: "instructor",
			placeholder:
				"What is your name? What experience makes you unique? What motivates you to teach this course?",
			label: "MEET YOUR INSTRUCTOR*",
			labelClassName: "input-yellow-label",
			inputClassName: "input-field",
			multiline: true,
			endGroup: true,
		},
	];

	const whatIsIncludedOnlineFormFields = [
		{
			name: "totalTeachingTime",
			leftText: "Knowledge development (hrs)",
			type: "text",
		},
		{
			name: "coaching",
			leftText: "Coaching",
			type: "radio",
		},
		{
			name: "lifetimeAccess",
			leftText: "Lifetime access (Online course content)",
			type: "radio",
		},
		{
			name: "mentorship",
			leftText: "Mentorship",
			type: "checkbox",
		},
	];

	const whatIsIncludedOnsiteFormFields = [
		{
			name: "totalTeachingTime",
			leftText: "Knowledge development (hrs)",
			type: "text",
		},
		{
			name: "totalFlightTime",
			leftText: "Flight time to develope skill (hrs)",
			type: "text",
		},
		{
			name: "lifetimeAccess",
			leftText: "Lifetime access (Online course content)",
			type: "radio",
		},
		{
			name: "mentorship",
			leftText: "Mentorship",
			type: "checkbox",
		},
	];

	const onClickSaveCourseInformation = async (data) => {
		const body = {};
		if (data.name.trim() !== course.name.trim()) {
			body.name = data.name.trim();
		}
		if (data.description.trim() !== course.description.trim()) {
			body.description = data.description.trim();
		}
		if (data.important.trim() !== course.important.trim()) {
			body.important = data.important.trim();
		}
		if (data.workload.trim() !== course.workload.trim()) {
			body.workload = data.workload.trim();
		}
		if (data.experience.trim() !== course.experience.trim()) {
			body.experience = data.experience.trim();
		}
		if (data.instructor.trim() !== course.instructor.trim()) {
			body.instructor = data.instructor.trim();
		}
		if (data.coaching !== course.coaching) {
			body.coaching = data.coaching;
		}
		if (data.mentorship !== course.mentorship) {
			body.mentorship = data.mentorship;
		}
		if (data.lifetimeAccess !== course.lifetimeAccess) {
			body.lifetimeAccess = data.lifetimeAccess;
		}
		if (Number(data.discount) !== course.discount) {
			body.discount = Number(data.discount);
		}
		if (course.type === courseType.ONSITE) {
			if ((data.location ?? "") !== (course.location ?? "")) {
				if (data.location === "") {
					body.location = null;
					body.latitude = null;
					body.longitude = null;
					body.timezone = null;
				} else {
					body.location = data.location;
					body.latitude = data.latitude;
					body.longitude = data.longitude;
					const response = await callApi({
						method: "GET",
						url: `${
							apis.GOOGLE_MAP_TIME_ZONE
						}?location=${data.latitude} ${data.longitude}&timestamp=${Math.round(
							Date.now() / 1000
						)}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
						baseURL: "",
					});
					console.log(response);
					if (response) {
						body.timezone = response.rawOffset / 3600;
					}
				}
			}
		}

		updateCourseBasicInformation(body);
	};

	const onClickCancel = () => {
		if (course) {
			setValue("name", course.name);
			setValue("description", course.description);
			setValue("important", course.important);
			setValue("workload", course.workload);
			setValue("experience", course.experience);
			setValue("instructor", course.instructor);
			setValue("totalTeachingTime", calculateTotalTeachingTime());
			setValue("totalFlightTime", calculateTotalFlightTime());
			setValue("coaching", course.coaching);
			setValue("mentorship", course.mentorship);
			setValue("lifetimeAccess", course.lifetimeAccess);
			setValue("price", USDFormatter.format(calculatePrice()));
			setValue("discount", `${course.discount}`);
			setValue("location", course.location);
			setValue("latitude", course.latitude);
			setValue("longitude", course.longitude);
		}
	};

	const renderWhatIsIncluded = () => {
		if (!course) {
			return null;
		}

		if (course.type === courseType.ONLINE) {
			return (
				<>
					<Typography component="p" className="input-yellow-label">
						WHAT'S INCLUDED
					</Typography>
					{whatIsIncludedOnlineFormFields.map((field) => {
						return (
							<CourseWhatIsIncludedInfo
								control={control}
								key={field.name}
								{...field}
							/>
						);
					})}
					<div className="separator" />
				</>
			);
		}

		if (course.type === courseType.ONSITE) {
			return (
				<>
					<Typography component="p" className="input-yellow-label">
						WHAT'S INCLUDED
					</Typography>
					{whatIsIncludedOnsiteFormFields.map((field) => {
						return (
							<CourseWhatIsIncludedInfo
								control={control}
								key={field.name}
								{...field}
							/>
						);
					})}
					<div className="separator" />
				</>
			);
		}

		return null;
	};

	const renderCoursePricing = () => {
		if (!course) {
			return null;
		}

		return (
			<>
				<CourseInfoInput
					control={control}
					name="price"
					label="TOTAL COST"
					labelClassName="input-yellow-label"
					inputClassName="input-field"
					disabled
				/>
				<CourseInfoInput
					control={control}
					name="discount"
					label="Discount"
					description="Pay full course discount. Recommended - Save 15%"
					descriptionClassName="normal-text input-description"
					placeholder="Pay full course discount"
					labelClassName="input-normal-label"
					inputClassName="input-field"
					suffix="%"
					disallowInvalidInput
					rules={{
						pattern: {
							value: validators.price,
							message: "Discount value must be between 0 and 100%",
						},
						max: {
							value: 100,
							message: "Discount value must be between 0 and 100%",
						},
					}}
				/>
				<div className="separator" />
			</>
		);
	};

	const renderCourseLocation = () => {
		if (!course) {
			return null;
		}

		if (course.type === courseType.ONSITE && isLoaded) {
			return (
				<>
					<CourseLocation
						control={control}
						name="location"
						latitudeName="latitude"
						longitudeName="longitude"
						label="LOCATION*"
						labelClassName="input-yellow-label"
						placeholder="Where is the course taking place?"
						inputClassName="input-field"
						setFormValue={setValue}
					/>
					<div className="separator" />
				</>
			);
		}
	};

	if (!course) {
		return null;
	}

	return (
		<div className="course-basic-info-container">
			<form onSubmit={handleSubmit(onClickSaveCourseInformation)}>
				{basicInfoFormFields.map((field) => {
					return (
						<div key={field.name}>
							<CourseInfoInput control={control} {...field} />
							{field.endGroup && <div className="separator" />}
						</div>
					);
				})}
				{renderWhatIsIncluded()}
				{renderCoursePricing()}
				{renderCourseLocation()}
				<Typography component="p" className="note">
					All Fields marked with * are needed to publish your course.
				</Typography>
				<SaveChangeButton control={control} onClickCancel={onClickCancel} />
			</form>
		</div>
	);
}
