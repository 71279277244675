import React, { useState } from "react";

import { apis, callApi } from "../../../api";
import { useAccount } from "../../../hooks/useAccount";
import MenuProfile from "../Accont/MenuProfile";

const ContentSafetyInstructor = () => {
	const { account } = useAccount();

	const [reportType, setReportType] = useState("0");
	const [reportReason, setReportReason] = useState("");

	const sendReasons = async (e) => {
		e.preventDefault();

		if (!account) {
			return;
		}

		if (reportType === "0") {
			alert("You have to choose reason for your report!");
			return;
		}

		try {
			const objectReasons = {
				reportType,
				reportReason,
			};

			await callApi({
				method: "POST",
				url: apis.REPORT_CASE,
				body: objectReasons,
			});

			e.target.reset();
      setReportType('0'); 
			setReportReason(''); 
			alert("Thank you for your report. We will take action");
		} catch (error) {
			alert("There was an error sending the report");
		}
	};

	return (
		<div
			className="container container-general-safety-instructor mb-5"
			id="GrisCalis"
		>
			<div className="row justify-content-md-between p-5">
				<div className="col-md-4">
					<MenuProfile active="Safety" />
				</div>

				<div className="col-xs-12 col-md-8 container-safety-instructor">
					<form onSubmit={sendReasons}>
						<div className="row">
							<h4 className="header-safety">Safety</h4>
							<p className="text-safety">
								Are you feeling unsafe? Report any unsafe activity here. ORKA is
								committed to creating a safe space for everyone.
							</p>
							<h5 className="header-unsafe-activity">Report Unsafe Activity</h5>

							<div className="form-control">
								<select
									className="form-select col-form-label-lg"
									id="TextGrayColor"
									value={reportType}
									onChange={(e) => {
										setReportType(e.target.value);
									}}
									required
								>
									<option value="0" disabled>
										Choose reason for your report
									</option>

									<option value="studentDisrespectedTeacher" className="option-custom-select">
										Student has disrespected me as a teacher
									</option>
									<option value="studentInitiatedArgument" className="option-custom-select">
										Student initiated argument in-flight
									</option>
									<option value="studentThreatenedTeacher" className="option-custom-select">
										Student threatened me
									</option>
									<option value="studentSmelledLikeSubstances" className="option-custom-select">
										Student smelled like alcohol or marijuana
									</option>
									<option value="questionableBehavior" className="option-custom-select">
										Student displays questionable verbal and physical behavior
									</option>
									<option value="trespassedBoundaries" className="option-custom-select">
										Student-Teacher boundaries have been trespassed
									</option>
									<option value="sexualHarassment" className="option-custom-select">
										Sexual Harassment
									</option>
									<option value="sexualAssault" className="option-custom-select">
										Sexual Assault
									</option>
									<option value="other" className="option-custom-select">
										Other
									</option>
								</select>

								<textarea
									className="form-control textarea-safety"
									style={{ resize: "none" }}
									rows="12"
									value={reportReason}
									onChange={(e) => {
										setReportReason(e.target.value);
									}}
									placeholder="We are sorry to hear that you feel unsafe at this time, in a few words explain your reasons for your report..."
									required
								/>
							</div>
						</div>

						<div className="box-button">
							<button
								type="submit"
								className="btn btn-light"
								id="SafetyBtnSubmit"
							>
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ContentSafetyInstructor;
