import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { reqApi } from "../../../../api";
import { AccountService } from "../../../../hooks/useAccount";
import { useToken } from "../../../../hooks/useToken";
import Navbar from "../../navegacion/Navbar";
import BodyItem from "./components/BodyItem";
import HeaderItem from "./components/HeaderItem";
import ReviewItem from "./components/ReviewItem";

export default function MyOrkaModerator() {
	const [optionSelected, setOptionSelected] = useState("Instructors");
	const [studentSelected, setStudentSelected] = useState();
	const [instructorSelected, setsInstructorSelected] = useState();
	const { token } = useToken();

	let [instructors, setInstructors] = useState([]);
	let [students, setStudents] = useState([]);

	const navigate = useNavigate();

	const [reviews, setReviews] = useState([]);

	const logOut = () => {
		AccountService.signOut();
		navigate("/");
	};

	const handleStudentSelected = (index) => {
		studentSelected === index
			? setStudentSelected()
			: setStudentSelected(index);
	};
	const handleInstructorSelected = (index) => {
		instructorSelected === index
			? setsInstructorSelected()
			: setsInstructorSelected(index);
	};

	const getData = async () => {
		const reps = await reqApi.get("/notifications/getNotifications", {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		const studentData = reps.data.userList;
		const instructorData = reps.data.teacherList;

		console.log("instructorData : ", instructorData);
		setStudents(studentData);
		setInstructors(instructorData);	
	};

	const getReviewsInGeneral = async () => {
		const resp = await reqApi.get("/ratingsCourses/getAllGeneral", {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

		setReviews(resp.data);
	};

	useEffect(() => {
		getData();
		getReviewsInGeneral();
	}, []);

	return (
		<>
			<div className="box-general-moderator">
				<Navbar transparent />
				<div className="d-flex moderator-container">
					<div className="moderator-menu-container">
						<div>
							<h3 className="h3-moderator">Hi There!</h3>
							<h2 className="h2-moderator">Adriana Barragan</h2>
						</div>
						<div className="moderator-menu">
							<p
								className={`btn-moderator ${
									optionSelected === "Instructors" ? "active" : "off"
								}`}
								onClick={() => {
									setOptionSelected("Instructors");
								}}
							>
								Instructors
							</p>

							<p
								className={`btn-moderator ${
									optionSelected === "Students" ? "active" : "off"
								}`}
								onClick={() => {
									setOptionSelected("Students");
								}}
							>
								Students
							</p>

							<p onClick={logOut} className="btn-moderator logout-moderator">
								Sign out
							</p>
						</div>
					</div>
					<div className="moderator-items-container">
						{optionSelected === "Students" ? (
							<Accordion className="moderator-accordion-container">
								<div className="accordion-scroll">
									{students?.map((student, index) => (
										<Accordion.Item
											eventKey={index}
											className="grayItem moderatorGrayItem"
											onClick={() => handleStudentSelected(index)}
										>
											<Accordion.Header className="gray item-moderator">
												<HeaderItem
													{...student}
													selected={studentSelected === index && true}
												/>
											</Accordion.Header>
											<Accordion.Body className="grayItem ">
												<BodyItem
													{...student}
													getData={getData}
													// ReportedInstructors={item.ReportedStudents}
													//Course={item.Course}
													email={student.email}
												/>
											</Accordion.Body>
										</Accordion.Item>
									))}
								</div>
							</Accordion>
						) : optionSelected === "Instructors" ? (
							<Accordion className="moderator-accordion-container">
								<div className="accordion-scroll">
									{instructors?.map((instructor, index) => (
										<Accordion.Item
											eventKey={index}
											className="grayItem"
											onClick={() => handleInstructorSelected(index)}
										>
											<Accordion.Header className="gray">
												<HeaderItem
													{...instructor}
													selected={instructorSelected === index && true}
												/>
											</Accordion.Header>
											<Accordion.Body className="grayItem">
												<BodyItem
													{...instructor}
													getData={getData}
													//ReportedStudents={item.ReportedStudents}
													//Course={item.Course}
													email={instructor.email}
												/>
											</Accordion.Body>
										</Accordion.Item>
									))}
								</div>
							</Accordion>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
}
