import React from "react";

import { Controller } from "react-hook-form";

export default function CommonCheckbox({ control, name, label, rules }) {
	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({ field: { value, onChange }, formState: { errors } }) => {
				const errorMessage = errors[name]?.message;

				let className = "form-check-input";
				if (errorMessage) {
					className += " is-invalid";
				}

				return (
					<>
						<div className="form-check">
							<input
								className={className}
								type="checkbox"
								value={value}
								onChange={onChange}
							/>
							{label}
						</div>
						{errorMessage && (
							<div className="invalid-feedback">{errorMessage}</div>
						)}
					</>
				);
			}}
		/>
	);
}
