import { Checkbox, Typography } from "@mui/material";
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io";
import { courseStatus } from "../../../../../../constants";
import { useMyCourseInstructor } from "../../hooks/useMyCourseInstructor";

const visibilityData = [
	{
		key: "Flying",
		value: courseStatus.FLYING,
		description:
			"Your course appears in search results and students can book available dates",
	},
	{
		key: "Grounded",
		value: courseStatus.GROUNDED,
		description: "Students can't book your course or find it in search results",
	},
	{
		key: "Remove",
		value: courseStatus.REMOVE,
		description: "Permanently remove your course",
	},
];

export function CourseVisibility() {
	const { course, changeCourseVisibility } = useMyCourseInstructor();

	const onClickVisibilityItem = (newStatus) => {
		changeCourseVisibility(newStatus);
	};

	if (!course) {
		return null;
	}

	return (
		<>
			<Typography component="p" className="input-yellow-label">
				COURSE STATUS
			</Typography>
			{visibilityData.map((item) => (
				<div className="course-visibility-item" key={item.key}>
					<div className="course-visibility-item-left">
						<Typography className="course-visibility-item-title">
							{item.value}
						</Typography>
						<Typography className="course-visibility-item-description normal-text">
							{item.description}
						</Typography>
					</div>
					<div className="course-visibility-item-right">
						<Checkbox
							onClick={() => {
								onClickVisibilityItem(item.value)
							}}
							checked={course.status === item.value}
							checkedIcon={<IoMdRadioButtonOn className="normal-icon" />}
							icon={<IoMdRadioButtonOff className="normal-icon" />}
						/>
					</div>
				</div>
			))}
		</>
	);
}
