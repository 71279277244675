import { useState } from "react";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from "@mui/material";

import { lessonType } from "../../../../../../constants";
import { USDFormatter } from "../../../../../../utils";

export function CourseLesson({ lesson }) {
	const [expanded, setExpanded] = useState(false);

	const onChangeExpanded = (e, newExpanded) => {
		setExpanded(newExpanded);
	};

	const renderLessonInfo = () => {
		const teachingTime = (lesson.teachingTime / 60).toFixed(2);
		const flightTime = (lesson.flightTime / 60).toFixed(2);
		const blockTime = (lesson.blockTime / 60).toFixed(
			2
		);

		return (
			<>
				<Typography className="normal-text">{`DESCRIPTION: ${lesson.description}`}</Typography>
				<div className="separator" />
				<Typography className="normal-text">{`TEACHING TIME: ${teachingTime} hours`}</Typography>
				{lesson.type === lessonType.ONSITE && (
					<Typography className="normal-text">{`FLIGHT TIME: ${flightTime} hours`}</Typography>
				)}
				<Typography className="normal-text">{`BLOCK TIME: ${blockTime} hours`}</Typography>
				<div className="separator" />
				{lesson.type === lessonType.ONSITE && (
					<>
						<Typography className="normal-text">{`AIRPLANE: ${lesson.airplane}`}</Typography>
						<div className="separator" />
					</>
				)}
				<Typography className="normal-text">{`TOTAL COST: ${USDFormatter.format(
					lesson.totalCost
				)}`}</Typography>
			</>
		);
	};

	return (
		<div className="course-lesson-item-container">
			<Accordion expanded={expanded} onChange={onChangeExpanded}>
				<AccordionSummary>
					<div style={{ width: "100%" }}>
						<Typography component="p" className="normal-text bold lesson-title">
							{lesson.title}
						</Typography>
						{!expanded && (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									width: "100%",
								}}
							>
								<Typography component="p" className="normal-text">
									{`${(lesson.blockTime / 60).toFixed(2)} hrs`}
								</Typography>
								<Typography component="p" className="normal-text">
									{`${USDFormatter.format(lesson.totalCost)}`}
								</Typography>
							</div>
						)}
					</div>
				</AccordionSummary>
				<AccordionDetails style={{ padding: 0 }}>
					{renderLessonInfo()}
				</AccordionDetails>
			</Accordion>
		</div>
	);
}
