import { Typography } from "@mui/material";

import { useCheckoutCourse } from "../hooks/useCheckoutCourse";

export function CourseBasicInformation() {
	const { course } = useCheckoutCourse();

	if (!course) {
		return null;
	}

	return (
		<div className="course-basic-information-container">
			<Typography className="course-title" component="p">
				{course.name}
			</Typography>
			<Typography className="course-description normal-text" component="p">
				{course.description}
			</Typography>
			<div className="separator" />
		</div>
	);
}
