import { useEffect } from "react";

import { Button, Typography } from "@mui/material";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { apis, callApi } from "../../../../api";
import NavbarOrka from "../../navegacion/Navbar";

import "./stripe.scss";

export function Stripe() {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		// Reset nextRoute after user navigate to this screen
		localStorage.removeItem("nextRoute");
	}, []);

	const onClickMaybeLater = () => {
		const nextRoute = location.state?.nextRoute;
		navigate(nextRoute ?? "/myorka");
	};

	const onClickCreateStripeAccount = async () => {
		try {
			const nextRoute = location.state?.nextRoute;

			const response = await callApi({
				method: "GET",
				url: apis.GET_ONBOARD_LINK,
			});

			if (response?.accountLink?.url) {
				localStorage.setItem("nextRoute", nextRoute ?? "/myorka");
				window.location.href = response.accountLink.url;
			}
		} catch (error) {}
	};

	if (!location.state?.nextRoute) {
		return <Navigate to="/myorka" replace />
	}

	return (
		<div className="stripe-container">
			<NavbarOrka />
			<div className="stripe-content-container">
				<div className="logo-image">
					<svg
						id="Capa_1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 681.25 234.71"
						className="img-all-set"
					>
						<path
							fill="currentcolor"
							d="M678.33,124.17c-.97-.37-22.22-7.68-189.79-7.44-.25-11.26-1.95-22.48-5.35-33.19-4.11-13.13-10.41-25.58-18.72-36.47-8.28-10.91-18.39-20.38-29.77-27.87-11.42-7.44-24.08-12.89-37.27-16.06-26.4-6.27-54.74-3.06-78.84,8.78-12,5.99-22.99,13.94-32.3,23.51-9.23,9.64-16.82,20.78-22.37,32.85,5.92-11.89,13.82-22.73,23.23-31.98,9.5-9.18,20.56-16.66,32.48-22.15,23.93-10.86,51.49-13.02,76.58-6.24,12.53,3.42,24.43,8.99,35.03,16.38,10.57,7.43,19.83,16.66,27.27,27.14,7.49,10.46,12.98,22.25,16.42,34.54,2.84,10.02,4.06,20.42,3.97,30.78-32.85,.1-71.05,.48-115.46,1.24h-.27s-.18,.2-.18,.2c-4.49,4.99-9.7,11.25-9.02,12.77,.15,.34,.47,.53,.88,.53,2.43,0,310.14-5.89,323.24-6.14l3.13-.06-2.92-1.13Z"
						/>
						<path
							fill="currentcolor"
							d="M332.15,109c.23-.2,.68-.78-.1-1.82-3.69-4.92-37.95-30.93-39.4-32.04l-.86-.65-1.38,12.42,.21,.21c11.6,11.6,34.39,22.18,40.32,22.18,.57,0,.98-.1,1.21-.3Z"
						/>
						<path
							fill="currentcolor"
							d="M402.02,109.95c.47,0,1.38-.44,3.67-3.23,10.82-13.23,43.62-26.69,43.95-26.83l.59-.24-5.42-12.2-.57,.26c-27.83,12.92-43.29,38.2-43.1,41.33,.04,.62,.46,.81,.59,.85,.08,.02,.17,.05,.29,.05Z"
						/>
						<path
							fill="currentcolor"
							d="M440.04,207.23c-9.45,7.18-19.95,12.91-31.03,16.95-11.12,3.97-22.82,6.14-34.54,6.56-1.46,.11-2.93,.07-4.4,.05l-4.4-.06c-2.92-.18-5.85-.34-8.75-.73-5.81-.71-11.57-1.79-17.18-3.42-11.22-3.2-21.95-8.06-31.59-14.54-4.83-3.22-9.41-6.79-13.7-10.69-4.26-3.92-8.21-8.17-11.82-12.67-13.98-17.3-22.34-38.8-24.04-60.71,28.86,.43,55.88,1.46,78.32,3.36,1.42,.13,2.83-.47,3.73-1.59,4.04-5.01,39.54-49.06,42.91-52.43,1.7-1.7,2.63-1.84,2.88-1.68,.06,.04,1.35,.97-1.43,8.39-1.3,3.46-1.1,6.4,.6,8.75,1.38,1.91,3.43,2.97,4.91,3.52,.51,.19,1.08,.16,1.57-.09,.49-.25,.86-.7,1.01-1.24l6.56-23.96c.22-.33,.61-1.18,.18-2.74-.64-2.34-2.81-5.38-6.45-9.02-.68-.68-1.58-.98-2.69-.9-12.72,.96-54.5,57.08-58.19,62.08C146.9,107.12,1.45,132.47,0,132.73l.14,1.22c1.74-.09,136.45-7.27,248.66-6.11,2.49,24.15,12.43,47.37,28.17,65.54,15.96,18.74,37.85,32.17,61.52,37.95,5.91,1.5,11.94,2.41,17.99,2.94,3.02,.31,6.06,.37,9.09,.45l4.55-.09c1.51-.03,3.03-.04,4.54-.21,12.08-.85,24.02-3.51,35.22-7.95,11.18-4.52,21.63-10.71,30.92-18.29,9.21-7.68,17.24-16.7,23.77-26.69-6.86,9.76-15.17,18.45-24.53,25.73Z"
						/>
					</svg>
				</div>
				<Typography className="title" component="h3">ALMOST DONE!</Typography>
				<Typography className="normal-text" component="p">
					{"You need to create a Stripe account to take money from your students :)"}
				</Typography>
				<div className="button-group">
					<Button
						variant="contained"
						className="normal-text create-stripe-account-button"
						disableElevation
						onClick={onClickCreateStripeAccount}
					>
						Create stripe account
					</Button>
					<Button
						variant="contained"
						className="normal-text maybe-later-button"
						disableElevation
						onClick={onClickMaybeLater}
					>
						Maybe later
					</Button>
				</div>
			</div>
		</div>
	);
}