import { Grid } from "@mui/material";

import { NotFound } from "../../../common/NotFound/NotFound";
import NavbarOrka from "../../navegacion/Navbar";
import { CourseAviationStory } from "./components/CourseAviationStory/CourseAviationStory";
import { CourseBasicInformation } from "./components/CourseBasicInformation/CourseBasicInformation";
import { CourseCheckoutButton } from "./components/CourseCheckoutButton/CourseCheckoutButton";
import { CourseLessons } from "./components/CourseLessons/CourseLessons";
import { usePreviewCourseInit } from "./hooks/usePreviewCourse";

import "./preview_course.scss";

export function PreviewCourse() {
	const { isNotFound } = usePreviewCourseInit();

	if (isNotFound) {
		return <NotFound text="Course not found" />;
	}

	return (
		<div style={{ height: "100vh" }}>
			<NavbarOrka chatScreen isHiThere isInHome />
			<Grid container className="preview-course-container">
				<Grid item xs={12} sm={12} md={6} lg={6} xl={7}>
					<CourseAviationStory />
					<CourseBasicInformation />
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
					<CourseLessons />
				</Grid>
				<CourseCheckoutButton />
			</Grid>
		</div>
	);
}
