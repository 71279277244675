import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from "@mui/material";

import { courseType } from "../../../../../../constants";
import { formatTimeRange } from "../../../../../../utils";

export function StudentProgressItem({ studentProgress, viewDetail }) {
	const onClickViewDetail = () => {
		viewDetail(studentProgress);
	};

	if (!studentProgress) {
		return null;
	}

	const nextLesson = studentProgress.upcomingBookings[0]; // undefined if upcomingBookings is empty
	const numberOfLessonsLeft =
		studentProgress.upcomingBookings.length +
		studentProgress.unscheduledBookings.length;

	let dateText = "";
	let timeText = "";
	if (nextLesson) {
		const calculateResult = formatTimeRange(
			nextLesson.startTime,
			nextLesson.endTime
		);
		dateText = calculateResult.dateText;
		timeText = calculateResult.timeText;
	}

	return (
		<Accordion className="student-progress-item">
			<AccordionSummary className="student-progress-item-summary">
				<Typography component="p" className="student-name">
					{`${studentProgress.student.firstName} ${studentProgress.student.lastName} - ${studentProgress.course.name}`}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{nextLesson && (
					<>
						<Typography
							component="p"
							className="student-progress-information normal-text"
						>
							{`Next class: ${nextLesson.lesson.title}`}
						</Typography>
						<div className="student-progress-information-container">
							<Typography
								component="p"
								className="student-progress-information normal-text"
							>
								{`Date: ${dateText}`}
							</Typography>
							<Typography
								component="p"
								className="student-progress-information normal-text"
							>
								{`Time: ${timeText}`}
							</Typography>
						</div>
					</>
				)}
				<Typography
					component="p"
					className="student-progress-information normal-text"
				>
					{`Classes left: ${numberOfLessonsLeft}`}
				</Typography>
				{studentProgress.course.type === courseType.ONSITE && (
					<>
						<div className="student-progress-information-container">
							<Typography
								component="p"
								className="student-progress-information normal-text"
							>
								{`Air Hours: ${studentProgress.airHours} hrs`}
							</Typography>
							<Typography
								component="p"
								className="student-progress-information normal-text"
							>
								{`Simulator Hours: ${studentProgress.simulatorHours} hrs`}
							</Typography>
							<Typography
								component="p"
								className="student-progress-information normal-text"
							>
								{`Miles Flown: ${studentProgress.milesFlown} mi`}
							</Typography>
						</div>
						{studentProgress.flightSummary && (
							<Typography
								component="p"
								className="student-progress-information normal-text"
							>
								{`Flight summary: ${studentProgress.flightSummary}`}
							</Typography>
						)}
					</>
				)}
				<Typography
					component="p"
					onClick={onClickViewDetail}
					className="view-edit-button normal-text"
				>
					View / Edit
				</Typography>
			</AccordionDetails>
		</Accordion>
	);
}
