export function formatHourMinute(hour, minute) {
    let hourString = "";
    if (hour < 10) {
        hourString = `0${hour}`;
    } else {
        hourString = `${hour}`;
    }

    let minuteString = "";
    if (minute < 10) {
        minuteString = `0${minute}`;
    } else {
        minuteString = `${minute}`;
    }

    return `${hourString}:${minuteString}`;
}
