import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import { useMyOwnedCoursesStudent } from "../../../../../../hooks/useMyOwnedCoursesStudent";
import { MissingLesson } from "./MissingLesson";
import { PaidLesson } from "./PaidLesson";

export function CourseLessons() {
	const params = useParams();
	const slug = params.slug ?? "";
	const { passedLessons, upcomingLessons, unscheduledLessons, missingLessons } =
		useMyOwnedCoursesStudent(slug);

	return (
		<div className="course-lessons-container">
			{passedLessons.length > 0 && (
				<Typography component="p" className="yellow-label">
					PASSED CLASSES
				</Typography>
			)}

			{passedLessons.map((booking) => {
				return <PaidLesson booking={booking} key={booking.id} />;
			})}

			{upcomingLessons.length > 0 && (
				<Typography component="p" className="yellow-label">
					UPCOMING CLASSES
				</Typography>
			)}

			{upcomingLessons.map((booking) => {
				return <PaidLesson booking={booking} key={booking.id} />;
			})}

			{unscheduledLessons.length > 0 && (
				<Typography component="p" className="yellow-label">
					UNSCHEDULED CLASSES
				</Typography>
			)}

			{unscheduledLessons.map((booking) => {
				return <PaidLesson booking={booking} key={booking.id} />;
			})}

			{missingLessons.length > 0 && (
				<Typography component="p" className="yellow-label">
					MISSING CLASSES
				</Typography>
			)}

			{missingLessons.map((lesson) => {
				return <MissingLesson lesson={lesson} key={lesson.id} />;
			})}
		</div>
	);
}
