import { courseType } from "../../../../../constants";
import { useCheckoutCourse } from "../hooks/useCheckoutCourse";
import { OnlineCourseCheckout } from "./OnlineCourseCheckout";
import { OnsiteCourseCheckout } from "./OnsiteCourseCheckout";

export function CourseCheckoutInformation() {
	const { course } = useCheckoutCourse();

	if (!course) {
		return null;
	}

	if (course.type === courseType.ONSITE) {
		return <OnsiteCourseCheckout />;
	}

	if (course.type === courseType.ONLINE) {
		return <OnlineCourseCheckout />;
	}

	return null;
}
