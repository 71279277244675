import { IconButton, TextField, Typography } from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import { useState } from "react";
import { FaChevronLeft } from "react-icons/fa6";
import { IoSend } from "react-icons/io5";

import { useAccount } from "../../../../../hooks/useAccount";
import { useChatMessages } from "../hooks/useChat";

export function ChatBox() {
	const { messages, sendMessage } = useChatMessages();
	const { account } = useAccount();
	const [text, setText] = useState("");

	if (!account || messages.length === 0) {
		return null;
	}

	const firstMessage = messages[0];
	const firstMessageSenderId = firstMessage.senderId;
	const summaryId = firstMessage.CourseID.split("#")[3];
	let displayOpponentName = "";
	let opponentId = "";
	if (account.userId === firstMessageSenderId) {
		displayOpponentName = `${firstMessage.receiver.firstName} - ${
			firstMessage.courseName ?? ""
		}`;
		opponentId = firstMessage.receiverId;
	} else {
		displayOpponentName = `${firstMessage.sender.firstName} - ${
			firstMessage.courseName ?? ""
		}`;
		opponentId = firstMessage.senderId;
	}

	const onClickSendMessage = (e) => {
		console.log("here");
		const body = {
			body: text.trim(),
			receiverId: opponentId,
			summaryId,
		};

		sendMessage(body);
		setText("");
	};

	return (
		<>
			<div className="chat-info">
				<IconButton>
					<FaChevronLeft className="normal-icon" />
				</IconButton>
				<Typography className="opponent-name">{displayOpponentName}</Typography>
			</div>
			<div className="messages-box">
				{messages.map((message) => (
					<div className="message-item" key={message.Timestamp}>
						<Typography component="span" className="normal-text bold">
							{message.sender.firstName}
						</Typography>
						<Typography component="span" className="sent-time">
							{moment(message.createdAt).format("HH:mm, DDD MMM, YYYY")}
						</Typography>
						<Typography
							style={{ whiteSpace: "pre-line" }}
							className="normal-text"
						>
							{message.message}
						</Typography>
					</div>
				))}
				<div id="scroll-position" />
			</div>
			<div className="input-box">
				<TextField
					fullWidth
					variant="standard"
					InputProps={{ disableUnderline: true }}
					multiline
					style={{ height: 60, overflowY: "auto" }}
					placeholder="Type a message..."
					value={text}
					onChange={(event) => {
						setText(event.target.value);
					}}
				/>
				<IconButton
					type="submit"
					disabled={text.trim() === ""}
					onClick={onClickSendMessage}
				>
					<IoSend
						className={classNames("normal-icon send-icon", {
							disabled: text.trim() === "",
						})}
					/>
				</IconButton>
			</div>
		</>
	);
}
