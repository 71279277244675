import { useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { useAccount } from "../../../../../../hooks/useAccount";
import { LessonInformation } from "./LessonInformation";
import { CourseSchedule } from "./CourseSchedule";
import { useMyOwnedCoursesStudent } from "../../../../../../hooks/useMyOwnedCoursesStudent";
import { lessonType } from "../../../../../../constants";

export function CourseSummary({ isLoading, courseSlug }) {
	const { account } = useAccount();
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedLessonId, setSelectedLessonId] = useState(
		Number(searchParams.get("class") ?? 0)
	);
	const [isOwned, setIsOwned] = useState(true);

	const {
		course,
		passedLessons,
		upcomingLessons,
		unscheduledLessons,
		missingLessons,
	} = useMyOwnedCoursesStudent(courseSlug);

	useEffect(() => {
		if (!course) {
			return;
		}

		const selectedLessonIdFromParams = searchParams.get("class");

		if (selectedLessonIdFromParams) {
			if (searchParams.get("o") === "0") {
				if (
					missingLessons.find(
						(lesson) => lesson.id === Number(selectedLessonIdFromParams)
					)
				) {
					setIsOwned(false);
					return;
				}
			}

			if (searchParams.get("o") === "1") {
				if (
					[...passedLessons, ...upcomingLessons, ...unscheduledLessons].find(
						(booking) => booking.id === Number(selectedLessonIdFromParams)
					)
				) {
					setIsOwned(1);
					return;
				}
			}

			searchParams.delete("class");
			searchParams.delete("o");
			setSearchParams(searchParams);
		}

		setIsOwned(true);
		if (passedLessons.length > 0) {
			if (passedLessons[0].lesson.type === lessonType.ONSITE) {
				setSelectedLessonId(passedLessons[0].id);
				return;
			}
		}

		if (upcomingLessons.length > 0) {
			setSelectedLessonId(upcomingLessons[0].id);
			return;
		}

		if (unscheduledLessons.length > 0) {
			setSelectedLessonId(unscheduledLessons[0].id);
		}
	}, [
		course,
		missingLessons,
		passedLessons,
		searchParams,
		setSearchParams,
		unscheduledLessons,
		upcomingLessons,
	]);

	return (
		<div className="course-summary-container">
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
					<Typography className="hi-there">Hi There!</Typography>
					<Typography className="user-name">
						{account.firstName} {account.lastName}
					</Typography>
					{!isLoading && (
						<LessonInformation
							courseSlug={courseSlug}
							selectedLessonId={selectedLessonId}
							isOwned={isOwned}
						/>
					)}
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
					<CourseSchedule
						courseSlug={courseSlug}
						isOwned={isOwned}
						selectedLessonId={selectedLessonId}
						setSelectedLessonId={setSelectedLessonId}
					/>
				</Grid>
			</Grid>
		</div>
	);
}
