import { useRef } from "react";

import { Box, CircularProgress, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import {
	GridContextProvider,
	GridDropZone,
	GridItem,
	swap,
} from "react-grid-dnd";

import {
	MAX_COURSE_DOCUMENT_FILE_SIZE,
	MAX_NUMBER_OF_COURSE_DOCUMENT_IMAGE,
	MAX_NUMBER_OF_COURSE_DOCUMENT_VIDEO,
	MIN_NUMBER_OF_COURSE_DOCUMENT_IMAGE,
	MIN_NUMBER_OF_COURSE_DOCUMENT_VIDEO,
} from "../../../../../../constants";
import useScreenSize from "../../../../../../hooks/useScreenSize";
import { ViewMediaModal } from "../../../../../common/ViewMediaModal/ViewMediaModal";
import { useMyCourseDocumentsInstructor } from "../../hooks/useMyCourseInstructor";
import { CourseDocumentImage } from "./CourseDocumentImage";
import { CourseDocumentVideo } from "./CourseDocumentVideo";

export function CourseAviationStory() {
	const { mediaFiles, addFile, reorderFiles } = useMyCourseDocumentsInstructor();
	const { width } = useScreenSize();
	const viewMediaModalRef = useRef();
	const openableMediaFiles = mediaFiles.filter((file) => file.id);
	const disableClickRef = useRef(false);

	const numberOfImages = mediaFiles.filter((file) =>
		file.contentType.startsWith("image")
	).length;
	const numberOfVideos = mediaFiles.filter((file) =>
		file.contentType.startsWith("video")
	).length;
	const isUploading = mediaFiles.some((file) => file.uploadId !== undefined);

	const dropzoneAccept = {};
	if (numberOfImages < MAX_NUMBER_OF_COURSE_DOCUMENT_IMAGE) {
		dropzoneAccept["image/*"] = [];
	}
	if (numberOfVideos < MAX_NUMBER_OF_COURSE_DOCUMENT_VIDEO) {
		dropzoneAccept["video/mp4"] = [];
		dropzoneAccept["video/quicktime"] = [];
	}
	const dropzoneDisabled =
		numberOfImages >= MAX_NUMBER_OF_COURSE_DOCUMENT_IMAGE &&
		numberOfVideos >= MAX_NUMBER_OF_COURSE_DOCUMENT_VIDEO;

	/**
	 * Upload file
	 * @param {File[]} files
	 */
	const handleUploadFile = (files) => {
		for (let i = 0; i < files.length; i++) {
			addFile(files[i]);
		}
	};

	const dropzone = useDropzone({
		noKeyboard: true,
		disabled: dropzoneDisabled,
		accept: dropzoneAccept,
		onDropAccepted: handleUploadFile,
		maxSize: MAX_COURSE_DOCUMENT_FILE_SIZE * 1024 * 1024,
	});

	const onChangeOrder = (sourceId, sourceIndex, targetIndex, targetId) => {
		disableClickRef.current = true;
		setTimeout(() => {
			disableClickRef.current = false;
		}, 100);
		const newMediaFiles = swap(mediaFiles, sourceIndex, targetIndex);
		reorderFiles(newMediaFiles);
	};

	const getBoxesPerRow = () => {
		if (width <= 576) {
			return 3;
		}
		if (width <= 768) {
			return 4;
		}
		if (width <= 992) {
			return 3;
		}
		if (width <= 1200) {
			return 4;
		}
		return 6;
	};

	const getRowHeight = () => {
		if (width <= 576) {
			return 156;
		}
		return 208;
	};

	const getGridDropZoneHeight = () => {
		const numberOfRows = Math.ceil(mediaFiles.length / getBoxesPerRow());
		return getRowHeight() * numberOfRows;
	};

	const onClickViewMediaFile = (fileToOpen) => {
		const index = openableMediaFiles.findIndex(
			(file) => file.id === fileToOpen.id
		);
		if (index > -1) {
			viewMediaModalRef.current?.open({ index });
		}
	};

	return (
		<>
			<div className="course-aviation-story">
				<Typography component="p" className="input-yellow-label">
					AVIATION STORY*
				</Typography>
				<Typography
					component="p"
					className="course-aviation-story-subtitle normal-text"
				>
					Show the experience
				</Typography>
				<GridContextProvider onChange={onChangeOrder}>
					<GridDropZone
						boxesPerRow={getBoxesPerRow()}
						rowHeight={getRowHeight()}
						style={{ height: `${getGridDropZoneHeight()}px` }}
						disableDrag={isUploading}
						disableDrop={isUploading}
					>
						{mediaFiles.map((file) => {
							if (file.uploadId) {
								return (
									<GridItem key={file.uploadId}>
										<div className="media-container">
											<Box
												sx={{
													position: "relative",
													display: "inline-flex",
												}}
											>
												<CircularProgress
													variant="determinate"
													value={file.progress}
												/>
												<Box
													sx={{
														top: 0,
														left: 0,
														bottom: 0,
														right: 0,
														position: "absolute",
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}
												>
													<Typography component="p" className="normal-text">
														{`${file.progress}%`}
													</Typography>
												</Box>
											</Box>
										</div>
									</GridItem>
								);
							}
							if (file.contentType.startsWith("image")) {
								return (
									<GridItem
										key={`${file.id}`}
										onClick={() => {
											if (disableClickRef.current) {
												return;
											}
											onClickViewMediaFile(file);
										}}
									>
										<CourseDocumentImage file={file} />
									</GridItem>
								);
							}

							if (file.contentType.startsWith("video")) {
								return (
									<GridItem
										key={`${file.id}`}
										onClick={() => {
											if (disableClickRef.current) {
												return;
											}
											onClickViewMediaFile(file);
										}}
									>
										<CourseDocumentVideo file={file} />
									</GridItem>
								);
							}

							return null;
						})}
					</GridDropZone>
				</GridContextProvider>
				{!dropzoneDisabled && (
					<div {...dropzone.getRootProps({ className: "drop-zone" })}>
						<input {...dropzone.getInputProps()} />
						<Typography className="normal-text">
							{`Drag 'n' drop ${MIN_NUMBER_OF_COURSE_DOCUMENT_IMAGE}-${MAX_NUMBER_OF_COURSE_DOCUMENT_IMAGE} photos and ${MIN_NUMBER_OF_COURSE_DOCUMENT_VIDEO}-${MAX_NUMBER_OF_COURSE_DOCUMENT_VIDEO} videos, or click to select files. Videos should be under ${MAX_COURSE_DOCUMENT_FILE_SIZE}MB. We support MP4, MOV, and all image files.`}
						</Typography>
					</div>
				)}
				<div
					style={{
						height: 1,
						width: "100%",
						backgroundColor: "#DCE3E5",
						marginTop: 10,
					}}
				/>
			</div>
			<ViewMediaModal ref={viewMediaModalRef} mediaFiles={openableMediaFiles} />
		</>
	);
}
