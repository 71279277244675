import React, { memo, useRef } from "react";

function QuestionItem({ data, index, selected, setSelectedQuestion }) {
	const lastTime = useRef(0);

	const onClickToggleQuestion = (e) => {
		e.preventDefault();

		if (Date.now() - lastTime.current < 100) {
			return;
		}
		lastTime.current = Date.now();

		if (selected) {
			setSelectedQuestion(null);
		} else {
			setSelectedQuestion(index);
		}
	};

	return (
		<div
			className="question-accordion-item"
			style={{cursor: 'pointer'}}
		>
			{selected ? (
				<>
					<div
						className="d-flex justify-content-between strong-blue"
						onClick={onClickToggleQuestion}
					>
						<p className="question-title-active">
							{index + 1}. {data.question}
						</p>
						<i className="fa-solid fa-chevron-up font-size-20"></i>
					</div>
					<p className="question-body-active">{data.body}</p>
				</>
			) : (
				<div
					className={'d-flex justify-content-between light-blue'}
					onClick={onClickToggleQuestion}
				>
					<p className="question-body" >
						{index + 1}. {data.question}
					</p>
					<i className="fa-solid fa-chevron-down font-size-20"></i>
				</div>
			)}
		</div>
	);
}

export default memo(QuestionItem);
