import { Link } from "react-router-dom";

import {
	useInstructorAgendaInit,
	useInstructorAgendas,
} from "../../../../../../hooks/useInstructorAgenda";
import { ScheduleCalendar } from "../../../../../common/ScheduleCalendar/ScheduleCalendar";

export function Schedule() {
	useInstructorAgendaInit();
	const { agendas } = useInstructorAgendas();

	const scheduleData = agendas.map((agenda) => ({
		...agenda,
		id: `${agenda.id}-instructor`,
		startDate: new Date(agenda.startTime),
		endDate: new Date(agenda.endTime),
		canReschedule: false,
		isInstructor: true,
	}));

	return (
		<div className="schedule">
			<ScheduleCalendar data={scheduleData} />
			<Link
				className="normal-text reschedule-text"
				to="/booking"
			>
				Reschedule
			</Link>
		</div>
	);
}
