import { useRef } from "react";

import { Grid, Typography } from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Link } from "react-router-dom";

import { apis, callApi } from "../../../../../../api";
import { lessonType } from "../../../../../../constants";
import { useGoogleMap } from "../../../../../../hooks/useGoogleMap";
import {
	MyOwnedCoursesStudentService,
	useMyOwnedCoursesStudent,
} from "../../../../../../hooks/useMyOwnedCoursesStudent";
import { useStudentSummaries } from "../../../../../../hooks/useStudentSummaries";
import { ImageViewer } from "../../../../../common/FileViewer/ImageViewer";
import { VideoPlayer } from "./VideoPlayer";
import { FileViewer } from "../../../../../common/FileViewer/FileViewer";

export function LessonInformation({ courseSlug, selectedLessonId, isOwned }) {
	const { summaries } = useStudentSummaries();
	const imageViewerRef = useRef(null);

	const {
		course,
		passedLessons,
		upcomingLessons,
		unscheduledLessons,
		missingLessons,
	} = useMyOwnedCoursesStudent(courseSlug);
	let booking = null;
	let missingLesson = null;
	if (isOwned) {
		booking = [...passedLessons, ...upcomingLessons, unscheduledLessons].find(
			(booking) => booking.id === selectedLessonId
		);
	} else {
		missingLesson = missingLessons.find(
			(lesson) => lesson.id === selectedLessonId
		);
	}

	const { isLoaded } = useGoogleMap();

	const downloadFile = async (file) => {
		try {
			const response = await callApi({
				method: "POST",
				url: apis.GET_DOWNLOAD_URL,
				body: {
					fileName: file.name,
					location: file.location,
				},
			});
			if (!response?.downloadURL) {
				return;
			}

			const res = await callApi({
				method: "GET",
				url: response.downloadURL,
				baseURL: "",
				responseType: "blob",
			});
			const url = window.URL.createObjectURL(new Blob([res]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${file.name}`);
			document.body.appendChild(link);
			link.click();
		} catch (error) {}
	};

	const renderMap = () => {
		if (!course) {
			return null;
		}

		if (summaries.length === 0 || !isLoaded) {
			return (
				<img
					src="../../../../imagenes/mapa_orka.png"
					alt="Default img map"
					className="map-view"
				/>
			);
		}

		if (
			booking?.lesson?.type === lessonType.ONLINE ||
			missingLesson?.type === lessonType.ONLINE
		) {
			return null;
		}

		if (course.latitude === null || course.longitude === null) {
			return null;
		}

		return (
			<GoogleMap
				zoom={10}
				center={{
					lat: course.latitude,
					lng: course.longitude,
				}}
				mapContainerClassName="map"
			>
				<Marker
					position={{
						lat: course.latitude,
						lng: course.longitude,
					}}
				/>
			</GoogleMap>
		);
	};

	if (summaries.length === 0 || !course) {
		return (
			<>
				{renderMap()}
				<Grid container className="course-information" spacing={1}>
					<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
						<Typography className="normal-text bold class-summary">
							Here's Your Class Summary:
						</Typography>
						<Typography className="normal-text enroll-title">
							Enroll in a class today!
						</Typography>
						<Typography className="normal-text enroll-description">
							Once you enroll in a class you'll be able to see your progress in
							this section
						</Typography>
						<div>
							<Link
								className="normal-text browse-class-text"
								to="/#experiences"
							>
								Browse Classes
							</Link>
						</div>
					</Grid>
					<Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
						<Grid container spacing={1}>
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<div className="summary-number-container">
									<span>
										<Typography
											className="summary-number bold"
											component="span"
										>
											0
										</Typography>
										<Typography
											className="summary-number-unit normal-text bold"
											component="span"
										>
											{" hrs"}
										</Typography>
									</span>
								</div>
								<Typography className="summary-number-title">
									Hours in Air
								</Typography>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<div className="summary-number-container">
									<span>
										<Typography
											className="summary-number bold"
											component="span"
										>
											0
										</Typography>
										<Typography
											className="summary-number-unit normal-text bold"
											component="span"
										>
											{" hrs"}
										</Typography>
									</span>
								</div>
								<Typography className="summary-number-title">
									Hours in Simulator
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className="summary-number-container">
									<span>
										<Typography
											className="summary-number bold"
											component="span"
										>
											0
										</Typography>
										<Typography
											className="summary-number-unit normal-text bold"
											component="span"
										>
											{" mi"}
										</Typography>
									</span>
								</div>
								<Typography className="summary-number-title">
									Miles flown
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</>
		);
	}

	if (booking?.lesson?.type === lessonType.ONSITE) {
		const isPassed = !!passedLessons.find((b) => b.id === booking.id);
		const helperDocuments = booking.lesson.lessonDocuments.filter(
			(file) => file.documentType === "HELP_DOC"
		);

		return (
			<>
				<Typography className="normal-text">{course.location}</Typography>
				{renderMap()}
				<Typography className="class-title">{booking.lesson.title}</Typography>
				<Typography className="normal-text class-description">
					{booking.lesson.description}
				</Typography>
				{helperDocuments.length > 0 && (
					<>
						<Typography>Helper documents</Typography>
						{helperDocuments.map((file) => (
							<div
								key={file.id}
								className="helper-document"
								onClick={() => {
									if (file.contentType.startsWith("image")) {
										imageViewerRef.current?.open({ url: file.url });
									} else {
										downloadFile(file);
									}
								}}
							>
								<Typography component="div" className="file-name">
									{file.name}
								</Typography>
							</div>
						))}
					</>
				)}
				{isPassed && (
					<Grid container className="course-information" spacing={1}>
						<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
							<Typography className="normal-text bold class-summary">
								Here's Your Class Summary:
							</Typography>
							<Typography className="normal-text">
								{booking.flightSummary}
							</Typography>
							<div>
								<Link
									className="normal-text show-more-text"
									to={`/owned-courses/${course.courseSlug}`}
								>
									View syllabus
								</Link>
							</div>
						</Grid>
						<Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
							<Grid container spacing={1}>
								<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
									<div className="summary-number-container">
										<span>
											<Typography
												className="summary-number bold"
												component="span"
											>
												{booking.airHours}
											</Typography>
											<Typography
												className="summary-number-unit normal-text bold"
												component="span"
											>
												{" hrs"}
											</Typography>
										</span>
									</div>
									<Typography className="summary-number-title">
										Hours in Air
									</Typography>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
									<div className="summary-number-container">
										<span>
											<Typography
												className="summary-number bold"
												component="span"
											>
												{booking.simulatorHours}
											</Typography>
											<Typography
												className="summary-number-unit normal-text bold"
												component="span"
											>
												{" hrs"}
											</Typography>
										</span>
									</div>
									<Typography className="summary-number-title">
										Hours in Simulator
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<div className="summary-number-container">
										<span>
											<Typography
												className="summary-number bold"
												component="span"
											>
												{booking.milesFlown}
											</Typography>
											<Typography
												className="summary-number-unit normal-text bold"
												component="span"
											>
												{" mi"}
											</Typography>
										</span>
									</div>
									<Typography className="summary-number-title">
										Miles flown
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
				{!isPassed && (
					<div>
						<Link
							className="normal-text show-more-text"
							to={`/owned-courses/${course.courseSlug}`}
						>
							View syllabus
						</Link>
					</div>
				)}
				<ImageViewer ref={imageViewerRef} />
			</>
		);
	}

	if (missingLesson?.type === lessonType.ONSITE) {
		return (
			<>
				<Typography className="normal-text">{course.location}</Typography>
				{renderMap()}
				<Typography className="class-title">{missingLesson.title}</Typography>
				<Typography className="normal-text class-description">
					{missingLesson.description}
				</Typography>
				<div>
					<Link
						className="normal-text show-more-text"
						to={`/owned-courses/${course.courseSlug}`}
					>
						View syllabus
					</Link>
				</div>
			</>
		);
	}

	if (booking?.lesson?.type === lessonType.ONLINE) {
		const helperDocuments = booking.lesson.lessonDocuments.filter(
			(file) => file.documentType === "HELP_DOC"
		);
		const video = booking.lesson.lessonDocuments.find(
			(file) => file.documentType === "FILM"
		);

		const onFinishLesson = () => {
			try {
				if (!booking.isPassed) {
					callApi({
						method: "PATCH",
						url: `${apis.PROGRESS}/${booking.id}`,
						body: {
							status: "Done",
						},
					});
					MyOwnedCoursesStudentService.finishALesson(courseSlug, booking.id);
				}
			} catch (error) {}
		};

		return (
			<>
				{video && <VideoPlayer video={video} onFinishLesson={onFinishLesson} />}
				<Typography className="class-title">{booking.lesson.title}</Typography>
				<Typography className="normal-text class-description">
					{booking.lesson.description}
				</Typography>
				{helperDocuments.length > 0 && (
					<>
						<Typography>Helper documents</Typography>
						{helperDocuments.map((file) => (
							<div
								key={file.id}
								className="helper-document"
								onClick={() => {
									if (file.contentType.startsWith("image")) {
										imageViewerRef.current?.open({ url: file.url });
									} else {
										downloadFile(file);
									}
								}}
							>
								<Typography component="div" className="file-name">
									{file.name}
								</Typography>
							</div>
						))}
					</>
				)}
				<ImageViewer ref={imageViewerRef} />
			</>
		);
	}

	return null;
}
