import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { validators } from "../../../../../../utils";
import { useMyCourseLessonsInstructor } from "../../hooks/useMyCourseInstructor";

import { CourseInfoInput } from "../CourseInfoInput";

export function CourseCreateLesson({ setIsCreating }) {
	const { control, handleSubmit } = useForm({
		defaultValues: {
			title: "",
		},
	});
	const { createNewLesson } = useMyCourseLessonsInstructor();

	const createLessonInputFields = [
		{
			name: "title",
			placeholder: "Class name",
			label: "CLASS NAME*",
			labelClassName: "lesson-input-title normal-text",
			inputClassName: "lesson-input-field normal-text",
			rules: {
				required: {
					value: true,
					message: "Class name is required.",
				},
				pattern: {
					value: validators.nonEmptyString,
					message: "Course name is required.",
				},
			},
		},
	];

	const onSubmitCreateLesson = async (data) => {
		await createNewLesson(data.title.trim());
		setIsCreating(false);
	};

	const onClickCancel = () => {
		setIsCreating(false);
	};

	return (
		<form onSubmit={handleSubmit(onSubmitCreateLesson)}>
			<div className="create-lesson-container">
				{createLessonInputFields.map((field) => {
					return (
						<CourseInfoInput control={control} key={field.name} {...field} />
					);
				})}
				<div className="lesson-buttons-container">
					<Button
						variant="contained"
						className="lesson-button normal-button disabled-button"
						onClick={onClickCancel}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						className="lesson-button normal-button primary-button"
						type="submit"
					>
						Save
					</Button>
				</div>
			</div>
		</form>
	);
}
