import { useState } from "react";

import { Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { omitEmpty, USDFormatter } from "../../../../../utils";
import { useCheckoutCourse, useOwnedLessons } from "../hooks/useCheckoutCourse";
import { CourseLessonItem } from "./CourseLessonItem";
import { paymentProductType } from "../../../../../constants";
import { apis, callApi } from "../../../../../api";

export function OnlineCourseCheckout() {
	const { course } = useCheckoutCourse();
	const { ownedLessons } = useOwnedLessons();

	const isBought = ownedLessons.some((lesson) => lesson.owned);
	const navigate = useNavigate();

	const [couponCode, setCouponCode] = useState("");

	const calculateTotalCost = () => {
		let totalCost = 0;

		for (let i = 0; i < ownedLessons.length; i++) {
			totalCost += ownedLessons[i].totalCost;
		}

		return totalCost;
	};

	const totalPrice = calculateTotalCost();

	const discountPrice = Math.round((totalPrice * course.discount)) / 100;
	const priceAfterDiscount = Math.round((totalPrice - discountPrice) * 100) / 100;
	const serviceFee = Math.round((priceAfterDiscount * 0.1 + 0.3) * 100) / 100;
	const totalCost = Math.round((priceAfterDiscount + serviceFee) * 100) / 100;

	const onClickCheckout = async () => {
		const body = {
			productCode: course.courseCode,
			productType: paymentProductType.COURSE,
			totalCost: Math.round(totalCost * 100),
			couponCode,
		};

		try {
			const response = await callApi({
				method: "POST",
				url: apis.CHECKOUT,
				body: omitEmpty(body),
			});

			if (!response.paymentResult) {
				return;
			}

			window.location.href = response.paymentResult.url;
		} catch (error) {}
	};

	const renderSelectCouponCode = () => {
		return (
			<>
				<Typography className="title-text-black">Promo code</Typography>
				<TextField
					size="small"
					autoComplete="off"
					onChange={(e) => {
						setCouponCode(e.target.value);
					}}
					value={couponCode}
					fullWidth
					variant="standard"
					InputProps={{
						disableUnderline: true,
						className: "promo-code-input normal-text",
					}}
				/>
			</>
		);
	};

	const onClickGoToCourseDetail = () => {
		if (!course) {
			return;
		}

		navigate(`/owned-courses/${course.courseSlug}`);
	};

	if (!course) {
		return null;
	}

	if (isBought) {
		return (
			<div className="course-checkout-information-container">
				<div className="bought-course-container">
					<Typography component="p" className="normal-text">
						You have bought this course
					</Typography>
					<Button onClick={onClickGoToCourseDetail} className="normal-button primary-button">
						View course detail
					</Button>
				</div>
			</div>
		);
	}

	return (
		<div className="course-checkout-information-container">
			<Typography className="title-text">REVIEW & FINISH</Typography>
			<Typography className="title-text-black">Classes</Typography>
			{ownedLessons.map((lesson) => (
				<CourseLessonItem lesson={lesson} key={lesson.id} />
			))}
			{renderSelectCouponCode()}
			<div className="online-course-lesson-item">
				<Typography className="lesson-title normal-text">
					Discount
				</Typography>
				<Typography className="normal-text">
					{USDFormatter.format(discountPrice)}
				</Typography>
			</div>
			<div className="online-course-lesson-item">
				<Typography className="lesson-title normal-text">
					Service fee
				</Typography>
				<Typography className="normal-text">
					{USDFormatter.format(serviceFee)}
				</Typography>
			</div>
			<div className="online-course-lesson-item">
				<Typography className="lesson-title normal-text bold">
					Total
				</Typography>
				<Typography className="normal-text">
					{USDFormatter.format(totalCost)}
				</Typography>
			</div>
			<div className="checkout-button-container">
				<Button
					className="normal-button primary-button"
					variant="contained"
					onClick={onClickCheckout}
				>
					Checkout
				</Button>
			</div>
		</div>
	);
}
