export * from "./courseStatus";
export * from "./courseType";
export * from "./documentType";
export * from "./inboxType";
export * from "./lessonType";
export * from "./paymentProductType";
export * from "./role";

export const MIN_NUMBER_OF_COURSE_DOCUMENT_IMAGE = 3;
export const MAX_NUMBER_OF_COURSE_DOCUMENT_IMAGE = 10;
export const MIN_NUMBER_OF_COURSE_DOCUMENT_VIDEO = 0;
export const MAX_NUMBER_OF_COURSE_DOCUMENT_VIDEO = 4;
export const MAX_COURSE_DOCUMENT_FILE_SIZE = 300;
