import { InputAdornment, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";

export function SummaryInfoInput({
	control,
	name,
	label,
	multiline,
	placeholder,
	rules,
	disallowInvalidInput,
	suffix,
	disabled,
}) {
	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({ field: { onChange, value }, fieldState: { error } }) => {
				return (
					<>
						<Typography
							component="p"
							className="summary-input-title normal-text"
						>
							{label}
						</Typography>
						<TextField
							helperText={error ? error.message : null}
							size="small"
							autoComplete="off"
							id={name}
							error={!!error}
							onChange={(e) => {
								if (disallowInvalidInput && rules?.pattern) {
									if (
										e.target.value !== "" &&
										!rules.pattern.value.test(e.target.value)
									) {
										return;
									}
								}
								onChange(e);
							}}
							value={value}
							fullWidth
							maxRows={5}
							multiline={multiline}
							placeholder={placeholder}
							variant="standard"
							disabled={disabled}
							InputProps={{
								disableUnderline: true,
								className: "summary-input-field normal-text",
								classes: {
									input: "input-field-inner",
								},
								endAdornment: suffix && (
									<InputAdornment position="end">
										<Typography className="summary-input-field normal-text">
											{suffix}
										</Typography>
									</InputAdornment>
								),
								onKeyDown(e) {
									if (!multiline && e.key === "Enter") {
										e.preventDefault();
									}
								},
							}}
						/>
					</>
				);
			}}
		/>
	);
}
