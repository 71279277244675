import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { apis, callApi } from "../../api";
import { roles } from "../../constants";
import { useAccount } from "../../hooks/useAccount";
import { OrkaFooter } from "../common/Footer/OrkaFooter";
import CarouselWithDots from "./HomeComponents/CarouselWithDots";
import { CourseItem } from "./HomeComponents/CourseItem";
import Navbar from "./navegacion/Navbar";

export default function Home() {
	const [courses, setCourses] = useState([]);
	const [onsiteName, setOnsiteName] = useState("");
	const [categoryName, setCategory] = useState("");
	const [formGetInTouch, setGetInTouch] = useState({
		name: "",
		mail: "",
		message: "",
	});

	const { account } = useAccount();

	const handleGetInTouch = async (e) => {
		e.preventDefault();
		try {
			await callApi({
				method: "POST",
				url: apis.GET_IN_TOUCH,
				body: formGetInTouch,
			});
			setGetInTouch({ name: "", mail: "", message: "" });
			alert("We will get in touch soon! ");
		} catch (error) {}
	};

	const handleSearchCourse = async (data) => {
		try {
			const response = await callApi({
				method: "POST",
				url: apis.SEARCH_COURSES,
				body: data,
			});
			setCourses(response);
		} catch (error) {}
	};

	const handleTextChange = (e) => {
		setOnsiteName(e.target.value);
	};

	const handleEvaluateInput = (e) => {
		if (e.target.name === "courseType") {
			setCategory(e.target.value);
		}
	};

	const handleSearch = () => {
		const objectSearch = { fullsearch: "" };

		// actually we need to make a reset but for now no clue

		if (onsiteName.trim().length !== 0) {
			objectSearch.fullsearch = onsiteName.trim();
		}

		if (categoryName) {
			objectSearch.category = categoryName;
		}

		handleSearchCourse(objectSearch);
	};

	const getFavoriteCourses = async () => {
		const courses = await callApi({
			method: "GET",
			url: apis.GET_FAVORITE_COURSES,
		});

		setCourses(courses);
	};

	useEffect(() => {
		getFavoriteCourses();
	}, []);

	if (account?.role === roles.MODERATOR) {
		return <Navigate to="/myorka" />;
	}

	return (
		<>
			<Navbar isInHome={true} />
			<div className="slogan-container">
				<div className="slogan-texts">
					<div className="slogan-title">LET YOUR DREAMS TAKE FLIGHT</div>
					<div className="slogan-desc">
						Let's make it happen, whether you want to learn to fly, dust off
						your flying skills, get certified, or add skills to your belt. We
						have a program for you!
					</div>
					<a href="#experiences" className="slogan-link Underline link">
						Find your class
					</a>
				</div>

				<div className="image-slogan-container">
					<img
						src={require("../imagenes/boy.png")}
						className="img-fluid mb-5 image-slogan1"
						alt="plane home"
						loading="lazy"
					/>
				</div>
			</div>
			<CarouselWithDots />
			<div
				className="Curso mt-1 container-experiences-favorite-cources"
				id="experiences"
			>
				<div className="row legend-experiences-container">
					<div className="legend-experiences col-sm-12" id="experiences">
						<h1 className="home-titles">Experiences</h1>
						<div className="home-texts experiencesText">
							There's a class for everyone, even if you have never touched a
							plane before, we've got you!
						</div>
						<div className="home-texts" style={{ color: "#F7C26D" }}>
							Browse our experiences and book your class today!
						</div>
					</div>
					<div className="col-sm-12 m-3">
						<div className="input-group justify-content-center">
							<input
								type="radio"
								className="btn-check"
								name="courseType"
								id="courseType1"
								value=""
								autoComplete="off"
								onClick={(e) => handleEvaluateInput(e)}
							/>
							<label className="btn btn-outline-primary" htmlFor="courseType1">
								All
							</label>

							<input
								type="radio"
								className="btn-check"
								name="courseType"
								id="courseType2"
								value="Onsite"
								autoComplete="off"
								onClick={(e) => handleEvaluateInput(e)}
							/>
							<label className="btn btn-outline-primary" htmlFor="courseType2">
								OnSite
							</label>

							<input
								type="radio"
								className="btn-check"
								name="courseType"
								id="courseType3"
								value="Online"
								autoComplete="off"
								onClick={(e) => handleEvaluateInput(e)}
							/>
							<label className="btn btn-outline-primary" htmlFor="courseType3">
								Online
							</label>
							<input
								type="text"
								id="input-by-place"
								placeholder="Search by skill or Theme"
								name="onsiteInput"
								className="w-50 getInTouch-input"
								value={onsiteName}
								onChange={(e) => handleTextChange(e)}
							/>
							<button
								type="button"
								className="btn btn-warning bi-search White"
								onClick={handleSearch}
							></button>
						</div>
					</div>
				</div>
				<div className="container mt-5 mb-5">
					{onsiteName && courses.length === 0 ? (
						<div className="getInTouch">
							<div className="text-center">
								<h4 className="titles-sections-info">
									Oh, there are no results with your keyword, <br /> but don’t
									worry. We are here to help you!
								</h4>
								<div className="overflow-hidden">
									<div className="container px-5">
										<img
											src="../imagenes/discuss.svg"
											className="img-fluid mb-5"
											alt="Something Great is coming"
											loading="lazy"
										/>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="phamHomeCourse">
							{/* Render your search results here */}
							{courses?.map((element, index) => {
								return (
									<CourseItem course={element} index={index} key={element.id} />
								);
							})}
						</div>
					)}
				</div>
				<div className="container">
					<div className="getInTouch mt-5">
						<h1 className="AktivGroteskEx-Black get-in-touch-pc-text">
							Get In Touch
						</h1>
						<div className="d-flex w-100 justify-content-center BackgroundDarkWhite BorderRadius28 formulario formGet">
							<form onSubmit={handleGetInTouch}>
								<div className="form-group m-3">
									<label htmlFor="name" className="AktivGroteskEx-Bold">
										Name
									</label>
									<input
										type="text"
										className="getInTouch-input"
										id="name"
										value={formGetInTouch.name}
										onChange={(e) =>
											setGetInTouch({
												...formGetInTouch,
												name: e.target.value,
											})
										}
										required
									/>
								</div>

								<div className="form-group m-3">
									<label htmlFor="email" className="AktivGroteskEx-Bold">
										Email
									</label>
									<input
										type="email"
										className="getInTouch-input"
										id="email"
										value={formGetInTouch.mail}
										onChange={(e) =>
											setGetInTouch({
												...formGetInTouch,
												mail: e.target.value,
											})
										}
										required
									/>
								</div>

								<div className="form-group m-3">
									<label htmlFor="message" className="AktivGroteskEx-Bold">
										Message
									</label>
									<textarea
										className="getInTouch-input text-area"
										id="message"
										value={formGetInTouch.message}
										onChange={(e) =>
											setGetInTouch({
												...formGetInTouch,
												message: e.target.value,
											})
										}
										required
									></textarea>
								</div>
								<div className="form-group m-3">
									<button
										type="submit"
										className="btn BackgroundYellow ProximaNova-Bold mt-3"
									>
										{" "}
										Send
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<footer>
				<OrkaFooter />
			</footer>
		</>
	);
}
