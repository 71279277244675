
// react, redux
import { useState,useEffect } from "react";

// components
import MenuProfile from "../Accont/MenuProfile";
import ReviewItemI from "./components/ReviewItemI";

// api manager
import { reqApi } from "../../../api";

import { useLocalStorage } from "../CreateAccount/useLocalStorage";

// styles

const ContentReviews = () => {

    const [token] = useLocalStorage("token");
    const [reviews, setReviews] = useState([]);

    const requestGet = () => {
        reqApi
            .get("/ratingsCourses/getAll",{
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            .then(resp=>{
                setReviews(resp.data);
                console.log('resp: ', resp);
            })
            .catch(err=>{
                console.log('There was an err at getting ratings --> ', err);
            });
    }

    useEffect(()=>{
        requestGet();
    }, []);

    return(
        <div className="container container-general-reviews mb-5" id="GrisCalis">
            <div className="row justify-content-md-between p-5">
                <div className="col-md-4 mb-4">
                    <MenuProfile active='Reviews' />
                </div>

                <div className="col-md-8 container-reviews">
                    <div className="row">
                        <h4 className="header-reviews">Reviews</h4>
                        <p className="text-reviews">Are you ready to update? Consider the aviation experiences you have delivered.</p>
                    </div>

                    <div className="content-reviews">
                        {reviews.length > 0 &&
                            reviews.map(({id, commentRating ,course, date, user}) => (
                                <ReviewItemI commentRating={commentRating} course={course} date={date} user={user} key={id}/>    
                            ))
                        }
                    </div>
                </div>

            </div>
        </div>
    )

}

export default ContentReviews;