import { useState } from "react";

import { Button, Grid, Typography } from "@mui/material";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import classNames from "classnames";
import moment from "moment";
import Calendar from "react-calendar";

import useScreenSize from "../../../../../hooks/useScreenSize";
import { formatHourMinute, formatTimezone } from "../../../../../utils";
import { useBooking, useBookingInit } from "../hooks/useBooking";


export function BookingLesson({ booking, setSelectedBooking }) {
	const lesson = booking.lesson;
	const teacherId = booking.teacherId;
	useBookingInit(teacherId);
	const { availableHours, bookings, bookASlot } = useBooking(teacherId);
	const { width } = useScreenSize();
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedTime, setSelectedTime] = useState(null);

	const isMobile = width <= 768;

	let spacing = 2;
	if (isMobile) {
		spacing = 1;
	}

	let availableTimeSlots = [];
	let isSelectedTimeAvailable = true;

	if (selectedDate) {
		const lessonBlockTime = lesson.blockTime;
		const dayOfWeek = selectedDate.getDay();
		const { startHour, startMinute, endHour, endMinute } = availableHours.find(
			(availableHour) => availableHour.dayOfWeek === dayOfWeek
		) ?? { startHour: 8, startMinute: 0, endHour: 20, endMinute: 0 };

		const currentTimezone = -new Date().getTimezoneOffset() / 60;
		const bookingOfThisDay = bookings
			.filter((bookingItem) => {
				if (bookingItem.id === booking.id) {
					return false;
				}
				const lessonStartTime = new Date(bookingItem.startTime);
				lessonStartTime.setHours(
					lessonStartTime.getHours() - currentTimezone + lesson.course.timezone
				);
				const lessonEndTime = new Date(bookingItem.endTime);
				lessonEndTime.setHours(
					lessonEndTime.getHours() - currentTimezone + lesson.course.timezone
				);

				const isStartTimeInTodayRange =
					lessonStartTime.toDateString() === selectedDate.toDateString() &&
					lessonStartTime.getHours() * 60 + lessonStartTime.getMinutes() >=
						startHour * 60 + startMinute &&
					lessonStartTime.getHours() * 60 + lessonStartTime.getMinutes() <=
						endHour * 60 + endMinute;

				const isEndTimeInTodayRange =
					lessonEndTime.toDateString() === selectedDate.toDateString() &&
					lessonEndTime.getHours() * 60 + lessonEndTime.getMinutes() >=
						startHour * 60 + startMinute &&
					lessonEndTime.getHours() * 60 + lessonEndTime.getMinutes() <=
						endHour * 60 + endMinute;
				return isStartTimeInTodayRange || isEndTimeInTodayRange;
			})
			.map((bookingItem) => {
				const lessonStartTime = new Date(bookingItem.startTime);
				lessonStartTime.setHours(
					lessonStartTime.getHours() - currentTimezone + lesson.course.timezone
				);
				const lessonEndTime = new Date(bookingItem.endTime);
				lessonEndTime.setHours(
					lessonEndTime.getHours() - currentTimezone + lesson.course.timezone
				);
				return {
					startTime:
						lessonStartTime.getHours() * 60 + lessonStartTime.getMinutes(),
					endTime: lessonEndTime.getHours() * 60 + lessonEndTime.getMinutes(),
				};
			})
			.sort((time1, time2) => time1.startTime - time2.startTime);

		if (bookingOfThisDay.length > 0) {
			if (
				startHour * 60 + startMinute <=
				bookingOfThisDay[0].startTime - lessonBlockTime
			) {
				availableTimeSlots.push({
					startTime: startHour * 60 + startMinute,
					endTime: bookingOfThisDay[0].startTime - lessonBlockTime,
				});
			}

			for (let i = 0; i < bookingOfThisDay.length - 1; i++) {
				if (
					bookingOfThisDay[i].endTime <=
					bookingOfThisDay[i + 1].startTime - lessonBlockTime
				) {
					availableTimeSlots.push({
						startTime: bookingOfThisDay[i].endTime,
						endTime: bookingOfThisDay[i + 1].startTime - lessonBlockTime,
					});
				}
			}

			if (
				bookingOfThisDay[bookingOfThisDay.length - 1].endTime <=
				endHour * 60 + endMinute - lessonBlockTime
			) {
				availableTimeSlots.push({
					startTime: bookingOfThisDay[bookingOfThisDay.length - 1].endTime,
					endTime: endHour * 60 + endMinute - lessonBlockTime,
				});
			}
		} else {
			availableTimeSlots.push({
				startTime: startHour * 60 + startMinute,
				endTime: endHour * 60 + endMinute,
			});
		}

		if (selectedTime && selectedTime.format("HH:mm") !== "Invalid date") {
			if (
				startHour * 60 + startMinute >
					selectedTime.get("hour") * 60 + selectedTime.get("minute") ||
				endHour * 60 + endMinute <
					selectedTime.get("hour") * 60 + selectedTime.get("minute")
			) {
				isSelectedTimeAvailable = false;
			} else {
				const foundSlot = availableTimeSlots.find(
					(slot) =>
						slot.startTime <=
							selectedTime.get("hour") * 60 + selectedTime.get("minute") &&
						slot.endTime >=
							selectedTime.get("hour") * 60 + selectedTime.get("minute")
				);
				isSelectedTimeAvailable = !!foundSlot;
			}
		}
	}

	const onClickBookASlot = async () => {
		if (!selectedDate || !selectedTime) {
			return;
		}

		const currentTimezone = -new Date().getTimezoneOffset() / 60;
		const startDate = selectedDate;
		startDate.setHours(selectedTime.get("hour") + currentTimezone - lesson.course.timezone);
		startDate.setMinutes(selectedTime.get("minute"));
		startDate.setSeconds(0);
		startDate.setMilliseconds(0);

		const body = {
			startDate: startDate.toISOString(),
			lessonId: lesson.id,
		};

		await bookASlot(booking.summaryId, booking.id, body, {
			...booking,
			startTime: startDate.toISOString(),
			endTime: moment(startDate).add(lesson.blockTime, "minute").toISOString(),
		});

		setSelectedBooking(null);
	};

	return (
		<>
			<Typography className="normal-text">
				{`Select date and time for class: ${lesson.title}`}
			</Typography>
			{lesson.course.timezone !== null && (
				<Typography className="normal-text">
					{`Note: Displayed date and time is in ${formatTimezone(
						lesson.course.timezone
					)}`}
				</Typography>
			)}
			<Grid container spacing={spacing}>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
					<Typography className="select-date-time normal-text">
						Select date
					</Typography>
					<Calendar
						onChange={(value) => {
							if (
								value instanceof Date &&
								value.toDateString() !== selectedDate?.toDateString()
							) {
								setSelectedDate(value);
							}
						}}
						value={selectedDate}
						minDate={new Date(new Date().getTime() + 86400000)}
						className="normal-text"
						locale="en-US"
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
					{selectedDate && (
						<>
							<Typography className="select-date-time normal-text">
								Select start time
							</Typography>
							<Typography className="normal-text">
								Available start time:
							</Typography>
							{availableTimeSlots.map(({ startTime, endTime }) => (
								<Typography key={`${startTime}-${endTime}`} className="normal-text">
									{`${formatHourMinute(
										Math.floor(startTime / 60),
										startTime % 60
									)} - ${formatHourMinute(Math.floor(endTime / 60), endTime % 60)}`}
								</Typography>
							))}
							<Typography className="normal-text">
								Select start time:
							</Typography>
							<div>
								<LocalizationProvider dateAdapter={AdapterMoment}>
									<DesktopTimePicker
										slotProps={{
											textField: {
												variant: "standard",
												InputProps: { disableUnderline: true },
											},
										}}
										ampm={false}
										value={selectedTime}
										onChange={setSelectedTime}
									/>
								</LocalizationProvider>
							</div>
							{selectedTime &&
								selectedTime.format("HH:mm") !== "Invalid date" &&
								!isSelectedTimeAvailable && (
									<Typography className="normal-text">{`Instructor is busy at ${selectedTime.format(
										"HH:mm"
									)}`}</Typography>
								)}
							{selectedTime &&
								selectedTime.format("HH:mm") !== "Invalid date" &&
								isSelectedTimeAvailable && (
									<Typography className="normal-text">
										{`Instructor is available at ${selectedTime.format(
											"HH:mm"
										)}`}
									</Typography>
								)}
							<Button
								className={classNames("normal-button primary-button", {
									"disabled-button":
										!selectedTime ||
										selectedTime.format("HH:mm") === "Invalid date" ||
										!isSelectedTimeAvailable,
								})}
								disabled={
									!selectedTime ||
									selectedTime.format("HH:mm") === "Invalid date" ||
									!isSelectedTimeAvailable
								}
								onClick={onClickBookASlot}
							>
								Book
							</Button>
						</>
					)}
				</Grid>
			</Grid>
		</>
	);
}
