import { useState } from "react";

import { Button, Typography } from "@mui/material";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { FaHammer } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";

import { apis, callApi } from "../../../../api";
import { courseType } from "../../../../constants";
import { useAccount } from "../../../../hooks/useAccount";
import { omitEmpty, validators } from "../../../../utils";
import NavbarOrka from "../../navegacion/Navbar";
import { CreateCourseInfoInput } from "./components/CreateCourseInfoInput";

import "./create_course.scss";

export function CreateCourse() {
	const navigate = useNavigate();
	const location = useLocation();

	// Get course type from location state (if using navigate)
	// Default is Online if access via URL
	const [selectedCourseType, setSelectedCourseType] = useState(
		location.state?.courseType ?? courseType.ONLINE
	);

	const { account } = useAccount();

	const { control, handleSubmit } = useForm({
		defaultValues: {
			name: "",
			description: "",
			important: "",
			workload: "",
			experience: "",
			instructor: "",
		},
	});

	const createCourseFormFields = [
		{
			name: "name",
			placeholder: "Your crafted course",
			label: "COURSE NAME*",
			labelClassName: "input-yellow-label",
			inputClassName: "input-field",
			rules: {
				required: {
					value: true,
					message: "Course name is required.",
				},
				pattern: {
					value: validators.nonEmptyString,
					message: "Course name is required.",
				},
			},
			endGroup: true,
		},
		{
			name: "description",
			placeholder:
				"How would you describe your course in a great one sentence?",
			label: "COURSE DESCRIPTION",
			labelClassName: "input-yellow-label",
			inputClassName: "input-field",
			multiline: true,
		},
		{
			name: "important",
			placeholder:
				"What problems are you helping your students overcome? What result does your course help someone achieve? What knowledge, skills, behaviors, and competencies are you transferring?",
			label: "Why is this important to you?",
			labelClassName: "input-normal-label",
			inputClassName: "input-field",
			multiline: true,
		},
		{
			name: "workload",
			placeholder: "How many days or months are required to complete the work?",
			label: "What is the Workload?",
			labelClassName: "input-normal-label",
			inputClassName: "input-field",
			multiline: true,
		},
		{
			name: "experience",
			placeholder:
				"What types of content and activities are included in this course?",
			label: "How is the learning experience?",
			labelClassName: "input-normal-label",
			inputClassName: "input-field",
			multiline: true,
			endGroup: true,
		},
		{
			name: "instructor",
			placeholder:
				"What is your name? What experience makes you unique? What motivates you to teach this course?",
			label: "MEET YOUR INSTRUCTOR",
			labelClassName: "input-yellow-label",
			inputClassName: "input-field",
			multiline: true,
			endGroup: true,
		},
	];

	const onClickCreateCourse = async (data) => {
		if (!account) {
			return;
		}

		try {
			const { name, description, important, workload, experience, instructor } =
				data;

			const body = omitEmpty({
				name,
				description,
				important,
				workload,
				experience,
				instructor,
				type: selectedCourseType,
				teacherId: account.id,
			});

			const response = await callApi({
				method: "POST",
				url: apis.COURSES,
				body,
			});

			const course = response?.course;
			const courseSlug = course?.courseSlug;

			if (course && courseSlug) {
				navigate(`/my-courses/${courseSlug}`);
			}
		} catch (error) {}
	};

	return (
		<div className="create-course-container">
			<NavbarOrka />
			<div className="select-course-type-container">
				<Typography component="h2" className="title">
					Will your Course Be
					<br />
					Online or Onsite
				</Typography>
				<Typography component="p" className="subtitle">
					You can create an Online Course with Videos or an Onsite Course with
					In-Person instruction.
				</Typography>
				<div className="buttons-container">
					{[courseType.ONLINE, courseType.ONSITE].map((type) => (
						<Button
							variant="contained"
							className={classNames("button", {
								selected: selectedCourseType === type,
							})}
							onClick={() => {
								setSelectedCourseType(type);
							}}
							key={type}
						>
							{type}
						</Button>
					))}
				</div>
				<Typography component="p" className="description">
					You can do both! Just pick one to begin with.
				</Typography>
			</div>
			<div className="course-basic-info-container">
				<form onSubmit={handleSubmit(onClickCreateCourse)}>
					{createCourseFormFields.map((field) => {
						return (
							<div key={field.name}>
								<CreateCourseInfoInput control={control} {...field} />
								{field.endGroup && (
									<div
										style={{
											height: 1,
											width: "100%",
											backgroundColor: "#DCE3E5",
										}}
									/>
								)}
							</div>
						);
					})}
					<Typography component="p" className="note">
						Fields marked with * are needed to craft your course.
						<br />
						Other fields, you can fill later.
					</Typography>
					<div className="craft-button-container">
						<Button
							variant="contained"
							className="craft-button"
							type="submit"
							startIcon={<FaHammer className="craft-button-icon" />}
						>
							Craft
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
}
