import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import "./legacy.css";
import "./custome.scss";
import "./styles/button.scss";
import "./styles/dropzone.scss";
import "./styles/text.scss";
import "./styles/icon.scss";
import "./styles/separator.scss";
import "./styles/calendar.scss";

//Estan ordenadas por abecedario
import About from "./components/paginas/About/About";
import FinancesConfiguration from "./components/paginas/Profile/FinancesConfiguration";
import Home from "./components/paginas/Home";
import { Login } from "./components/paginas/LogIn/Login";
import Reviews from "./components/paginas/Profile/Reviews";
import { SignUp } from "./components/paginas/CreateAccount/SignUp";
import { Welcome } from "./components/paginas/LogIn/Welcome";
import { WhatCanIDo } from "./components/paginas/CreateAccount/WhatCanIDo";
import { TokenService, useToken } from "./hooks/useToken";
import { AccountService, useAccount } from "./hooks/useAccount";
import { apis, callApi } from "./api";
import { useCallback, useEffect } from "react";
import {
	AuthGuard,
	GuestGuard,
	RoleBasedGuard,
} from "./components/common/Guard";
import { roles } from "./constants";
import { MyOrka } from "./components/paginas/myorka/MyOrka";
import Profile from "./components/paginas/Accont/Profile";
import Documents from "./components/paginas/Profile/Documents";
import Safety from "./components/paginas/Profile/Safety";
import ResetPassword from "./components/paginas/ResetPassword/ResetPassword";
import { CreateCourse } from "./components/paginas/Instructor/CreateCourse/CreateCourse";
import { MyCourseInformation } from "./components/paginas/Instructor/MyCourseInformation/MyCourseInformation";
import { Stripe } from "./components/paginas/Instructor/Stripe/Stripe";
import { StripeService } from "./hooks/useStripe";
import { StripeSuccess } from "./components/paginas/Instructor/StripeSuccess/StripeSuccess";
import { PreviewCourse } from "./components/paginas/User/PreviewCourse/PreviewCourse";
import { CheckoutCourse } from "./components/paginas/User/CheckoutCourse/CheckoutCourse";
import { MyOrkaInstructor } from "./components/paginas/Instructor/MyOrka/MyOrkaInstructor";
import { MyOrkaStudent } from "./components/paginas/Student/MyOrka/MyOrkaStudent";
import { OwnedCourse } from "./components/paginas/Student/OwnedCourse/OwnedCourse";
import { Booking } from "./components/paginas/Student/Booking/Booking";
import { PaymentSuccess } from "./components/paginas/User/PaymentSuccess/PaymentSuccess";
import { Chat } from "./components/paginas/Student/Chat/Chat";
import { CreateAccount } from "./components/paginas/CreateAccount/CreateAccount";

function App() {
	const { token } = useToken();
	const { account } = useAccount();
	const navigate = useNavigate();

	const getUserInfoByToken = useCallback(async () => {
		try {
			const response = await callApi({
				method: "GET",
				url: apis.GET_USER_BY_TOKEN,
			});
			const {stripeInfo, ...user} = response;
			AccountService.saveAccount(user);
			StripeService.saveStripeData(stripeInfo);
		} catch (error) {
			if (error?.response?.status === 404) {
				TokenService.clearToken();
				navigate("/", { replace: true });
			}
		}
	}, [navigate]);

	useEffect(() => {
		// If can get token from local storage but did not get user info yet
		// just call api to get user info
		if (token && !account) {
			getUserInfoByToken();
		}
	}, [account, getUserInfoByToken, token]);

	const routes = useRoutes([
		{
			path: "/reviews",
			element: <Reviews />,
		},
		// Below is updated routes
		{
			path: "/",
			element: <Home />,
		},
		{
			path: "/sign-in",
			element: (
				<GuestGuard>
					<Login />
				</GuestGuard>
			),
		},
		{
			path: "/sign-up",
			children: [
				{
					index: true,
					element: (
						<GuestGuard>
							<SignUp />
						</GuestGuard>
					),
				},
				{
					path: "student",
					element: (
						<GuestGuard>
							<CreateAccount role={"student"} />
						</GuestGuard>
					),
				},
				{
					path: "instructor",
					element: (
						<GuestGuard>
							<CreateAccount role={"instructor"} />
						</GuestGuard>
					),
				},
			],
		},
		{
			path: "/reset-password/:token",
			element: (
				<GuestGuard>
					<ResetPassword />
				</GuestGuard>
			),
		},
		{
			path: "/what-can-i-do",
			element: (
				<AuthGuard>
					<RoleBasedGuard accessibleRoles={[roles.STUDENT, roles.INSTRUCTOR]}>
						<WhatCanIDo />
					</RoleBasedGuard>
				</AuthGuard>
			),
		},
		{
			path: "/welcome-back",
			element: <Welcome />,
		},
		{
			path: "/about",
			element: <About />,
		},
		// TODO: Fix this route later
		{
			path: "/myorka",
			children: [
				{
					path: "",
					element: (
						<AuthGuard>
							<RoleBasedGuard
								accessibleRoles={[roles.STUDENT, roles.INSTRUCTOR, roles.MODERATOR]}
							>
								<MyOrka />
							</RoleBasedGuard>
						</AuthGuard>
					),
				},
				{
					path: "instructor",
					element: (
						<AuthGuard>
							<RoleBasedGuard
								accessibleRoles={[roles.INSTRUCTOR, roles.MODERATOR]}
							>
								<MyOrkaInstructor />
							</RoleBasedGuard>
						</AuthGuard>
					),
				},
				{
					path: "student",
					element: (
						<AuthGuard>
							<RoleBasedGuard
								accessibleRoles={[roles.STUDENT, roles.INSTRUCTOR, roles.MODERATOR]}
							>
								<MyOrkaStudent />
							</RoleBasedGuard>
						</AuthGuard>
					),
				},
			],
		},
		{
			path: "/profile",
			element: (
				<AuthGuard>
					<RoleBasedGuard accessibleRoles={[roles.STUDENT, roles.INSTRUCTOR]}>
						<Profile />
					</RoleBasedGuard>
				</AuthGuard>
			),
		},
		{
			path: "/documents",
			element: (
				<AuthGuard>
					<RoleBasedGuard accessibleRoles={[roles.STUDENT, roles.INSTRUCTOR]}>
						<Documents />
					</RoleBasedGuard>
				</AuthGuard>
			),
		},
		{
			path: "/safety",
			element: (
				<AuthGuard>
					<RoleBasedGuard accessibleRoles={[roles.STUDENT, roles.INSTRUCTOR]}>
						<Safety />
					</RoleBasedGuard>
				</AuthGuard>
			),
		},
		{
			path: "/finances",
			element: <FinancesConfiguration />,
		},
		{
			path: "/my-courses",
			children: [
				{
					path: "new",
					element: (
						<AuthGuard>
							<RoleBasedGuard accessibleRoles={[roles.INSTRUCTOR]}>
								<CreateCourse />
							</RoleBasedGuard>
						</AuthGuard>
					),
				},
				{
					path: ":slugOrUuid",
					element: (
						<AuthGuard>
							<RoleBasedGuard accessibleRoles={[roles.INSTRUCTOR]}>
								<MyCourseInformation />
							</RoleBasedGuard>
						</AuthGuard>
					),
				},
			],
		},
		{
			path: "/stripe",
			children: [
				{
					path: "",
					element: (
						<AuthGuard>
							<RoleBasedGuard accessibleRoles={[roles.INSTRUCTOR]}>
								<Stripe />
							</RoleBasedGuard>
						</AuthGuard>
					),
				},
				{
					path: "success",
					element: (
						<AuthGuard>
							<RoleBasedGuard accessibleRoles={[roles.INSTRUCTOR]}>
								<StripeSuccess />
							</RoleBasedGuard>
						</AuthGuard>
					),
				},
				{
					path: "refresh",
					element: (
						<AuthGuard>
							<RoleBasedGuard accessibleRoles={[roles.INSTRUCTOR]}>
								<StripeSuccess />
							</RoleBasedGuard>
						</AuthGuard>
					),
				},
			],
		},
		{
			path: "/courses",
			children: [
				{
					path: ":slug",
					children: [
						{
							path: "preview",
							element: (
								<PreviewCourse />
							),
						},
						{
							path: "checkout",
							element: (
								<AuthGuard>
									<CheckoutCourse />
								</AuthGuard>
							),
						},
					],
				},
			],
		},
		{
			path: "/owned-courses",
			children: [
				{
					path: ":slug",
					element: (
						<AuthGuard>
							<OwnedCourse />
						</AuthGuard>
					),
				},
			],
		},
		{
			path: "/booking",
			element: (
				<AuthGuard>
					<Booking />
				</AuthGuard>
			),
		},
		{
			path: "/payment/success",
			element: (
				<AuthGuard>
					<PaymentSuccess />
				</AuthGuard>
			),
		},
		{
			path: "/messages",
			children: [
				{
					path: "",
					element: (
						<AuthGuard>
							<Chat />
						</AuthGuard>
					),
				},
				{
					path: ":chatId",
					element: (
						<AuthGuard>
							<Chat />
						</AuthGuard>
					),
				},
			],
		},
		{
			path: "*",
			element: <Navigate to="/" replace />,
		},
	]);

	return <div className="App">{routes}</div>;
}

export default App;
