import React from "react";

import { Button, Grid, IconButton, Typography } from "@mui/material";
import { FaChevronLeft } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";

import useScreenSize from "../../../hooks/useScreenSize";
import NavbarOrka from "../navegacion/Navbar";

import "./sign_up.scss";

export function SignUp() {
	const navigate = useNavigate();
	const { width } = useScreenSize();
	const isMobile = width <= 768;

	return (
		<div style={{ height: "100vh" }}>
			<NavbarOrka isHiThere isInHome />
			<div className="select-sign-up-role-container">
				<div className="select-sign-up-role-content-container">
					<Grid container className="select-sign-up-role-box">
						{!isMobile && (
							<Grid
								item
								xs={12}
								sm={12}
								md={3}
								lg={3}
								xl={3}
								className="left-column"
							>
								<Typography className="sign-up-text">SIGN UP</Typography>
							</Grid>
						)}
						<Grid
							item
							xs={12}
							sm={12}
							md={9}
							lg={9}
							xl={9}
							className="right-column"
						>
							<IconButton
								className="back-icon"
								onClick={() => {
									navigate("/sign-in");
								}}
							>
								<FaChevronLeft className="normal-icon" />
							</IconButton>
							<div className="right-column-content-container">
								<Typography className="welcome-text">WELCOME</Typography>
								<Typography className="normal-text sign-up-sub-text">
									How would you like to register as today?
								</Typography>
								<div className="select-role-button-group">
									<Link to="/sign-up/student">
										<Button
											className="normal-button select-role-button"
											variant="contained"
										>
											Student
										</Button>
									</Link>

									<Link to="/sign-up/instructor">
										<Button
											className="normal-button select-role-button"
											variant="contained"
										>
											Instructor
										</Button>
									</Link>
								</div>
								<Typography className="normal-text sign-up-sub-text">
									You can do both! Just pick one to begin with.
								</Typography>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		</div>
	);
}
