import { useRef } from "react";

import { IconButton, Tooltip } from "@mui/material";
import { IoClose } from "react-icons/io5";

import { FileViewer } from "../../../../../common/FileViewer/FileViewer";
import { ImageViewer } from "../../../../../common/FileViewer/ImageViewer";
import { useMyCourseLessonsInstructor } from "../../hooks/useMyCourseInstructor";

export function CourseLessonHelperDocumentItem({lessonId, document}) {
	const { removeDocument } = useMyCourseLessonsInstructor();

	const imageViewerRef = useRef(null);

	const onClickOpenFile = () => {
		if (document.contentType.startsWith("image")) {
			imageViewerRef.current?.open({ url: document.url });
		}
	};

	const onClickRemoveFile = () => {
		removeDocument(lessonId, document.id);
	};

	if (document.uploadId) {
		return (
			<div className="helper-document-container normal-text">
				Uploading... ({document.progress}%)
			</div>
		);
	}

	return (
		<div key={document.id} className="helper-document-container">
			<div
				className="normal-text file-name"
				onClick={onClickOpenFile}
			>
				{document.name}
			</div>
			<Tooltip title="Remove">
				<IconButton className="icon-button" onClick={onClickRemoveFile}>
					<IoClose />
				</IconButton>
			</Tooltip>
			<ImageViewer ref={imageViewerRef} />
		</div>
	);
}