/**
 * @param {number} timezoneInNumber 
 */
export function formatTimezone(timezoneInNumber) {
	if (timezoneInNumber >= 0) {
		return `GMT+${timezoneInNumber}`;
	}

	return `GMT${timezoneInNumber}`;
}
