import React, { useState } from "react";

import { apis, callApi } from "../../../api";
import { useAccount } from "../../../hooks/useAccount";
import MenuProfile from "../Accont/MenuProfile";

const ContentSafetyStudents = () => {
	const { account } = useAccount();

	const [reportType, setReportType] = useState("0");
	const [reportReason, setReportReason] = useState("");

	const sendReasons = async (e) => {
		e.preventDefault();

		if (!account) {
			return;
		}

		if (reportType === "0") {
			alert("You have to choose reason for your report!");
			return;
		}

		try {
			const objectReasons = {
				reportType,
				reportReason,
			};

			await callApi({
				method: "POST",
				url: apis.REPORT_CASE,
				body: objectReasons,
			});
			
			e.target.reset();
      setReportType('0');
			setReportReason(''); 
			alert("Thank you for your report. We will take action");
		} catch (error) {
			alert("There was an error sending the report");
		}
	};

	return (
		<div
			className="container container-general-safety mb-3"
			id="GrisCalis"
		>
			<div className="row justify-content-md-between p-5">
				<div className="col-md-4 mb-4">
					<MenuProfile active="Safety" />
				</div>
				<div className="col-xs-12 col-md-8 container-safety">
					<form onSubmit={sendReasons}>
						<div className="row">
							<h4 className="header-safety">Safety</h4>
							<p className="text-safety">
								Are you feeling unsafe? Report any unsafe activity here.{" "}
								<span className="text-safety-span">
									ORKA is committed to creating a safe space for everyone.
								</span>
							</p>
							<h5 className="header-unsafe-activity">Report Unsafe Activity</h5>

							<div className="form-control">
								<select
									className="form-select col-form-label-lg"
									id="TextGrayColor"
									value={reportType}
									onChange={(e) => {
										setReportType(e.target.value);
									}}
									required
								>
									<option value="0" disabled>
										Choose reason for your report
									</option>
									<option value="instructorFailedObjectives" className="option-custom-select">
										Instructor failed to accomplish class objectives
									</option>
									<option value="instructorUnprepared" className="option-custom-select">
										Instructor showed up unprepared
									</option>
									<option value="operateOutsideLimitations" className="option-custom-select">
										I believe we operated outside airplane limitations
									</option>
									<option value="instructorSmelledLikeSubstances" className="option-custom-select">
										Instructor smelled like alcohol or marijuana
									</option>
									<option value="questionableBehavior" className="option-custom-select">
										Instructor displays questionable verbal and physical behavior
									</option>
									<option value="trespassedBoundaries" className="option-custom-select">
										Teacher-Student boundaries have been trespassed
									</option>
									<option value="sexualHarassment" className="option-custom-select">
										Sexual Harassment
									</option>
									<option value="sexualAssault" className="option-custom-select">
										Sexual Assault
									</option>
									<option value="other" className="option-custom-select">
									Other
									</option>
								</select>

								<textarea
									className="form-control textarea-safety"
									rows="12"
									style={{ resize: "none" }}
									placeholder="We are sorry to hear that you feel unsafe at this time, in a few words explain your reasons for your report..."
									value={reportReason}
									onChange={(e) => {
										setReportReason(e.target.value);
									}}
									required
								/>
							</div>
						</div>

						<div className="box-button">
							<button
								type="submit"
								className="btn btn-light"
								id="SafetyBtnSubmit"
							>
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ContentSafetyStudents;
