import { useEffect, useState } from "react";

import { apis, callApi } from "../api";
import { useAccount } from "./useAccount";

const cacheTime = 10 * 60 * 1000;

export const InstructorAgendaService = {
	isInitialized: false,
	initializedAt: null,
	clearDataTimeout: null,
	listeners: [],
	agendas: [],

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	saveAgendas(agendas) {
		this.agendas = agendas;
		this.emitChanges();
	},

	reschedule(newAgenda) {
		this.agendas = this.agendas.filter((agenda) => agenda.id !== newAgenda.id);
		this.agendas.push(newAgenda);
		this.agendas = [...this.agendas];
		this.emitChanges();
	},

	clearData() {
		this.agendas = [];
		this.isInitialized = false;
		this.initializedAt = null;
		if (this.clearDataTimeout) {
			clearTimeout(this.clearDataTimeout);
			this.clearDataTimeout = null;
		}
		this.emitChanges();
	},

	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

export function useInstructorAgendaInit() {
	const { account } = useAccount();
	const [isLoading, setIsLoading] = useState(
		!InstructorAgendaService.isInitialized
	);

	useEffect(() => {
		const getTeacherAgenda = async () => {
			try {
				if (!account?.id) {
					return;
				}

				const response = await callApi({
					method: "GET",
					url: `${apis.GET_TEACHER_AGENDA}/${account.id}`,
				});

				if (!response?.bookings) {
					return;
				}

				InstructorAgendaService.saveAgendas(response.bookings);
			} catch (error) {
			} finally {
				InstructorAgendaService.isInitialized = true;
				setIsLoading(false);
			}
		};

		if (!account?.id) {
			return;
		}

		if (InstructorAgendaService.clearDataTimeout) {
			clearTimeout(InstructorAgendaService.clearDataTimeout);
			InstructorAgendaService.clearDataTimeout = null;
		}

		if (
			!InstructorAgendaService.isInitialized ||
			InstructorAgendaService.initializedAt ||
			Date.now() - InstructorAgendaService.initializedAt > cacheTime
		) {
			getTeacherAgenda();
		}
	}, [account.id]);

	useEffect(() => {
		return () => {
			if (!InstructorAgendaService.isInitialized || !InstructorAgendaService.initializedAt) {
				return;
			}

			if (Date.now() - InstructorAgendaService.initializedAt > cacheTime) {
				InstructorAgendaService.clearData();
			} else {
				InstructorAgendaService.clearDataTimeout = setTimeout(() => {
					InstructorAgendaService.clearData();
				}, cacheTime - Date.now() + InstructorAgendaService.initializedAt);
			}
		};
	}, []);

	return { isLoading };
}

export function useInstructorAgendas() {
	const [agendas, setAgendas] = useState(InstructorAgendaService.agendas);

	useEffect(() => {
		const updateSummaries = () => {
			setAgendas(InstructorAgendaService.agendas);
		};

		InstructorAgendaService.addEventListener(updateSummaries);

		return () => {
			InstructorAgendaService.removeEventListener(updateSummaries);
		};
	}, []);

	return { agendas };
}
