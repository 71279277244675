import React from "react";

import { FaMagnifyingGlass, FaUser } from "react-icons/fa6";
import { Link } from "react-router-dom";

import { useAccount } from "../../../hooks/useAccount";
import { useToken } from "../../../hooks/useToken";

const NavbarOrka = ({
	transparent,
	isInHome = false,
	chatScreen,
	isHiThere,
}) => {
	const { token } = useToken();
	const { account } = useAccount();

	const NavWithoutUser = () => {
		return (
			<nav className="rightElements">
				<Link
					className="navbar-brand px-auto Opacity68p FontSize125px fontMobileNav navbar-icon-equality"
					to={"/#experiences"}
					style={{ color: "#6B8C95" }}
				>
					<FaMagnifyingGlass />
				</Link>
				<a
					className="navbar-brand px-auto Green Opacity68p FontSize13px fontMobileNav"
					href="/"
					style={{ marginLeft: "1.4rem" }}
				>
					HOME
				</a>
				<Link
					className={`navbar-brand px-auto Opacity68p FontSize13px fontMobileNav font font-weight-200 navbar-icon-equality`}
					// id="Linkstaticob"
					to="/about"
					style={{ color: "#6B8C95" }}
				>
					ABOUT
				</Link>
				<Link
					className="navbar-brand px-auto Green FontSize13px fontMobileNav link-login Bold navbar-icon-equality"
					to="/sign-in"
				>
					SIGN IN
				</Link>
			</nav>
		);
	};

	const NavUser = () => {
		return (
			<nav className="navbar rightElements">
				<Link
					className="navbar-brand px-auto Opacity68p FontSize13px fontMobileNav navbar-icon-equality"
					to={"/#experiences"}
					style={{ color: "#6B8C95" }}
				>
					<FaMagnifyingGlass />
				</Link>
				<Link
					className={`${
						isHiThere ? "hidden" : ""
					} navbar-brand px-auto Opacity68p FontSize13px fontMobileNav font font-weight-200 navbar-icon-equality`}
					to={"/about"}
					style={{ color: "#6B8C95" }}
				>
					ABOUT
				</Link>
				{chatScreen && (
					<Link
						className="navbar-brand px-auto Green Opacity68p FontSize13px fontMobileNav myOrka-link navbar-icon-equality Pointer"
						to="/messages"
					>
						INBOX
						<i className=" pl-10 fa-solid fa-comment"></i>
					</Link>
				)}
				<Link
					className="navbar-brand px-auto Green Opacity68p FontSize13px fontMobileNav myOrka-link navbar-icon-equality"
					id="Linkstaticob"
					to={"/myorka"}
				>
					myORKA
				</Link>

				{account?.role !== "ADMIN" ? (
					<Link
						className="navbar-brand px-auto Green Opacity68p FontSize13px fontMobileNav  navbar-icon-equality"
						to={"/profile"}
						id="Linkstaticob"
					>
						<FaUser />
					</Link>
				) : (
					<Link
						className="navbar-brand px-auto Green Opacity68p FontSize13px fontMobileNav  navbar-icon-equality"
						id="Linkstaticob"
					>
						<img
							src="./imagenes/moderador.svg"
							alt="moderator img"
							className="img-moderador"
						/>
					</Link>
				)}
			</nav>
		);
	};

	let containerClassName = "w-100 NavBackground";
	if (isInHome) {
		containerClassName = "w-100 NavBackground fixed-top";
	} else if (transparent) {
		containerClassName = "w-100";
	}

	return (
		<div className={containerClassName}>
			<div className="my-orka-nav-bar container-header-myOrka">
				<div className="navbar-brand me-md-auto align-self-center">
					<Link to="/">
						<img className="svg-logo" src={require("../../imagenes/orka-logo.png")} alt="logo" />
					</Link>
				</div>
				{/* !token means non signed in user and account means signed in user and we know their role */}
				{(!token || account) && (
					<div className="align-self-center">
						{account ? <NavUser /> : <NavWithoutUser />}
					</div>
				)}
			</div>
		</div>
	);
};

export default NavbarOrka;
