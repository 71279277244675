import { useState } from "react";

import { Box, CircularProgress } from "@mui/material";

export function CourseDocumentImage({ file }) {
	const [isLoading, setIsLoading] = useState(true);

	const onLoad = () => {
		setIsLoading(false);
	};

	return (
		<div className="media-container">
			<img src={file.url} alt={file.name} className="media" onLoad={onLoad} />
			{isLoading && (
				<Box sx={{ position: "absolute", display: "inline-flex" }}>
					<CircularProgress />
				</Box>
			)}
		</div>
	);
}
