import { useParams } from "react-router-dom";

import { courseType } from "../../../../../../constants";
import { useGoogleMap } from "../../../../../../hooks/useGoogleMap";
import { useMyOwnedCoursesStudent } from "../../../../../../hooks/useMyOwnedCoursesStudent";
import { CourseInfo } from "../CourseInfo";
import { CourseLocation } from "./CourseLocation";

export function CourseBasicInformation() {
	const params = useParams();
	const slug = params.slug ?? "";
	const { course } = useMyOwnedCoursesStudent(slug);

	const { isLoaded } = useGoogleMap();

	const courseBasicInfo = [
		{
			label: "COURSE NAME",
			labelClassName: "yellow-label",
			value: course?.name || "No information",
			valueClassName: "normal-text basic-info-value",
			endGroup: true,
		},
		{
			label: "COURSE DESCRIPTION",
			labelClassName: "yellow-label",
			value: course?.description || "No information",
			valueClassName: "normal-text basic-info-value",
		},
		{
			label: "Why is this important to you?",
			labelClassName: "normal-label",
			value: course?.important || "No information",
			valueClassName: "normal-text basic-info-value",
		},
		{
			label: "What is the Workload?",
			labelClassName: "normal-label",
			value: course?.workload || "No information",
			valueClassName: "normal-text basic-info-value",
		},
		{
			label: "How is the learning experience?",
			labelClassName: "normal-label",
			value: course?.experience || "No information",
			valueClassName: "normal-text basic-info-value",
			endGroup: true,
		},
		{
			label: "MEET YOUR INSTRUCTOR",
			labelClassName: "yellow-label",
			value: course?.instructor || "No information",
			valueClassName: "normal-text basic-info-value",
			endGroup: true,
		},
	];

	const renderCourseLocation = () => {
		if (!course) {
			return null;
		}

		if (course.type === courseType.ONSITE && isLoaded) {
			return (
				<>
					<CourseLocation />
					<div className="separator" />
				</>
			);
		}
	};

	if (!course) {
		return null;
	}

	return (
		<div className="course-basic-info-container">
			{courseBasicInfo.map((info) => {
				return (
					<div key={info.label}>
						<CourseInfo {...info} />
						{info.endGroup && <div className="separator" />}
					</div>
				);
			})}
			{renderCourseLocation()}
		</div>
	);
}
