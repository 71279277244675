import { useEffect, useState } from "react";

let listeners = [];

export const TokenService = {
	addEventListener: (cb) => {
		listeners = listeners.filter((listener) => listener !== cb);
		listeners.push(cb);
	},

	/**
	 * Save token to localStorage and emit changes to all listeners
	 * @param {string} newToken
	 */
	saveToken: (newToken) => {
		localStorage.setItem("token", newToken);
		TokenService.emitChanges(newToken);
	},

	clearToken: () => {
		localStorage.removeItem("token");
		TokenService.emitChanges(null);
	},

	/**
	 * Send new token to all listeners
	 * @param {string} newToken new access token
	 */
	emitChanges: (newToken) => {
		for (const listener of listeners) {
			listener(newToken);
		}
	},

	removeEventListener: (cb) => {
		listeners = listeners.filter((listener) => listener !== cb);
	},
};

export function useToken() {
	const [token, setToken] = useState(localStorage.getItem("token"));

	useEffect(() => {
		const updateToken = (newToken) => {
			setToken(newToken);
		};

		TokenService.addEventListener(updateToken);

		return () => {
			TokenService.removeEventListener(updateToken);
		};
	}, []);

	return { token };
}
