import { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { apis, callApi } from "../../../../../api";

const PreviewCourseService = {
	listeners: [],
	savedCourse: null,

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	saveCourse(newCourse) {
		this.savedCourse = newCourse;
		this.emitChanges();
	},

	clearData() {
		this.savedCourse = null;
		this.emitChanges();
	},

	/**
	 * Send new course data to all listeners
	 */
	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

/**
 * This hook should call only once
 */
export function usePreviewCourseInit() {
	const params = useParams();
	const slug = params.slug ?? "";

	const [isNotFound, setIsNotFound] = useState(false);

	const getCourseBySlug = useCallback(async () => {
		try {
			const response = await callApi({
				method: "GET",
				url: `${apis.PREVIEW_COURSE_BY_SLUG}/${slug}`,
			});

			if (!response?.course) {
				return;
			}

			PreviewCourseService.saveCourse(response.course);
		} catch (error) {
			if (error?.response?.status === 404) {
				setIsNotFound(true);
			}
		}
	}, [slug]);

	useEffect(() => {
		getCourseBySlug();
	}, [getCourseBySlug]);

	useEffect(() => {
		return () => {
			PreviewCourseService.clearData();
		};
	}, []);

	return { isNotFound };
}

export function usePreviewCourse() {
	const [course, setCourse] = useState(PreviewCourseService.savedCourse);

	useEffect(() => {
		const updateCourse = () => {
			setCourse(PreviewCourseService.savedCourse);
		};

		PreviewCourseService.addEventListener(updateCourse);

		return () => {
			PreviewCourseService.removeEventListener(updateCourse);
		};
	}, []);

	return { course };
}
