import React from "react";

import { Link, useNavigate } from "react-router-dom";

import { apis, callApi } from "../../../api/index";
import { roles } from "../../../constants";
import { AccountService, useAccount } from "../../../hooks/useAccount";
import { StripeService, useStripe } from "../../../hooks/useStripe";

const MenuProfile = ({ active }) => {
	const { account } = useAccount();
	const { stripeData } = useStripe();

	const navigate = useNavigate();

	const initStripe = async () => {
		try {
			const stripeResponse = await callApi({
				method: "POST",
				url: apis.LINK_ACCOUNT,
				body: {
					userCode: account.userId,
				},
			});

			StripeService.saveStripeData(stripeResponse.account);
		} catch (error) {}
	};

	const updateUserRole = async (changedData) => {
		try {
			if (!account) {
				return;
			}

			const response = await callApi({
				method: "PUT",
				url: apis.UPDATE_USER_ROLE,
				body: changedData,
			});

			if (!response) {
				return;
			}

			if (changedData.role === roles.INSTRUCTOR && account.requiredKYC) {
				if (!stripeData || stripeData.status !== "ACTIVE") {
					await initStripe();
					AccountService.saveAccount({...account, ...changedData});
					navigate("/stripe", {
						state: {
							nextRoute: "/what-can-i-do",
						},
					});
				} else {
					AccountService.saveAccount({...account, ...changedData});
					localStorage.setItem("nextRoute", "/what-can-i-do");
					navigate("/stripe/success");
				}
			} else {
				AccountService.saveAccount({...account, ...changedData});
				navigate("/what-can-i-do");
			}
		} catch (error) {}
	};

	const logOut = () => {
		AccountService.signOut();
		navigate("/");
	};

	const switchRole = () => {
		const data = {
			role: account.role === roles.INSTRUCTOR ? "USER" : "TEACHER",
		};
		updateUserRole(data);
	};

	if (!account) {
		return null;
	}

	return (
		<>
			<div className="box-name-profile-student-pc">
				<p className="hi-there">Hi There!</p>
				<p className="full-name-person">{account.firstName} {account.lastName}</p>
			</div>
			<div className="options-box-account-info-student">
			<ul className="list-group">
  				<li className="list-group-item">
						<Link
							className={`btn btn-link ${
							active === "Profile"
							? " account-info-page-active"
							: " account-info-page-gray-color"
							}`}
							to={"/profile"}
							>
							Account
						</Link>
					</li>
					<li className="list-group-item">
						<Link
							className={`btn btn-link ${
							active === "Documents"
							? " account-info-page-active"
							: " account-info-page-gray-color"
							}`}
							to={"/documents"}
						>
						Submit Documents
					</Link>
					</li>
					{account.role === roles.INSTRUCTOR && (
						<li className="list-group-item">
							<Link
									className={`btn btn-link ${
									active === "Finances"
									? " account-info-page-active"
									: " account-info-page-gray-color"
									}`}
									to="/finances"
								>
								Finances
							</Link>
						</li>
					)}
					<li className="list-group-item">
						<Link
							className={`btn btn-link ${
								active === "Safety"
								? " account-info-page-active"
								: " account-info-page-gray-color"
								}`}
								to={"/safety"}
								>
							Safety
						</Link>
					</li>
					

				{account.role === roles.INSTRUCTOR && (
					<li className="list-group-item">
						<Link
							className={`btn btn-link ${
								active === "Reviews"
								? " account-info-page-active"
								: " account-info-page-gray-color"
								}`}
								to="/reviews"
							>
							Reviews
						</Link>
					</li>
				)}
				<li className="list-group-item">
					<button
							type="button"
							onClick={switchRole}
							className="btn btn-link account-info-page-gray-color" >
							Switch to{" "}
							{account.role === roles.INSTRUCTOR ? "Student" : "Instructor"}
					</button>
				</li>
				<li className="list-group-item">
					<button
						type="button"
						onClick={logOut}
						className="btn btn-link btn-logout Underline Bold account-info-page-gray-color">
						Sign out
					</button>
				</li>
				</ul>
			</div>
		</>
	);
};

export default MenuProfile;
