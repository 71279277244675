import { useEffect, useState } from "react";

import { IconButton, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoReload } from "react-icons/io5";
import { toast } from "react-toastify";

import { apis, callApi } from "../../../../../../api";
import { CourseItem } from "../CourseItem/CourseItem";

import "./courses.scss";

export default function ViewClasses() {
	const [courses, setCourses] = useState([]);
	const navigate = useNavigate();

	const onClickGoToCreateCourse = () => {
		navigate("/my-courses/new");
	};

	const getCourses = async (showSuccessToast = false) => {
		try {
			const response = await callApi({
				method: "GET",
				url: apis.MY_COURSES,
			});
	
			if (response?.courses) {
				setCourses(response.courses);
				if (showSuccessToast) {
					toast.success("Your courses are up-to-date. Make learning awesome!");
				}
			}
		} catch (error) {}
	};

	useEffect(() => {
		getCourses();
	}, []);

	const onClickRefreshCourse = () => {
		getCourses(true);
	};

	return (
		<div className="my-orka-courses-container">
			<div className="title-container">
				<Typography component="h5" className="title">
					Current Courses
				</Typography>
				<Tooltip title="Refresh" onClick={onClickRefreshCourse}>
					<IconButton className="reload-icon">
						<IoReload className="normal-icon" />
					</IconButton>
				</Tooltip>
			</div>

			{courses.map((course) => (
				<CourseItem course={course} key={course.id} />
			))}

			<div className="create-button" onClick={onClickGoToCreateCourse}>
				Create Course
			</div>
		</div>
	);
}
