import { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import { useDropzone } from "react-dropzone";
import { FaCircleCheck, FaFile } from "react-icons/fa6";
import { GoAlert } from "react-icons/go";

import { apis, callApi } from "../../../api";
import { documentType } from "../../../constants";
import { useAccount } from "../../../hooks/useAccount";
import MenuProfile from "../Accont/MenuProfile";

const TemplateShowFileUploaded = ({ handleDeleteFile, nameFile }) => {
	return (
		<div className="containerGeneralFileUploaded">
			<div className="containerFileUploaded">
				<FaFile
					className="faFile_icon_fontAwesome"
					style={{ color: "#6B8C95" }}
				/>
				<span className="nameFile">{nameFile}</span>
				<span className="optionDelete" onClick={handleDeleteFile}>
					Delete
				</span>
			</div>
		</div>
	);
};

const ContentSubmitDocumentStudent = () => {
	const [pilotCertificateDocuments, setPilotCertificateDocuments] = useState(
		[]
	);
	const [idCardDocuments, setIdCardDocuments] = useState([]);
	const [emergencyContactDocuments, setEmergencyContactDocuments] = useState(
		[]
	);
	const isPilotCertificateDocumentsMissing =
		pilotCertificateDocuments.length === 0;
	const isIdCardDocumentsMissing = idCardDocuments.length === 0;
	const isEmergencyContactDocumentsMissing =
		emergencyContactDocuments.length === 0;

	const { account } = useAccount();

	const [showModal, setShowModal] = useState({
		show: false,
		documentType: "",
	});

	const [showModalImages, setShowModalImages] = useState({
		show: false,
		documentType: "",
	});

	const getAllMyDocuments = async () => {
		try {
			const response = await callApi({
				method: "GET",
				url: apis.GET_ALL_MY_DOCUMENTS,
			});
			if (!response?.documents) {
				return;
			}

			const myPilotCertificateDocuments = [];
			const myIdCardDocuments = [];
			const myEmergencyContactDocuments = [];
			for (let i = 0; i < response.documents.length; i++) {
				if (
					response.documents[i].documentType === documentType.PILOT_CERTIFICATE
				) {
					myPilotCertificateDocuments.push(response.documents[i]);
				} else if (
					response.documents[i].documentType === documentType.ID_CARD
				) {
					myIdCardDocuments.push(response.documents[i]);
				} else if (
					response.documents[i].documentType === documentType.EMERGENCY_CONTACT
				) {
					myEmergencyContactDocuments.push(response.documents[i]);
				}
			}
			setPilotCertificateDocuments(myPilotCertificateDocuments);
			setIdCardDocuments(myIdCardDocuments);
			setEmergencyContactDocuments(myEmergencyContactDocuments);
		} catch (error) {}
	};

	const uploadFile = async (acceptedFile, selectedDocumentType) => {
		if (!account) {
			return;
		}

		try {
			const file = acceptedFile[0];

			const getSignedURLResponse = await callApi({
				method: "POST",
				url: apis.GET_SIGNED_URL,
				body: {
					fileName: file.name,
					docType: "docs",
					contentType: file.type,
					userId: account.userId,
				},
			});

			if (!getSignedURLResponse) {
				return;
			}

			const { signedURL, location } = getSignedURLResponse;

			await callApi({
				method: "PUT",
				baseURL: "",
				url: signedURL,
				body: file,
			});

			const saveNewDocumentResponse = await callApi({
				method: "POST",
				url: apis.CREATE_MY_DOCUMENT,
				body: {
					fileName: file.name,
					contentType: file.type,
					location,
					documentType: selectedDocumentType,
				},
			});

			if (!saveNewDocumentResponse) {
				return;
			}

			if (selectedDocumentType === documentType.PILOT_CERTIFICATE) {
				setPilotCertificateDocuments((prev) => [
					...prev,
					saveNewDocumentResponse.newDocument,
				]);
			} else if (selectedDocumentType === documentType.ID_CARD) {
				setIdCardDocuments((prev) => [
					...prev,
					saveNewDocumentResponse.newDocument,
				]);
			} else if (selectedDocumentType === documentType.EMERGENCY_CONTACT) {
				setEmergencyContactDocuments((prev) => [
					...prev,
					saveNewDocumentResponse.newDocument,
				]);
			}
		} catch (error) {}
	};

	const handleImageChange = async (acceptedFile) => {
		await uploadFile(acceptedFile, showModalImages.documentType);
		setShowModalImages((prev) => ({ ...prev, show: false, documentType: "" }));
	};

	const handleFileChange = async (acceptedFile) => {
		await uploadFile(acceptedFile, showModal.documentType);
		setShowModal((prev) => ({ ...prev, show: false, documentType: "" }));
	};

	const handleDeleteFile = async (id, selectedDocumentType) => {
		try {
			await callApi({
				method: "DELETE",
				url: `${apis.DELETE_MY_DOCUMENT}/${id}`,
			});

			if (selectedDocumentType === documentType.PILOT_CERTIFICATE) {
				setPilotCertificateDocuments((prev) =>
					prev.filter((item) => item.id !== id)
				);
			} else if (selectedDocumentType === documentType.ID_CARD) {
				setIdCardDocuments((prev) => prev.filter((item) => item.id !== id));
			} else if (selectedDocumentType === documentType.EMERGENCY_CONTACT) {
				setEmergencyContactDocuments((prev) =>
					prev.filter((item) => item.id !== id)
				);
			}
		} catch (error) {}
	};

	const dropzoneFile = useDropzone({
		noClick: true,
		noKeyboard: true,
		maxFiles: 1,
		accept: {
			"application/pdf": [],
		},
		onDropAccepted: handleFileChange,
	});

	const dropZoneImage = useDropzone({
		noClick: true,
		noKeyboard: true,
		maxFiles: 1,
		accept: {
			"image/*": [],
		},
		onDropAccepted: handleImageChange,
	});

	/**
	 * Return missing document UI if document is missing
	 * Else return check icon
	 * @param {boolean} isDocumentMissing
	 */
	const renderDocumentStatus = (isDocumentMissing) => {
		if (isDocumentMissing) {
			return (
				<div className="box-general-document-missing">
					<GoAlert />
					<div className="box-text-document-missing">
						<p id="WarningTxtPaddingAndColor">Document Missing</p>
					</div>
				</div>
			);
		}

		return (
			<div className="box-general-document-missing">
				<div className="box-image-warning">
					<FaCircleCheck
						className="faCheck_icon_fontAwesome"
						style={{ color: "#6B8C95" }}
					/>
				</div>
			</div>
		);
	};

	useEffect(() => {
		getAllMyDocuments();
	}, []);

	return (
		<>
			<Modal
				show={showModal.show}
				onHide={() => {
					setShowModal({
						show: false,
					});
				}}
				dialogClassName="Width90p modal-upload-files-dialog"
				contentClassName="Height75vh modal-upload-files"
				centered={true}
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Header>
					<Modal.Title
						id="example-custom-modal-styling-title"
						style={{
							fontFamily: "Roboto, sans-serif!important",
							fontWeight: "bold!important",
						}}
					>
						Upload files
					</Modal.Title>
					<i
						className="fa-solid fa-xmark me-3 FontSize30 YellowIcon Hover"
						onClick={() => setShowModal({ show: false })}
					></i>
				</Modal.Header>
				<Modal.Body
					{...dropzoneFile.getRootProps({
						className: "dropzone d-flex justify-content-evenly flex-column",
					})}
				>
					<input
						style={{ display: "none" }}
						type="file"
						onChange={handleFileChange}
						accept={".pdf"}
						{...dropzoneFile.getInputProps()}
					/>
					<div className="align-self-center BackgroundGrayRegister rounded-circle">
						<i className="fa-solid fa-upload LightGray m-5 FontSize60" />
					</div>
					<div className="align-self-center">
						<div className="mb-4">
							<p
								style={{
									color: "#6B8C95",
									fontFamily: "Roboto, sans-serif!important",
									fontWeight: "600",
								}}
							>
								Drag and drop files to upload
							</p>
						</div>
						<div className="align-self-center d-flex justify-content-center">
							<button
								className="btn btn-primary btnSelectFile"
								onClick={dropzoneFile.open}
							>
								<span className="Green FontWeight600 FontSize115p">
									Select files
								</span>
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{/* modal de subir imagenes */}

			<Modal
				show={showModalImages.show}
				onHide={() => {
					setShowModalImages({
						show: false,
					});
				}}
				dialogClassName="Width90p modal-upload-images-dialog"
				contentClassName="Height75vh modal-upload-files"
				centered={true}
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Header>
					<Modal.Title
						id="example-custom-modal-styling-title"
						style={{
							fontFamily: "Roboto, sans-serif!important",
							fontWeight: "bold!important",
						}}
					>
						Upload image
					</Modal.Title>
					<i
						className="fa-solid fa-xmark me-3 FontSize30 YellowIcon Hover"
						onClick={() => setShowModalImages({ show: false })}
					></i>
				</Modal.Header>
				<Modal.Body
					{...dropZoneImage.getRootProps({
						className: "dropzone d-flex justify-content-evenly flex-column",
					})}
				>
					<input
						style={{ display: "none" }}
						type="file"
						onChange={handleImageChange}
						accept=".jpg"
						{...dropZoneImage.getInputProps()}
					/>
					<div className="align-self-center BackgroundGrayRegister rounded-circle">
						<i className="fa-solid fa-upload LightGray m-5 FontSize60" />
					</div>
					<div className="align-self-center">
						<div className="mb-4">
							<p
								style={{
									color: "#6B8C95",
									fontFamily: "Roboto, sans-serif!important",
									fontWeight: "600",
								}}
							>
								Drag and drop image to upload
							</p>
						</div>
						<div className="align-self-center d-flex justify-content-center">
							<button
								className="btn btn-secondary btnSelectFile"
								onClick={dropZoneImage.open}
							>
								<span className="Green FontWeight600 FontSize115p">
									Select files
								</span>
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{/* CONTENIDO */}

			<div
				className="container container-general-submit-document-student mb-5"
				id="GrisCalis"
			>
				<div className="row justify-content-md-between p-5">
					<div className="col-md-4 mb-4">
						<MenuProfile active="Documents" />
					</div>

					<div className="col-md-8 container-personal-documents">
						<div className="row">
							<h4 className="titles-sections-info">Submit Documents</h4>
							<p className="text-submit-documents">
								Here you can upload and update any documents needed
							</p>
						</div>

						<div className="box-element-span-document-missing-warning">
							<h5 className="header-submit-documents">Pilot Certificate</h5>
							{renderDocumentStatus(isPilotCertificateDocumentsMissing)}
						</div>

						{pilotCertificateDocuments.map(({ id, documentType, name }) => {
							return (
								<TemplateShowFileUploaded
									handleDeleteFile={() => handleDeleteFile(id, documentType)}
									nameFile={name}
									key={id}
								/>
							);
						})}

						<div className="box-buttons-upload-photo">
							<div
								className="btn btn-light box-button-upload box-inside-button-upload-photo"
								id=""
								onClick={() => {
									setShowModal({
										show: true,
										documentType: documentType.PILOT_CERTIFICATE,
									});
								}}
							>
								<i
									className="fa-solid fa-arrow-up-from-bracket"
									id="IconPadding"
								></i>
								<span>Upload a file</span>
							</div>
						</div>

						<div className="box-element-span-document-missing-warning">
							<h5 className="header-submit-documents">Government I.D.</h5>
							{renderDocumentStatus(isIdCardDocumentsMissing)}
						</div>

						{idCardDocuments?.map(({ id, documentType, name }) => {
							return (
								<TemplateShowFileUploaded
									handleDeleteFile={() => handleDeleteFile(id, documentType)}
									nameFile={name}
									key={id}
								/>
							);
						})}

						<div className="box-buttons-upload-photo">
							<div
								className="btn btn-light box-button-upload box-inside-button-upload-photo"
								onClick={() => {
									setShowModal({
										show: true,
										documentType: documentType.ID_CARD,
									});
								}}
							>
								<i
									className="fa-solid fa-arrow-up-from-bracket"
									id="IconPadding"
								></i>
								<span>Upload a file</span>
							</div>
						</div>

						<div className="box-element-span-document-missing-warning">
							<h5 className="header-submit-documents header-goverment">
								Emergency Contact
							</h5>
							{renderDocumentStatus(isEmergencyContactDocumentsMissing)}
						</div>

						{emergencyContactDocuments?.map(({ id, documentType, name }) => {
							return (
								<TemplateShowFileUploaded
									handleDeleteFile={() => handleDeleteFile(id, documentType)}
									nameFile={name}
									key={id}
								/>
							);
						})}

						<div className="box-buttons-upload-photo">
							<div
								className="btn btn-light box-button-upload box-inside-button-upload-photo"
								onClick={() => {
									setShowModal({
										show: true,
										documentType: documentType.EMERGENCY_CONTACT,
									});
								}}
							>
								<i
									className="fa-solid fa-arrow-up-from-bracket"
									id="IconPadding"
								></i>
								<span>Upload a file</span>
							</div>
						
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContentSubmitDocumentStudent;
