import React from "react";

import { Navigate } from "react-router-dom";

import { useAccount } from "../../../hooks/useAccount";

export default function RoleBasedGuard({ accessibleRoles, children }) {
	const { account } = useAccount();

	if (!accessibleRoles.includes(account?.role)) {
		return <Navigate to="/" />;
	}

	return <>{children}</>;
}
