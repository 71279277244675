import React, { useEffect, useState } from "react";

import { IconButton, Tooltip, Typography } from "@mui/material";
import { IoReload } from "react-icons/io5";
import { toast } from "react-toastify";

import { apis, callApi } from "../../../../../../api";

import "./course_students.scss";
import { StudentProgressItem } from "./StudentProgressItem";
import {
	InstructorSummariesService,
	useInstructorSummaries,
} from "../../../../../../hooks/useInstructorSummaries";
import { StudentProgressDetail } from "./StudentProgressDetail";

export function CourseStudents() {
	const { summaries } = useInstructorSummaries();
	const [selectedSummary, setSelectedSummary] = useState(null);

	const getSummaries = async (showSuccessToast = false) => {
		try {
			const response = await callApi({
				method: "GET",
				url: apis.SUMMARY_BY_TEACHER_TOKEN,
			});

			if (!response?.summaries) {
				return;
			}

			InstructorSummariesService.saveSummaries(response.summaries);
			if (showSuccessToast) {
				toast.success(
					"Your students progress are up-to-date. Make learning awesome!"
				);
			}
		} catch (error) {}
	};

	useEffect(() => {
		getSummaries();
	}, []);

	const onClickRefreshSummaries = () => {
		getSummaries(true);
	};

	const viewDetail = (summary) => {
		setSelectedSummary(summary);
	};

	const clearSelectSummary = () => {
		setSelectedSummary(null);
	};

	if (selectedSummary) {
		return (
			<StudentProgressDetail
				studentProgress={selectedSummary}
				clearSelect={clearSelectSummary}
			/>
		);
	}

	return (
		<div className="my-orka-course-students-container">
			<div className="title-container">
				<Typography component="h5" className="title">
					Current Students
				</Typography>
				<Tooltip title="Refresh" onClick={onClickRefreshSummaries}>
					<IconButton className="reload-icon">
						<IoReload className="normal-icon" />
					</IconButton>
				</Tooltip>
			</div>

			{summaries.map((summary) => (
				<StudentProgressItem
					studentProgress={summary}
					viewDetail={viewDetail}
					key={summary.id}
				/>
			))}
		</div>
	);
}
