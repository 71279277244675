import React, { useState } from "react";

import Navbar from "../navegacion/Navbar";
import AboutCarousel from "./AboutCarousel";
import QuestionItem from "./QuestionItem";

const commentsCourse = [
	{
		comment:
			"I have recently had the pleasure to review several of the on-line courses available through ORKA. These videos contain an amazing amount of aeronautical information and techniques that I believe are useful for pilots at all levels of qualification.",
		user: "Wallace Moran, Retired DPE, NAFI Flight Instructor Hall of Fame",
		course: "Knowledge Base Academics",
	},
	{
		comment:
		"ORKA has developed an online course for students, pilots, and anyone interested in aeronautical sciences. I had the opportunity to learn from this course. I was impressed with the materials on flight physics, performance, weather, airspace, and navigation. This course not only covers the traditional ground school, but it also explains more advanced topics on safety and recovering from adverse flight conditions. I would recommend this course to every person interested in becoming a pilot.",
		user: "Dr. Jack Tseng, Airplane Designer, Staff Scientist",
		course: "Knowledge Base Academics"
	},
	{
		comment: "Had such a great time earning my Tailwheel Endorsement in South Carolina’s Low Country. Ridgeland Airport is a treasure and the 1946 Luscombe is a fun challenge. Instructor is knowledgeable, patient, and is truly passionate about the art of tailwheel flying. Would recommend earning your endorsement here for great scenery, top instruction, and a memorable aviation experience.",
		user: "Austen Jarboe, Flight Instructor, Pilot",
		course: "Tailwheel Endorsement"
	},
	{
		comment: "Great instruction! Having fun learning to fly!",
		user: "Stacy O'Quinn, DVM",
		course: "Private Pilot Certification Course, Tailwheel Endorsement"
	},
	{
		comment:
		"Began flight training as part of a lifelong dream to do so. Instructor was very fast to respond to inquiries and laid out a very thoroughly planned and structured path to obtaining my private pilot license. A combination of online academics, one on one briefings, simulator, and flying was used to cover everything from the introduction to the airplane, to every aspect of flight. Scheduling was very flexible to my needs, making flying while working full time easy. Instructor’s style focuses on technical precision and performing each maneuver to strict standards to ensure that each skill forms a solid foundation to build upon. The Cessna 172 we trained in was in fantastic condition, with a very modern avionics suite and an extra powerful engine vs the factory original. Overall, this was a wonderful experience and I look forward to continuing training.",
		user: "Jake Brodersen, Airframe & Powerplant Mechanic",
		course: "Private Pilot Certification Course"
	},
	{
		comment:
		"Extremely pleased to have selected ORKA for flight training. Instructor is incredibly effective, with a lot of flight experience and aircraft knowledge. Instructor’s thorough communication and ample patience make learning on the ground and in the air feel natural. Excited to continuing training, would highly recommend ORKA to anyone looking to pursue their pilot’s license.",
		user: "Bridget Ollenburg, Engineer",
		course: "Private Pilot Certification Course"
	},
	{
		comment: "Always a pleasure to work with ORKA. The instructor is exceptional with every detail needed for flight training. ORKA is highly relevant for anyone interested in flying, I would highly recommend it.",
		user: "Nii Nartey, Engineer",
		course: "Private Pilot Certification Course"
	},
	{
		comment: "Excellent Flight Instruction from a passionate flyer. Great fixed price multiday courses. Did my tailwheel endorsement. Highly recommended.",
		user: "Boris Kubrak, Engineer",
		course: "Tailwheel Endorsement"
	}
];

const questions = [
	{
		question: "How airplanes fly?",
		body: "When you have sufficient flow of air around a wing, standard airfoil design accelerates such flow substantially. As fluid accelerates, pressure changes. If the pressure differential between the upper and lower wing surfaces is enough, we say we have a lifting force. Flyers manipulate this lifting force to maneuver with the airplane.",
	},
	{
		question: "Is flying safe?",
		body: "Flying is risky, but it doesn’t have to be. You can mitigate risks by having awareness, the right attitude, and competence. Having awareness, the right attitude, and competence takes effort. If you are considering to get into flying, you must be willing to invest a significant amount of time and effort to reduce the risks. If you just want to belong to a flying club and forget about aviation for weeks, flying is probably not healthy for you. ",
	},
	{
		question: "What is the most fun plane to fly? ",
		body: "It depends on your style. Slow airplanes allow you to practice energy management and are great for scenic flights. Acro airplanes are a delight for those who love movement. Gliders are always a challenge to those who have analytical minds and the spirit of an artist. Find out which you like best!",
	},
	{
		question: "Where can you fly over scenic terrain?",
		body: "Some courses at ORKA are hosted in sites with amazing views. You may want to explore experiences to find the scenic views that you are looking for. You may also discover towns or cities you’ve always wanted to visit.",
	},
	{
		question: "How to master the crosswind takeoff & landing?",
		body: "We recommend a buildup approach. Start with 5 knots, then 7 knots, stay at 10 knots for some time, and finally challenge yourself to the limit specified in your airplane flight manual. During any ground roll, have your wheels roll straight along your direction of motion, most likely the runway centerline. When using a “sideslip”, you must find and hold the bank angle that balances the crosswind. If using a wind correction angle, you must find and hold the angle that balances the crosswind.",
	},
	{
		question: "How to land a taildragger?",
		body: "Landing a tailwheel airplane requires the same principles to land any airplane. Some airplanes are unforgiving to sloppy flying. To land a tailwheel airplane master precise speed, pitch, bank, and yaw control, stabilized approaches, and commit to land with the longitudinal axis straight along your direction of motion.",
	},

	{
		question: "How to make spot landings?",
		body: "Identify the spot where you want to touch down. Select an appropriate speed to fly a stabilized approach. Select an aiming point located prior to the intended touch down point. The distance between the aiming point and the touch down is equal to the amount of floating experienced during the roundout and flare. As you master energy management in the airplane you fly, you will gain the sensitivity to select and adjust your aiming point. As competitive as you may be, always prioritize a safe landing. The probability of touching down on the spot is gained during the approach, not the round-out.",
	},
	{
		question: "How to become a safer and better pilot?",
		body: "Keep learning, investing in your skill, knowledge, and seeking the finest aviation relationships.",
	},
	{
		question: "Where should I go for spin recovery training?",
		body: "We recommend you find an aerobatic CFI, with an aerobatic airplane, in good condition, specializing in spin training. Subject matter experts have specialty aviation courses.",
	},
	{
		question: "How do I create a course?",
		body: "Open an ORKA account and register as an Instructor (click SIGN IN, then Register here, and select Instructor). You can create online or onsite aviation courses via your instructor portal. Contact us if you need help.",
	},
	{
		question: "Is my instructor an expert?",
		body: "We have the goal to ensure that your instructor is always a specialist, and show that the finest aviation education can come from independent flight instructors. Because of that, we work diligently to verify the character, knowledge, skill, and quality of those teaching through ORKA.",
	},
	{
		question: "Who is ORKA?",
		body: "We are a network of skilled, knowledgeable, humble, caring, and available individuals. And We are connected with individuals who share these values. ORKA brings together educators, coaches, flyers, engineers, competition pilots, and recreational pilots who want to help you in achieving your maximum potential.",
	},
	{
		question: "What we do?",
		body: "ORKA is focused on cultivating and integrating knowledge and skills strong on fundamentals, so you can respond quickly, appropriately, and safely. Like the flight test of an airplane, we follow a disciplined approach to expand your ability to fly an aircraft to its full capabilities.",
	},
	{
		question: "What does ORKA mean?",
		body: "ORKA is a Swedish word that means to have the energy and strength to do something. We say we have ORKA when we have vocation, passion, and drive. ",
	},
	{
		question:
			"Why price should never be the first consideration in enrolling a flight program? ",
		body: "Your participation should be instrumental to your growth as a flyer and as an individual. The quality, safety, and impact of your training must be high. When learning through ORKA, you are also building and keeping healthy relationships with a fine aviation community, perhaps the biggest jewel for us.",
	},
	{
		question: "What qualities make a great student? ",
		body: "Mainly an open mind and energy to study. Other great qualities are humility, positive determination, self-control, respect, responsibility, honesty and coordination. You don’t need to have all of these, but an open mind and the energy to learn are nonnegotiable.",
	},
	{
		question: "What qualities make a great teacher? ",
		body: "An individual who is successful at what they do (their craft), respectful, and great with people are signs of a good teacher.",
	},
	{
		question: "Why do we make background checks on a regular basis?",
		body: "People in authority are not always really as they present themselves, they are predators. We know cases of instructors who physically, mentally, emotionally, & even sexually abuse their students. At ORKA we have zero tolerance for such behavior. We want to create a safe and healthy training environment, especially for women.",
	},
	{
		question: "Are ORKA courses recognized as professional courses?",
		body: "Courses at ORKA will grant you training that may allow you to get endorsements and apply for certification recognized by aeronautical authorities. Paperwork and any exams required may not be included in your course. Make sure you read the course description, or contact us to learn more about your course before enrolling.",
	},
]

function About() {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  return (
    <>
      <Navbar />

      <div className="about-carousel-container">
        <AboutCarousel data={commentsCourse} />
      </div>
      <div className="common-questions-container">
        <h4 className="common-questions-title">COMMON QUESTIONS</h4>
      </div>
      {/* {questionSelected} */}
      <div className="questions-container">
        {questions.map((question, index) => (
          <QuestionItem
            data={question}
            index={index}
            key={`${index}`}
            selected={selectedQuestion === index}
			setSelectedQuestion={setSelectedQuestion}
          />
        ))}
      </div>
    </>
  );
}

export default About;
