import moment from "moment";

export function formatTimeRange(startTime, endTime) {
	const startDate = moment(startTime);
	const endDate = moment(endTime);
	let dateText = "";
	if (startDate.format("YYYY-MM-DD") === endDate.format("YYYY-MM-DD")) {
		dateText = startDate.format("DD MMM YYYY");
	} else if (startDate.format("YYYY-MM") === endDate.format("YYYY-MM")) {
		dateText = `${startDate.format("DD")} - ${endDate.format("DD MMM YYYY")}`;
	} else if (startDate.format("YYYY") === endDate.format("YYYY")) {
		dateText = `${startDate.format("DD MMM")} - ${endDate.format(
			"DD MMM YYYY"
		)}`;
	} else {
		dateText = `${startDate.format("DD MMM YYYY")} - ${endDate.format(
			"DD MMM YYYY"
		)}`;
	}

	const timeText = `${startDate.format("HH:mm")} - ${endDate.format("HH:mm")}`;

	return { dateText, timeText };
}
