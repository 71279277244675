import React from "react";

import { roles } from "../../../constants";
import { useAccount } from "../../../hooks/useAccount";
import SubmitDocumentInstructor from "./SubmitDocumentInstructor";
import SubmitDocumentStudent from "./SubmitDocumentStudent";

export default function Documents() {
	const { account } = useAccount();

	if (!account) {
		return null;
	}

	if (account.role === roles.STUDENT) {
		return <SubmitDocumentStudent />;
	}

	if (account.role === roles.INSTRUCTOR) {
		return <SubmitDocumentInstructor />;
	}
}
