import classNames from "classnames";

const viewNames = [
	{ key: "Day", displayText: "Day", value: "Day" },
	{ key: "Week", displayText: "Week", value: "Week" },
	{ key: "Month", displayText: "Month", value: "Month" },
];

function ExternalViewSwitcherItem({ item, isSelected, setCurrentViewName }) {
	const onClickSetCurrentViewName = () => {
		setCurrentViewName(item.value);
	};
	return (
		<p
			className={classNames("select-calendar-type-option", {
				bold: isSelected,
			})}
			onClick={onClickSetCurrentViewName}
		>
			{item.displayText}
		</p>
	);
}

export default function ExternalViewSwitcher({
	currentViewName,
	setCurrentViewName,
}) {
	return (
		<div className="d-flex">
			{viewNames.map((item) => (
				<ExternalViewSwitcherItem
					key={item.key}
					item={item}
					isSelected={currentViewName === item.value}
					setCurrentViewName={setCurrentViewName}
				/>
			))}
		</div>
	);
}
