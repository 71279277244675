import { useState } from "react";

import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { IoClose } from "react-icons/io5";

import { useMyCourseDocumentsInstructor } from "../../hooks/useMyCourseInstructor";

export function CourseDocumentImage({ file }) {
	const [isLoading, setIsLoading] = useState(true);
	const { removeFile } = useMyCourseDocumentsInstructor();

	const onLoad = () => {
		setIsLoading(false);
	};

	const onClickRemoveFile = (e) => {
		e.stopPropagation();
		removeFile(file.id);
	};

	return (
		<div className="media-container">
			<img src={file.url} alt={file.name} className="media" onLoad={onLoad} />
			{isLoading && (
				<Box sx={{ position: "absolute", display: "inline-flex" }}>
					<CircularProgress />
				</Box>
			)}
			<Tooltip title="Remove">
				<IconButton className="remove-button" onClick={onClickRemoveFile}>
					<IoClose />
				</IconButton>
			</Tooltip>
		</div>
	);
}
