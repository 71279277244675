export const validators = {
	email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
	phoneNumber: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/,
	password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d#$@!%&*?\s]{10,}$/,
	uuidV4: /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
	nonEmptyString: /^(?!\s+$).+/,
	time: /^\d+\.{0,1}(\d{0,1}[05]{0,1})$/,
	price: /^\d+$/,
};

export const rules = {
	firstName: {
		min: {
			value: 1,
			message: 'First name has from 1 to 50 characters.',
		},
		max: {
			value: 50,
			message: 'First name has from 1 to 50 characters.',
		},
	},
	lastName: {
		min: {
			value: 1,
			message: 'Last name has from 1 to 50 characters.',
		},
		max: {
			value: 50,
			message: 'Last name has from 1 to 50 characters.',
		},
	},
	middleName: {
		min: {
			value: 1,
			message: 'Middle name has from 1 to 50 characters.',
		},
		max: {
			value: 50,
			message: 'Middle name has from 1 to 50 characters.',
		},
	},
	nickname: {
		min: {
			value: 1,
			message: 'Nickname has from 1 to 50 characters.',
		},
		max: {
			value: 50,
			message: 'Nickname has from 1 to 50 characters.',
		},
	},
	email: {
		pattern: {
			value: validators.email,
			message: 'Email is invalid.',
		},
	},
	phoneNumber: {
		pattern: {
			value: validators.phoneNumber,
			message: 'Phone number is invalid.',
		},
	},
	password: {
		pattern: {
			value: validators.password,
			message: 'Password must have at least 10 characters, 1 uppercase letter, 1 lowercase letter and 1 number.'
		},
	},
}
