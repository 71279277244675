export function omitEmpty(param) {
	if (!param) {
		return param;
	}

	if (typeof param === 'string') {
		return param.trim();
	}

	if (typeof param !== 'object') {
		return param;
	}

	// array
	if (Array.isArray(param)) {
		for (let i = 0; i < param.length; i++) {
			param[i] = omitEmpty(param[i]);
		}
		return param;
	}

	// object
	const keys = Object.keys(param);

	for (let i = 0; i < keys.length; i++) {
		const value = param[keys[i]];

		if (value === undefined || value === null || Number.isNaN(value)) {
			delete param[keys[i]];
			continue;
		}

		if (typeof value === 'string') {
			if (value.trim().length === 0) {
				delete param[keys[i]];
				continue;
			}

			param[keys[i]] = value.trim();
			continue;
		}

		if (Array.isArray(value)) {
			for (let j = 0; j < value.length; j++) {
				param[keys[i]][j] = omitEmpty(value[j]);
			}
			continue;
		}

		if (typeof value === 'object') {
			param[keys[i]] = omitEmpty(value);
			if (Object.keys(param[keys[i]]).length === 0) {
				delete param[keys[i]];
			}
		}
	}

	return param;
}