import { Typography } from "@mui/material";

export function CourseInfo({
	label,
	labelClassName,
	value,
	valueClassName,
}) {
	return (
		<>
			<Typography component="p" className={labelClassName}>
				{label}
			</Typography>
			<Typography component="p" className={valueClassName}>
				{value}
			</Typography>
		</>
	);
}
