import { useEffect, useState } from "react";

import { StripeService } from "./useStripe";
import { TokenService } from "./useToken";

export const AccountService = {
	savedAccount: null,
	listeners: [],

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	/**
	 * Save token to localStorage and emit changes to all listeners
	 * @param {Object} newAccount
	 */
	saveAccount(newAccount) {
		this.savedAccount = newAccount;
		AccountService.emitChanges();
	},

	signOut() {
		TokenService.clearToken();
		StripeService.clearData();
		this.savedAccount = null;
		AccountService.emitChanges();
	},

	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

export function useAccount() {
	const [account, setAccount] = useState(AccountService.savedAccount);

	useEffect(() => {
		const updateAccount = () => {
			setAccount(AccountService.savedAccount);
		};

		AccountService.addEventListener(updateAccount);

		return () => {
			AccountService.removeEventListener(updateAccount);
		};
	}, []);

	return { account };
}
