import { roles } from '../../../constants';
import { useAccount } from '../../../hooks/useAccount';
import { MyOrkaInstructor } from '../Instructor/MyOrka/MyOrkaInstructor';
import { MyOrkaStudent } from '../Student/MyOrka/MyOrkaStudent';
import MyOrkaModerator from './Moderator/MyOrkaModerator';

export function MyOrka() {
	const {account} = useAccount();

	// Just for sure, you can only access this screen if and only if you signed in
	if (!account) {
		return null;
	}

	if (account.role === roles.STUDENT) {
		return <MyOrkaStudent />;
	}

	if (account.role === roles.INSTRUCTOR) {
		return <MyOrkaInstructor />;
	}

	if (account.role === roles.MODERATOR) {
		return <MyOrkaModerator />;
	}

	// Can't happen this case but just for sure
	return null;
}