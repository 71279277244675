import { NotFound } from "../../../common/NotFound/NotFound";
import NavbarOrka from "../../navegacion/Navbar";
import { CourseBasicInformation } from "./components/CourseBasicInformation";
import { useCheckoutCourseInit } from "./hooks/useCheckoutCourse";

import "./checkout_course.scss";
import { CourseCheckoutInformation } from "./components/CourseCheckoutInformation";

export function CheckoutCourse() {
	const { isNotFound, isLoading } = useCheckoutCourseInit();

	if (isNotFound) {
		return <NotFound text="Course not found" />;
	}

	if (isLoading) {
		return null;
	}

	return (
		<div style={{ height: "100vh" }}>
			<NavbarOrka chatScreen isHiThere isInHome />
			<div className="checkout-course-container">
				<CourseBasicInformation />
				<CourseCheckoutInformation />
			</div>
		</div>
	);
}
