import { useEffect } from "react";

import moment from "moment";

export function NowRedLine({ currentViewName }) {
	useEffect(() => {
		if (currentViewName === "Month") {
			return;
		}

		const now = moment();
		const differentInMinute = now.get("hour") * 60 + now.get("minute");
		const element = document.getElementsByClassName(
			"Container-container css-ljfojm"
		);

		if (element.length === 0) {
			return;
		}

		const redLinePivot = document.createElement("div");
		redLinePivot.id = "red-line-pivot";
		redLinePivot.style = `position: absolute; z-index: 1; margin-top: calc(${
			(differentInMinute / 1440) * 1152
		}px - 100px);`;
		const redLineTextNow = document.createElement("div");
		redLineTextNow.id = "red-line-text-now";
		let redLineTextNowMarginTop = `calc(${
			(differentInMinute / 1440) * 1152
		}px - 20px)`;
		if (differentInMinute < 60) {
			redLineTextNowMarginTop = `calc(${
				(differentInMinute / 1440) * 1152
			}px + 5px)`;
		}
		redLineTextNow.style = `color: #FF0000; position: absolute; z-index: 1; left: 5px; margin-top: ${redLineTextNowMarginTop}; cursor: default; user-select: none; font-size: 12px;`;
		const textNow = document.createElement("p");
		textNow.textContent = "Now";
		redLineTextNow.appendChild(textNow);
		const redLine = document.createElement("div");
		redLine.id = "red-line";
		redLine.style = `height: 1px; width: 100%; background-color: #FF0000; position: absolute; z-index: 1; margin-top: ${
			(differentInMinute / 1440) * 1152
		}px;`;

		setTimeout(() => {
			element[0].appendChild(redLinePivot);
			element[0].appendChild(redLineTextNow);
			element[0].appendChild(redLine);
		}, 0);

		const interval = setInterval(() => {
			const now = moment();
			const differentInMinute = now.get("hour") * 60 + now.get("minute");
			redLinePivot.style = `position: absolute; z-index: 1; margin-top: calc(${
				(differentInMinute / 1440) * 1152
			}px - 100px);`;
			let redLineTextNowMarginTop = `calc(${
				(differentInMinute / 1440) * 1152
			}px - 20px)`;
			if (differentInMinute < 60) {
				redLineTextNowMarginTop = `calc(${
					(differentInMinute / 1440) * 1152
				}px + 5px)`;
			}
			redLineTextNow.style = `color: #FF0000; position: absolute; z-index: 1; left: 5px; margin-top: ${redLineTextNowMarginTop}; cursor: default; user-select: none; font-size: 12px;`;
			redLine.style = `height: 1px; width: 100%; background-color: #FF0000; position: absolute; z-index: 1; margin-top: ${
				(differentInMinute / 1440) * 1152
			}px;`;
		}, 60000);

		return () => {
			clearInterval(interval);
		};
	}, [currentViewName]);

	return null;
}
