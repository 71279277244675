import React, { useEffect, useRef } from "react";

export function VideoPlayer({ video, onFinishLesson }) {
	const isFinished = useRef(false);

	useEffect(() => {
		const videoElement = document.getElementById(`online-video-${video.id}`);

		const handleTimeUpdate = (event) => {
			if (videoElement.currentTime / videoElement.duration > 0.95) {
				if (isFinished.current === false) {
					onFinishLesson();
				}
				isFinished.current = true;
			}
		};

		videoElement.addEventListener("timeupdate", handleTimeUpdate);

		// Clean up the event listeners on component unmount
		return () => {
			videoElement.removeEventListener("timeupdate", handleTimeUpdate);
		};
	}, [onFinishLesson, video.id]);

	return (
		<video id={`online-video-${video.id}`} className="film" controls controlsList="nodownload">
			<source src={video.url} type="video/mp4" />
		</video>
	);
}
