import React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";

import { useLocalStorage } from "../../../CreateAccount/useLocalStorage";
import { reqApi } from "../../../../../api";

function HeaderItem(props) {
  const { id, firstName, lastName, reports, role, selected } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [token] = useLocalStorage("token");

  const keyId = `${id}-${role}`;
  const handleChangeAsSeen = async(url, data) => {
    await reqApi.patch(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(resp => {
      //console.log('respuesta general de as seen --> ', resp);
      if(resp.status === 200){
        console.log('+ !!!');
      }
    })

    .catch(error => {
      console.log('There was an error to change as seen ----> ', error);
    })
  }

  const handleCheckAsSeen = (userid) => {
    const userObject = {
      userid,
      role
    };

    handleChangeAsSeen('/notifications/markAsSeen', userObject);
  }
  // aqui empezaron los cambios
  return (
    <>
      <div className="d-flex w-100 align-items-center justify-content-between ">
        <div className="d-flex align-items-center">
          {reports.length !== 0 ?
            <>
              <i
                className="fa-solid fa-user moderator-item-user moderator-item-user-report"
                onClick={()=>{ setModalOpen(true); handleCheckAsSeen(id) }}
              >
              </i>
            </>
            :
            <i className="fa-solid fa-user moderator-item-user"></i>
          }

          <p className="moderator-accordion-title movil-accordion-title">
            {firstName} {lastName}
          </p>
        </div>
        <div className="modal-item-gray">
          <i
            className={`fa-solid  ${selected ? "fa-chevron-up modal-item-blue" : "fa-chevron-down "
              } font-20 px-3`}
          ></i>
        </div>
      </div>
      <Modal show={modalOpen} dialogClassName="w-60 moderator-modal-container">
            <Modal.Body className="body-modal" style={{ height: '90%' }}>
              <p className="title-modal"> Safety</p>
              <p className="subtile-modal">
                ORKA is committed to creating a safe space for everyone.
              </p>
              <p className="typeReport-modal">Report Unsafe Activity</p>

              <div className="moderator-scroll">
                {reports?.map(({ id, reportType, reportReason }) => (
                  <div className="infoContainer-modal" key={id}>
                    <p className="infoTitle-modal">{reportType}</p>
                    <p className="infoText-modal">{reportReason}</p>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-end mt-3 moderator-modal-close">
                <p
                  className="btnClose-modal"
                  // variant="secondary"
                  onClick={() => setModalOpen(false)}
                >
                  Close
                </p>
              </div>
            </Modal.Body>
          </Modal>
    </>
  );
}

export default HeaderItem;
