import React from "react";

import { roles } from "../../../constants";
import { useAccount } from "../../../hooks/useAccount";
import SafetyInstructor from "./SafetyInstructor";
import SafetyStudents from "./SafetyStudents";

export default function Safety() {
	const { account } = useAccount();

	if (!account) {
		return null;
	}

	if (account.role === roles.STUDENT) {
		return <SafetyStudents />;
	}

	if (account.role === roles.INSTRUCTOR) {
		return <SafetyInstructor />;
	}
}
