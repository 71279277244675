import { TextField } from "@mui/material";
import React from "react";

import { Controller } from "react-hook-form";

import "./common_input.scss";

export default function CommonInput({
	control,
	name,
	rules,
	placeholder,
	type = "text",
}) {
	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({ field: { value, onChange }, formState: { errors } }) => {
				const errorMessage = errors[name]?.message;

				// Show success message when value is not empty and there are no errors
				const showSuccessMessage = !!value && !errorMessage;

				let className = "";
				if (errorMessage) {
					className = "invalid";
				}

				return (
					<div className="common-input">
						<TextField
							type={type}
							className={className}
							placeholder={placeholder}
							value={value}
							onChange={onChange}
							fullWidth
						/>
						{errorMessage && (
							<div className="invalid-message">{errorMessage}</div>
						)}
						{showSuccessMessage && (
							<div className="valid-message">Looks good!</div>
						)}
					</div>
				);
			}}
		/>
	);
}
