import { Fab, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

export function CourseCheckoutButton() {
	const navigate = useNavigate();
	const { slug } = useParams();

	const onClickGoToCheckoutPage = () => {
		navigate(`/courses/${slug}/checkout`);
	};

	return (
		<Fab
			className="normal-button primary-button checkout-button"
			onClick={onClickGoToCheckoutPage}
		>
			<Typography className="normal-text">Checkout now</Typography>
		</Fab>
	);
}
