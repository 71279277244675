import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAccount } from "../../../hooks/useAccount";
import { useToken } from "../../../hooks/useToken";

export default function AuthGuard({ children }) {
	const { token } = useToken();
	const { account } = useAccount();
	const location = useLocation();

	if (!token) {
		return <Navigate to={`/sign-in?redirect=${location.pathname}`} replace />;
	}

	if (!account) {
		// TODO: I will return a loading later
		return null;
	}

	return <>{children}</>;
}
