import { useState } from "react";

import { Fab, Tooltip, Typography } from "@mui/material";
import { IoMdAdd } from "react-icons/io";

import { useMyCourseLessonsInstructor } from "../../hooks/useMyCourseInstructor";
import { CourseCreateLesson } from "./CourseCreateLesson";
import { CourseLesson } from "./CourseLesson";

export function CourseLessons() {
	const [isCreating, setIsCreating] = useState(false);

	const { lessons } = useMyCourseLessonsInstructor();

	const onClickOpenCreateLessonForm = () => {
		setIsCreating(true);
	};

	return (
		<div className="course-lessons-container">
			<Typography component="p" className="input-yellow-label">
				CLASSES*
			</Typography>
			<Typography component="p" className="update-lessons-note">
				{"NOTE: Please note that your \"Flying\" course will automatically switch to \"Grounded\" when you update any classes. You can set it to \"Flying\" again after filling in all required fields."}
			</Typography>

			{lessons.map((lesson) => {
				return (
					<CourseLesson lesson={lesson} key={lesson.id} />
				);
			})}

			{isCreating && (
				<CourseCreateLesson setIsCreating={setIsCreating} />
			)}

			{lessons.length === 0 && !isCreating && (
				<Typography className="no-lessons normal-text">
					You don't have any classes.
				</Typography>
			)}

			<Tooltip title="Create new class">
				<Fab
					color="primary"
					className="add-lesson-button"
					onClick={onClickOpenCreateLessonForm}
				>
					<IoMdAdd />
				</Fab>
			</Tooltip>
		</div>
	);
}
