import { useState } from "react";

import { Button, Checkbox, Grid, TextField, Typography } from "@mui/material";
import classNames from "classnames";
import Calendar from "react-calendar";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

import useScreenSize from "../../../../../hooks/useScreenSize";
import {
	USDFormatter,
	formatHourMinute,
	formatTimezone,
	omitEmpty,
} from "../../../../../utils";
import {
	useBookingCourse,
	useCheckoutCourse,
	useOwnedLessons,
} from "../hooks/useCheckoutCourse";
import { CourseLessonItem } from "./CourseLessonItem";
import { paymentProductType } from "../../../../../constants";
import { apis, callApi } from "../../../../../api";

const paymentMethods = Object.freeze({
	PAY_FULL_COURSE: "pay-full-course",
	PAY_AS_YOU_GO: "pay-as-you-go",
});

export function OnsiteCourseCheckout() {
	const { course } = useCheckoutCourse();
	const { ownedLessons } = useOwnedLessons();
	const { availableHours, bookings } = useBookingCourse();
	const [selectedMethod, setSelectedMethod] = useState(
		paymentMethods.PAY_FULL_COURSE
	);
	const [selectedLessons, setSelectedLessons] = useState([]);
	const { width } = useScreenSize();

	const [selectedDate, setSelectedDate] = useState(
		new Date(new Date().getTime() + 86400000)
	);
	const [selectedHour, setSelectedHour] = useState(null);

	const [couponCode, setCouponCode] = useState("");

	let lessons = [];
	if (selectedMethod === paymentMethods.PAY_FULL_COURSE) {
		lessons = ownedLessons;
	} else if (selectedMethod === paymentMethods.PAY_AS_YOU_GO) {
		lessons = selectedLessons;
	}

	const calculateTotalCost = () => {
		let totalCost = 0;

		for (let i = 0; i < lessons.length; i++) {
			totalCost += lessons[i].totalCost;
		}

		return totalCost;
	};

	const totalPrice = calculateTotalCost();

	let discount = 0;
	if (selectedMethod === paymentMethods.PAY_FULL_COURSE) {
		discount = course.discount;
	}

	const discountPrice = Math.round((totalPrice * discount)) / 100;
	const priceAfterDiscount = Math.round((totalPrice - discountPrice) * 100) / 100;
	const serviceFee = Math.round((priceAfterDiscount * 0.1 + 0.3) * 100) / 100;
	const totalCost = Math.round((priceAfterDiscount + serviceFee) * 100) / 100;

	const calculateIsCheckoutButtonDisabled = () => {
		if (lessons.length === 0) {
			return true;
		}

		if (selectedHour === null) {
			return true;
		}

		return false;
	};

	const isCheckoutButtonDisabled = calculateIsCheckoutButtonDisabled();

	const onClickSelectPayFullCourse = () => {
		setSelectedMethod(paymentMethods.PAY_FULL_COURSE);
	};

	const onClickSelectPayAsYouGo = () => {
		setSelectedMethod(paymentMethods.PAY_AS_YOU_GO);
		if (ownedLessons.length === 0) {
			return;
		}

		const nextLessonIndex = ownedLessons.findIndex(
			(lesson) => lesson.owned !== true
		);

		let nextLesson = null;
		if (nextLessonIndex > -1) {
			nextLesson = ownedLessons[nextLessonIndex];
			setSelectedLessons([nextLesson]);
		} else {
			setSelectedLessons(ownedLessons[0]);
		}
	};

	const onClickCheckout = async () => {
		const body = {};
		const currentTimezone = -new Date().getTimezoneOffset() / 60;
		const startDate = selectedDate;
		startDate.setHours(selectedHour + currentTimezone - course.timezone);
		startDate.setMinutes(0);
		startDate.setSeconds(0);
		startDate.setMilliseconds(0);

		if (selectedMethod === paymentMethods.PAY_FULL_COURSE) {
			body.productType = paymentProductType.COURSE;
			body.productCode = course.courseCode;
		} else if (selectedMethod === paymentMethods.PAY_AS_YOU_GO) {
			body.productType = paymentProductType.LESSON;
			body.productCode = lessons[0].lessonCode;
		}
		body.startDate = startDate.toISOString();
		body.totalCost = Math.round(totalCost * 100);
		body.couponCode = couponCode.trim();

		try {
			const response = await callApi({
				method: "POST",
				url: apis.CHECKOUT,
				body: omitEmpty(body),
			});

			if (!response.paymentResult) {
				return;
			}

			window.location.href = response.paymentResult.url;
		} catch (error) {}
	};

	const renderSelectLessons = () => {
		if (selectedMethod === paymentMethods.PAY_FULL_COURSE) {
			return null;
		}

		const isMobile = width <= 768;

		let spacing = 2;
		if (isMobile) {
			spacing = 1;
		}

		const nextLessonIndex = ownedLessons.findIndex(
			(lesson) => lesson.owned !== true
		);

		return (
			<>
				<Typography className="title-text">SELECT CLASSES</Typography>
				<Typography className="normal-text">
					Note: You have to learn follow the syllabus!
				</Typography>
				<Grid container spacing={spacing}>
					{ownedLessons.map((lesson, index) => {
						const checked = selectedLessons.some((l) => l.id === lesson.id);
						let disabled = true;
						if (checked) {
							disabled = false;
						}
						if (nextLessonIndex === -1 || index <= nextLessonIndex) {
							disabled = false;
						}
						// if allow select multiple uncomment below code
						// if (selectedLessons.length > 0 && index > 0) {
						// 	if (
						// 		selectedLessons[selectedLessons.length - 1].id ===
						// 		ownedLessons[index - 1].id
						// 	) {
						// 		disabled = false;
						// 	}
						// }
						return (
							<Grid
								className="select-lesson-item"
								item
								xs={12}
								sm={12}
								md={6}
								lg={6}
								xl={6}
								key={lesson.id}
							>
								<Checkbox
									checked={checked}
									disabled={disabled}
									onChange={(e, checked) => {
										if (checked) {
											setSelectedLessons([lesson]);
											// if allow select multiple uncomment below code and comment above code
											// setSelectedLessons((prev) => [...prev, lesson]);
										} else {
											setSelectedLessons([]);
											// if allow select multiple uncomment below code and comment above code
											// setSelectedLessons((prev) => prev.slice(0, index));
										}
									}}
									icon={<MdCheckBoxOutlineBlank className="normal-icon" />}
									checkedIcon={<MdCheckBox className="normal-icon" />}
								/>
								<Typography className="normal-text lesson-title">
									{lesson.title}
								</Typography>
							</Grid>
						);
					})}
				</Grid>
				<div className="separator" />
			</>
		);
	};

	const renderSelectFirstLessonTime = () => {
		if (lessons.length === 0) {
			return null;
		}

		const isMobile = width <= 768;

		let spacing = 2;
		if (isMobile) {
			spacing = 1;
		}

		const firstLesson = lessons[0];
		const firstLessonBlockTime = firstLesson.blockTime / 60;
		const dayOfWeek = selectedDate.getDay();
		const { startHour, endHour } = availableHours.find(
			(availableHour) => availableHour.dayOfWeek === dayOfWeek
		) ?? { startHour: 8, endHour: 20 };
		let availableHourOfThisDay = Array.from({
			length: endHour - startHour - Math.ceil(firstLessonBlockTime) + 1,
		}).map((_, index) => startHour + index);

		const currentTimezone = -new Date().getTimezoneOffset() / 60;
		const bookingOfThisDay = bookings
			.filter((bookingItem) => {
				const lessonStartTime = new Date(bookingItem.startTime);
				lessonStartTime.setHours(
					lessonStartTime.getHours() - currentTimezone + course.timezone
				);
				return lessonStartTime.toDateString() === selectedDate.toDateString();
			})
			.map((bookingItem) => {
				const lessonStartTime = new Date(bookingItem.startTime);
				lessonStartTime.setHours(
					lessonStartTime.getHours() - currentTimezone + course.timezone
				);
				const lessonEndTime = new Date(bookingItem.endTime);
				lessonEndTime.setHours(
					lessonEndTime.getHours() - currentTimezone + course.timezone
				);
				return {
					startHour: lessonStartTime.getHours(),
					endHour: Math.ceil(
						lessonEndTime.getHours() + lessonEndTime.getMinutes() / 60
					),
				};
			});

		for (let i = 0; i < bookingOfThisDay.length; i++) {
			availableHourOfThisDay = availableHourOfThisDay.filter((hour) => {
				if (
					hour <
					bookingOfThisDay[i].startHour - Math.ceil(firstLessonBlockTime) + 1
				) {
					return true;
				}

				if (hour >= bookingOfThisDay[i].endHour) {
					return true;
				}

				return false;
			});
		}

		return (
			<>
				<Typography className="title-text">
					{`SELECT DATE & TIME - ${lessons[0].title} (${(lessons[0].blockTime / 60).toFixed(2)}hrs)`}
				</Typography>
				{course.timezone !== null && (
					<Typography className="normal-text">
						{`Note: Displayed date and time is in ${formatTimezone(
							course.timezone
						)}`}
					</Typography>
				)}
				<Grid container spacing={spacing}>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
						<Typography className="select-date-time normal-text">
							Select date
						</Typography>
						<Calendar
							onChange={(value) => {
								if (
									value instanceof Date &&
									value.toDateString() !== selectedDate.toDateString()
								) {
									setSelectedDate(value);
									setSelectedHour(null);
								}
							}}
							value={selectedDate}
							minDate={new Date(new Date().getTime() + 86400000)}
							className="normal-text"
							locale="en-US"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
						<Typography className="select-date-time normal-text">
							Select time
						</Typography>
						<Grid container spacing={spacing}>
							{availableHourOfThisDay.map((hour) => (
								<Grid item key={hour} xs={3} sm={3} md={6} lg={6} xl={6}>
									<div
										className={classNames("hour-item", {
											"selected-hour": hour === selectedHour,
										})}
										onClick={() => {
											setSelectedHour(hour);
										}}
									>
										<Typography className="normal-text">
											{formatHourMinute(hour, 0)}
										</Typography>
									</div>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
				<div className="separator" />
			</>
		);
	};

	const renderPackage = () => {
		if (lessons.length === 0) {
			return null;
		}

		return (
			<>
				<Typography className="title-text-black">Classes</Typography>
				{lessons.map((lesson) => (
					<CourseLessonItem lesson={lesson} key={lesson.id} />
				))}
			</>
		);
	};

	const renderSelectCouponCode = () => {
		return (
			<>
				<Typography className="title-text-black">Promo code</Typography>
				<TextField
					size="small"
					autoComplete="off"
					onChange={(e) => {
						setCouponCode(e.target.value);
					}}
					value={couponCode}
					fullWidth
					variant="standard"
					InputProps={{
						disableUnderline: true,
						className: "promo-code-input normal-text",
					}}
				/>
			</>
		);
	};

	const renderSummary = () => {
		if (lessons.length === 0) {
			return null;
		}

		return (
			<>
				<Typography className="title-text">REVIEW & FINISH</Typography>
				{renderPackage()}
				{renderSelectCouponCode()}
				<div className="online-course-lesson-item">
					<Typography className="lesson-title normal-text">
						Discount
					</Typography>
					<Typography className="normal-text">
						{USDFormatter.format(discountPrice)}
					</Typography>
				</div>
				<div className="online-course-lesson-item">
					<Typography className="lesson-title normal-text">
						Service fee
					</Typography>
					<Typography className="normal-text">
						{USDFormatter.format(serviceFee)}
					</Typography>
				</div>
				<div className="online-course-lesson-item">
					<Typography className="lesson-title normal-text bold">
						Total
					</Typography>
					<Typography className="normal-text">
						{USDFormatter.format(totalCost)}
					</Typography>
				</div>
				<div className="checkout-button-container">
					<Button
						className={classNames("normal-button", {
							"primary-button": !isCheckoutButtonDisabled,
							"button-disabled": isCheckoutButtonDisabled,
						})}
						variant="contained"
						disabled={isCheckoutButtonDisabled}
						onClick={onClickCheckout}
					>
						Checkout
					</Button>
				</div>
			</>
		);
	};

	if (!course) {
		return null;
	}

	return (
		<div className="course-checkout-information-container">
			<Typography className="title-text">PAYMENT METHOD</Typography>
			<div className="select-payment-method-container">
				<div
					className={classNames("normal-button select-payment-method-button", {
						"primary-button": selectedMethod === paymentMethods.PAY_FULL_COURSE,
						"disabled-button":
							selectedMethod !== paymentMethods.PAY_FULL_COURSE,
					})}
					onClick={onClickSelectPayFullCourse}
				>
					<Typography className="normal-text bold">PAY FULL COURSE</Typography>
					{course.discount > 0 && <Typography className="save-x-percent">{`Save ${course.discount}%`}</Typography>}
				</div>
				<div
					className={classNames("normal-button select-payment-method-button", {
						"primary-button": selectedMethod === paymentMethods.PAY_AS_YOU_GO,
						"disabled-button": selectedMethod !== paymentMethods.PAY_AS_YOU_GO,
					})}
					onClick={onClickSelectPayAsYouGo}
				>
					<Typography className="normal-text bold">PAY AS YOU GO</Typography>
				</div>
			</div>
			<div className="separator" />
			{renderSelectLessons()}
			{renderSelectFirstLessonTime()}
			{renderSummary()}
		</div>
	);
}
