import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import Image1 from '../../imagenes/img_carousel_home_1.png';
import Image2 from '../../imagenes/img_carousel_home_2.png';
import Image3 from '../../imagenes/img_carousel_home_3.png';
import Image4 from '../../imagenes/img_carousel_home_4.png';

const carouselItems = [
	{
		title: 'AVIATION JOURNEY INTO POWER',
		paragraph: [
			'Here at ORKA, our training methods are designed to fully develop your skills as a pilot and allow you to integrate them into your aviation story. Building effective processes through our courses will enable you to experience aviation safely and effectively, while discovering (or re-discovering) the joy of flying.',
			'Flying should be an empowering, exciting, and enjoyable experience. Learning should be Fun and there is a certain satisfaction obtained from focused practice while building fundamental aviation skills.'
		],
		image: Image1
	},
	{
		title: 'DEFINE YOUR CRAFT',
		paragraph: [
			'Aviators across the globe are hungry to learn. Let them find You here at ORKA. Whether you are a Flight Instructor or not, you can share your skill and knowledge if you have the desire to teach and are a subject matter expert.',
			'We are promoting courses in specialized aviation domains, not limited to: Aerobatics, Competitive Flight, Emergency Procedures, High Altitude, High Performance, Soaring, Tow Pilot, Tailwheel. Register as an Instructor and start crafting your course today. Your curriculum, your training methods, your legacy.'
		],
		image: Image2
	},
	{
		title: 'WHY ORKA',
		paragraph: [
			' We felt a clear need to enable creative space for those with a burning desire to instruct and knew it would require a new approach to aviation education. The creation of an immersive aviation online community could bring instructors and students together in a new format; one that is inclusive and effective for all.',
			'At ORKA, you will find a variety of unique training methods focused on cultivating your knowledge and developing your skills, through a firm grasp of the fundamentals and a foundation of intentional practice.'
		],
		image: Image3
	},
	{
		title: 'EXPLORE ORKA',
		paragraph: [
			'Whether you are an aviation expert drawn to flight instruction, a student who desires top-tier aviation training, or just want to experience the joy of flight, we invite you to explore the courses created by independent flight instructors here at ORKA.'
		],
		image: Image4
	}
];

function CarouselWithDots() {
	return (
		<Carousel controls={false} fade={true}>
			{carouselItems.map((item, index) => {
				return (
					<Carousel.Item key={index} interval={20000}>
						<div
							className='d-flex w-100 first-box-carousel-item-carousel-with-dots justify-content-around align-items-center carousel-container'
							style={{ backgroundImage: `url(${item.image})` }}
						>
							<div className='d-flex justify-content-center align-items-center CarouselText'>
								<div className='NoBgBlur'>
									<div className='text-center AktivGroteskEx-Black Gray Carousel-title home-titles mb-4 '>
										{item.title}
									</div>
									<p className='home-texts ProximaNova-Regular Gray'>
										{item.paragraph[0]}
									</p>
									<p className='home-texts ProximaNova-Regular Gray'>
										{item?.paragraph[1]}
									</p>
								</div>
							</div>
						</div>
					</Carousel.Item>
				);
			})}
		</Carousel>
	);
}

export default CarouselWithDots;
