import { useMemo, useState } from "react";

import { Grid, Typography } from "@mui/material";

import { lessonType } from "../../../../constants";
import { useAccount } from "../../../../hooks/useAccount";
import {
	useInstructorAgendaInit,
	useInstructorAgendas,
} from "../../../../hooks/useInstructorAgenda";
import {
	useStudentSummaries,
	useStudentSummariesInit,
} from "../../../../hooks/useStudentSummaries";
import { ScheduleCalendar } from "../../../common/ScheduleCalendar/ScheduleCalendar";
import NavbarOrka from "../../navegacion/Navbar";

import "./booking.scss";
import { BookingLesson } from "./components/BookingLesson";
import classNames from "classnames";

export function Booking() {
	useInstructorAgendaInit();
	useStudentSummariesInit();
	const { agendas } = useInstructorAgendas();
	const { summaries } = useStudentSummaries();
	const { account } = useAccount();

	const [selectedBooking, setSelectedBooking] = useState(null);

	const instructorAgendas = useMemo(() => {
		return agendas.map((agenda) => ({
			...agenda,
			id: `${agenda.id}-instructor`,
			startDate: new Date(agenda.startTime),
			endDate: new Date(agenda.endTime),
			canReschedule: new Date(agenda.startTime) > new Date(),
			isInstructor: true,
		}));
	}, [agendas]);

	const studentAgendas = useMemo(() => {
		const result = [];
		for (let i = 0; i < summaries.length; i++) {
			for (let j = 0; j < summaries[i].passedBookings.length; j++) {
				if (summaries[i].passedBookings[j].lesson.type === lessonType.ONSITE) {
					result.push({
						...summaries[i].passedBookings[j],
						id: `${summaries[i].passedBookings[j].id}-student`,
						startDate: new Date(summaries[i].passedBookings[j].startTime),
						endDate: new Date(summaries[i].passedBookings[j].endTime),
						canReschedule: false,
						isInstructor: false,
						user: {
							lastName: account?.lastName ?? "",
							firstName: account?.firstName ?? "",
						},
						lesson: {
							...summaries[i].passedBookings[j].lesson,
							course: summaries[i].course,
						},
					});
				}
			}
			for (let j = 0; j < summaries[i].upcomingBookings.length; j++) {
				if (
					summaries[i].upcomingBookings[j].lesson.type === lessonType.ONSITE
				) {
					result.push({
						...summaries[i].upcomingBookings[j],
						id: `${summaries[i].upcomingBookings[j].id}-student`,
						startDate: new Date(summaries[i].upcomingBookings[j].startTime),
						endDate: new Date(summaries[i].upcomingBookings[j].endTime),
						canReschedule: false,
						isInstructor: false,
						user: {
							lastName: account?.lastName ?? "",
							firstName: account?.firstName ?? "",
						},
						lesson: {
							...summaries[i].upcomingBookings[j].lesson,
							course: summaries[i].course,
						},
					});
				}
			}
		}

		return result;
	}, [account?.firstName, account?.lastName, summaries]);

	const combineAgendas = [...instructorAgendas, ...studentAgendas];

	const unscheduledSummaries = useMemo(() => {
		return summaries.filter(
			(summary) => summary.unscheduledBookings.length > 0
		);
	}, [summaries]);

	const onClickSelectLesson = (booking) => {
		setSelectedBooking({...booking, isInstructor: false});
	};

	const onClickReschedule = (agenda) => {
		setSelectedBooking({
			...agenda,
			id: Number(agenda.id.split('-')[0]),
			isInstructor: true,
		});
	};

	return (
		<div style={{ height: "100vh" }}>
			<NavbarOrka chatScreen isHiThere isInHome />
			<div className="booking-content-container">
				<ScheduleCalendar data={combineAgendas} onClickReschedule={onClickReschedule} />
				<Typography className="select-class-text">
					Select a class to schedule
				</Typography>
				{selectedBooking && (
					<BookingLesson booking={selectedBooking} setSelectedBooking={setSelectedBooking} />
				)}
				{unscheduledSummaries.map((summary) => {
					return (
						<div key={summary.id}>
							<Typography className="booking-course-name">
								{summary.course.name}
							</Typography>
							<Grid container spacing={2}>
								{summary.unscheduledBookings.map((booking) => {
									return (
										<Grid
											item
											key={booking.id}
											xs={12}
											sm={12}
											md={6}
											lg={4}
											xl={3}
										>
											<div
												className={classNames("booking-lesson-item", {selected: booking.id === selectedBooking?.id})}
												onClick={() => {
													onClickSelectLesson({
														...booking,
														lesson: {
															...booking.lesson,
															course: { ...summary.course },
														},
													});
												}}
											>
												<Typography className="lesson-name normal-text">
													{booking.lesson.title}
												</Typography>
												<Typography className="lesson-name normal-text">
													{`${(booking.lesson.blockTime / 60).toFixed(2)} hrs`}
												</Typography>
											</div>
										</Grid>
									);
								})}
							</Grid>
						</div>
					);
				})}
			</div>
		</div>
	);
}
