import React, { useState, useRef, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaCircleQuestion } from 'react-icons/fa6';

const Help = () => {
	const wrapperRef = useRef(null);
	const [show, setShow] = useState(false);

	useEffect(() => {
		function handleClickOutside(event) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setShow(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className='help' ref={wrapperRef}>
			<div className='box-helpImgPc'>
				<div className='helpImg helpButton me-2' onClick={() => setShow(!show)}>
					<FaCircleQuestion className='circle-help' />
				</div>

				<span className='help-text' onClick={() => setShow(!show)}>
					Need Help?
				</span>
			</div>

			{show && (
				<div className='helpBox'>
					<Row>
						<Col md='10'>
							<h5 className='titleHelpBox mb-3'>CONTACT US</h5>
						</Col>
						<Col md='2'>
							<span className='closeButtonHelp'></span>
						</Col>
					</Row>
					<p className='textHelpBox'>
						Call us or email us at{' '}
						<a className='linkHelpBox' href='mailto:help@flyorka.com'>
							hello@flyorka.com
						</a>
						{''} or{' '}
						<a className='linkHelpBox' href='tel: 843 310 1471'>
							843.310.1471
						</a>
						. Our customer service is available for you every day from 8 a.m.
						until 8 p.m. (EST Time)
					</p>
				</div>
			)}
		</div>
	);
};

export default Help;
