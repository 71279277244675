import { useRef } from "react";

import { Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import { useMyOwnedCoursesStudent } from "../../../../../../hooks/useMyOwnedCoursesStudent";
import { ViewMediaModal } from "../../../../../common/ViewMediaModal/ViewMediaModal";
import { CourseDocumentImage } from "./CourseDocumentImage";
import { CourseDocumentVideo } from "./CourseDocumentVideo";

export function CourseAviationStory() {
	const params = useParams();
	const slug = params.slug ?? "";
	const { course } = useMyOwnedCoursesStudent(slug);
	const viewMediaModalRef = useRef();

	const onClickViewMediaFile = (fileToOpen) => {
		const index = course.courseDocuments.findIndex(
			(file) => file.id === fileToOpen.id
		);
		if (index > -1) {
			viewMediaModalRef.current?.open({ index });
		}
	};

	if (!course) {
		return null;
	}

	return (
		<>
			<div className="course-aviation-story">
				<Typography component="p" className="yellow-label">
					AVIATION STORY
				</Typography>
				<Grid container>
					{course.courseDocuments.map((file) => {
						if (file.contentType.startsWith("image")) {
							return (
								<Grid
									key={`${file.id}`}
									item
									xs={4}
									sm={3}
									md={4}
									lg={3}
									xl={2}
									onClick={() => {
										onClickViewMediaFile(file);
									}}
								>
									<CourseDocumentImage file={file} />
								</Grid>
							);
						}

						if (file.contentType.startsWith("video")) {
							return (
								<Grid
									key={`${file.id}`}
									item
									xs={4}
									sm={3}
									md={4}
									lg={3}
									xl={2}
									onClick={() => {
										onClickViewMediaFile(file);
									}}
								>
									<CourseDocumentVideo file={file} />
								</Grid>
							);
						}

						return null;
					})}
				</Grid>
				<div
					style={{
						height: 1,
						width: "100%",
						backgroundColor: "#DCE3E5",
						marginTop: 10,
					}}
				/>
			</div>
			<ViewMediaModal
				ref={viewMediaModalRef}
				mediaFiles={course.courseDocuments}
			/>
		</>
	);
}
