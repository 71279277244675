import { Button, Typography } from "@mui/material";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { USDFormatter } from "../../../../utils";
import { NotFound } from "../../../common/NotFound/NotFound";
import NavbarOrka from "../../navegacion/Navbar";
import {
	usePaymentSuccess,
	usePaymentSuccessInit,
} from "./hooks/usePaymentSuccess";

import "./payment_success.scss";

export function PaymentSuccess() {
	const { isNotFound } = usePaymentSuccessInit();
	const { course, bookings } = usePaymentSuccess();
	const navigate = useNavigate();

	const calculateTotalPrice = () => {
		let totalPrice = 0;
		for (let i = 0; i < bookings.length; i++) {
			totalPrice += bookings[i].details.totalCost;
		}

		return totalPrice;
	};

	const onClickViewDetail = () => {
		if (!course) {
			return;
		}

		navigate(`/owned-courses/${course.courseSlug}`);
	};

	if (isNotFound) {
		return <NotFound text="Course not found" />;
	}

	if (!course || bookings.length === 0) {
		return null;
	}

	const totalPrice = calculateTotalPrice();

	const prices = [
		{ text: "Classes", value: USDFormatter.format(totalPrice) },
		{
			text: "Discount",
			value: `${bookings[0].discount}%`,
			hidden: bookings[0].discount === 0,
		},
		{
			text: "Price after discount",
			value: USDFormatter.format(
				(totalPrice * (100 - bookings[0].discount)) / 100
			),
			hidden: bookings[0].discount === 0,
		},
		{
			text: "Service fee",
			value: USDFormatter.format(
				((totalPrice * (100 - bookings[0].discount)) / 100) * 0.1 + 0.3
			),
		},
		{
			text: "Promo code",
			value: bookings[0].couponCode,
			hidden: bookings[0].couponCode === "",
		},
		{
			text: "Total",
			value: USDFormatter.format(bookings[0].totalCost / 100),
			bold: true,
		},
	].filter((price) => !price.hidden);

	return (
		<div style={{ height: "100%" }}>
			<NavbarOrka chatScreen isHiThere isInHome />
			<div className="payment-success-content-container">
				<Typography className="course-title" component="p">
					{course.name}
				</Typography>
				<Typography className="course-description normal-text" component="p">
					{course.description}
				</Typography>
				<div className="separator" />
				<Typography className="title-text">Classes</Typography>
				{bookings.map((booking) => (
					<div className="course-lesson-item" key={booking.id}>
						<Typography className="lesson-title normal-text">
							{booking.details.title}
						</Typography>
						<Typography className="lesson-price normal-text">
							{USDFormatter.format(booking.details.totalCost)}
						</Typography>
					</div>
				))}
				<div className="separator" />
				<Typography className="title-text">Cost</Typography>
				{prices.map((price) => (
					<div className="course-lesson-item" key={price.text}>
						<Typography
							className={classNames("lesson-title normal-text", {
								bold: price.bold,
							})}
						>
							{price.text}
						</Typography>
						<Typography className="lesson-price normal-text">
							{price.value}
						</Typography>
					</div>
				))}
				<div className="button-container">
					<Button
						className="normal-button primary-button"
						variant="contained"
						onClick={onClickViewDetail}
					>
						View details
					</Button>
				</div>
			</div>
		</div>
	);
}
