import { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import { IconButton, Modal, Tooltip } from "@mui/material";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
// Don't remove ReactZoomPanPinchRef
import {
	// eslint-disable-next-line no-unused-vars
	ReactZoomPanPinchRef,
	TransformComponent,
	TransformWrapper,
} from "react-zoom-pan-pinch";

import "./view_media_modal.scss";

export const ViewMediaModal = forwardRef(({ mediaFiles }, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const [showingIndex, setShowingIndex] = useState(0);
	const [scale, setScale] = useState(1);

	const open = useCallback(
		({ index }) => {
			if (index >= 0 && index <= mediaFiles.length) {
				setIsOpen(true);
				setShowingIndex(index);
			}
		},
		[mediaFiles]
	);

	const close = () => {
		setIsOpen(false);
	};

	useImperativeHandle(ref, () => ({ open, close }), [open]);

	const onClose = (event, reason) => {
		if (reason === "escapeKeyDown") {
			close();
		}
	};

	/**
	 *
	 * @param {ReactZoomPanPinchRef} ref
	 * @param {MouseEvent | TouchEvent} event
	 */
	const onZoomStop = (ref, event) => {
		setScale(Math.round(ref.state.scale));
	};

	const onClickGoToNextMediaFile = () => {
		if (showingIndex < mediaFiles.length - 1) {
			setShowingIndex((prev) => prev + 1);
		}
	};

	const onClickGoToPreviousMediaFile = () => {
		if (showingIndex > 0) {
			setShowingIndex((prev) => prev - 1);
		}
	};

	const renderMedia = () => {
		if (mediaFiles.length === 0) {
			return null;
		}

		if (mediaFiles[showingIndex].contentType.startsWith("image")) {
			return (
				<TransformWrapper
					minScale={1}
					maxScale={5}
					onZoomStop={onZoomStop}
					smooth={false}
					doubleClick={{ step: 2, mode: scale === 5 ? "reset" : "zoomIn" }}
					key={mediaFiles[showingIndex].url}
				>
					<TransformComponent
						wrapperClass="transform-wrapper"
						contentClass="image-container"
					>
						<img
							src={mediaFiles[showingIndex].url}
							className="image"
							alt={mediaFiles[showingIndex].url}
						/>
					</TransformComponent>
				</TransformWrapper>
			);
		}

		if (mediaFiles[showingIndex].contentType.startsWith("video")) {
			return (
				<video className="video-container" controls>
					<source src={mediaFiles[showingIndex].url} type="video/mp4" />
				</video>
			);
		}
	};

	return (
		<Modal open={isOpen} onClose={onClose}>
			<div className="view-media-modal-container">
				<Tooltip title="Close">
					<IconButton
						className="change-media-button change-media-button-close-button"
						onClick={close}
					>
						<IoClose />
					</IconButton>
				</Tooltip>
				<Tooltip title="Previous">
					<IconButton
						className="change-media-button change-media-button-left"
						onClick={onClickGoToPreviousMediaFile}
					>
						<FaAngleLeft />
					</IconButton>
				</Tooltip>
				{renderMedia()}
				<Tooltip title="Next">
					<IconButton
						className="change-media-button change-media-button-right"
						onClick={onClickGoToNextMediaFile}
					>
						<FaAngleRight />
					</IconButton>
				</Tooltip>
			</div>
		</Modal>
	);
});
