import { useState } from "react";

import { apis, callApi } from "../../../api";
import { AccountService, useAccount } from "../../../hooks/useAccount";

const ContentAccountInformationPageInstructor = () => {
	const { account } = useAccount();

	const [firstName, setFirstName] = useState(account?.firstName ?? "");
	const [middleName, setMiddleName] = useState(account?.middleName ?? "");
	const [lastName, setLastName] = useState(account?.lastName ?? "");
	const [nickName, setNickName] = useState(account?.nickName ?? "");
	const [address, setAddress] = useState(account?.address ?? "");
	const [city, setCity] = useState(account?.city ?? "");
	const [state, setState] = useState(account?.state ?? "");
	const [zip, setZip] = useState(account?.zip ?? "");
	const [phoneNumber, setPhoneNumber] = useState(account?.phoneNumber ?? "");

	const updateUser = async (changedData) => {
		try {
			const response = await callApi({
				method: "PATCH",
				url: apis.UPDATE_USER_INFORMATION,
				body: changedData,
			});
			AccountService.saveAccount(response.user);
		} catch (error) {}
	};

	const updatePersonalInformation = () => {
		const changedData = {
			firstName,
			lastName,
			phoneNumber,
			email: account?.email ?? "",
		};
		if (account.middleName !== middleName) {
			changedData.middleName = middleName;
		}
		if (account.nickName !== nickName) {
			changedData.nickName = nickName;
		}
		if (account.address !== address) {
			changedData.address = address;
		}
		if (account.city !== city) {
			changedData.city = city;
		}
		if (account.state !== state) {
			changedData.state = state;
		}
		if (account.zip !== zip) {
			changedData.zip = zip;
		}
		updateUser(changedData);
	};

	if (!account) {
		return null;
	}

	return (
		<div className="col-md-8 container-personal-info-and-account-info">
			<div className="row titles-personal-info">
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "baseline",
					}}
				>
					<h4 className="titles-sections-info" style={{ marginRight: ".5rem" }}>
						Personal Information
					</h4>
					{!(
						account.firstName !== firstName ||
						account.middleName !== middleName ||
						account.lastName !== lastName ||
						account.nickName !== nickName ||
						account.address !== address ||
						account.city !== city ||
						account.state !== state ||
						account.zip !== zip
					) ? (
						<i
							style={{ cursor: "pointer" }}
							className="lni lni-pencil FontWeightBold FontSize24 ms-3 GrayTitle"
						/>
					) : (
						<i
							style={{ cursor: "pointer" }}
							onClick={updatePersonalInformation}
							className="lni lni-save FontWeightBold FontSize24 ms-3 GrayTitle"
						/>
					)}
				</div>
			</div>

			<div className="item-personal-info">
				<div className="" style={{ paddingRight: "2rem" }}>
					<label className="label-element">First name</label>
					<input
						type="text"
						className="form-control edicion-text-box"
						value={firstName}
						onChange={(e) => {
							setFirstName(e.target.value);
						}}
					/>
				</div>

				<div className="" style={{ paddingRight: "2rem" }}>
					<label className="label-element">Middle name</label>
					<input
						type="text"
						className="form-control edicion-text-box"
						value={middleName}
						onChange={(e) => {
							setMiddleName(e.target.value);
						}}
					/>
				</div>

				<div
					className="field-full-last-name-pc"
					style={{ paddingRight: "2rem" }}
				>
					<label className="label-element">Last name</label>
					<input
						type="text"
						className="form-control edicion-text-box"
						value={lastName}
						onChange={(e) => {
							setLastName(e.target.value);
						}}
					/>
				</div>
			</div>

			<div className="item-personal-info">
				<div className="field-full-last-name-mobil">
					<label className="label-element">Last name</label>
					<input
						type="text"
						className="form-control edicion-text-box"
						value={lastName}
						onChange={(e) => {
							setLastName(e.target.value);
						}}
					/>
				</div>

				<div className="">
					<label className="label-element">Nickname</label>
					<input
						type="text"
						className="form-control edicion-text-box"
						value={nickName}
						onChange={(e) => {
							setNickName(e.target.value);
						}}
					/>
				</div>
			</div>

			<div className="item-personal-info">
				<div className="box-input-address mb-3 mb-sm-0">
					<label className="label-element">Address</label>
					<input
						type="text"
						className="form-control edicion-text-box"
						value={address}
						onChange={(e) => {
							setAddress(e.target.value);
						}}
					/>
				</div>

				<div className="box-city-state-zip">
					<div className="">
						<label className="label-element">City</label>
						<input
							type="text"
							className="form-control edicion-text-box"
							value={city}
							onChange={(e) => {
								setCity(e.target.value);
							}}
						/>
					</div>

					<div className="">
						<label className="label-element">State</label>
						<input
							type="text"
							className="form-control edicion-text-box"
							value={state}
							onChange={(e) => {
								setState(e.target.value);
							}}
						/>
					</div>

					<div className="">
						<label className="label-element">Zip Code</label>
						<input
							type="text"
							className="form-control edicion-text-box"
							value={zip}
							onChange={(e) => {
								setZip(e.target.value);
							}}
						/>
					</div>
				</div>
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "baseline",
				}}
			>
				<h4 className="titles-sections-info" style={{ marginRight: ".5rem" }}>
					Account Information
				</h4>
				{!(account.phoneNumber !== phoneNumber) ? (
					<i
						style={{ cursor: "pointer" }}
						className="lni lni-pencil FontWeightBold FontSize24 ms-3 GrayTitle"
					/>
				) : (
					<i
						style={{ cursor: "pointer" }}
						onClick={updatePersonalInformation}
						className="lni FontWeightBold lni-save FontSize24 ms-3 GrayTitle"
					/>
				)}
			</div>

			<div className="row item">
				<div className="col-xs-6 col-md-6 mb-3 mb-sm-0">
					<label className="label-element">Email</label>
					<input
						type="email"
						autoComplete="off"
						className="form-control edicion-text-box"
						value={account.email}
						disabled
					/>
				</div>

				<div className="col-xs-6 col-md-6">
					<label className="label-element">Phone number</label>
					<input
						type="text"
						className="form-control edicion-text-box"
						value={phoneNumber}
						onChange={(e) => {
							setPhoneNumber(e.target.value);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default ContentAccountInformationPageInstructor;
