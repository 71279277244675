import { Checkbox, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { IoMdRadioButtonOn } from "react-icons/io";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

export function CourseWhatIsIncludedInfo({ control, name, type, leftText }) {
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, value } }) => {
				const renderValue = () => {
					if (type === "text") {
						return (
							<Typography className="normal-text" component="p">
								{value}
							</Typography>
						);
					}

					if (type === "checkbox") {
						return (
							<Checkbox
								checked={value}
								onChange={onChange}
								icon={<MdCheckBoxOutlineBlank className="normal-icon" />}
								checkedIcon={<MdCheckBox className="normal-icon" />}
							/>
						);
					}

					if (type === "radio") {
						return (
							<Checkbox
								checked
								checkedIcon={<IoMdRadioButtonOn className="normal-icon" />}
							/>
						);
					}

					return null;
				};

				return (
					<div className="what-is-included-item-container">
						<div className="what-is-included-item-left">
							<Typography className="normal-text" component="p">
								{leftText}
							</Typography>
						</div>
						<div className="what-is-included-item-right">{renderValue()}</div>
					</div>
				);
			}}
		/>
	);
}
