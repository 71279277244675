import { useEffect, useState } from "react";

import { apis, callApi } from "../api";

const cacheTime = 10 * 60 * 1000;

export const MyOwnedCoursesStudentService = {
	listeners: {},
	cacheTimes: {},
	savedCourses: {},

	addEventListener(slug, cb) {
		this.listeners[slug] = (this.listeners[slug] ?? []).filter(
			(listener) => listener !== cb
		);
		this.listeners[slug].push(cb);
	},

	saveCourse(
		slug,
		course,
		passedLessons,
		upcomingLessons,
		unscheduledLessons,
		missingLessons
	) {
		this.savedCourses[slug] = {
			course,
			passedLessons,
			upcomingLessons,
			unscheduledLessons,
			missingLessons,
		};
		this.savedCourses = { ...this.savedCourses };
		this.cacheTimes[slug] = Date.now();
		this.emitChanges(slug);
	},

	finishALesson(slug, bookingId) {
		if (this.savedCourses[slug]) {
			const index = this.savedCourses[slug].upcomingLessons.findIndex(
				(booking) => booking.id === bookingId
			);

			if (index === -1) {
				return;
			}

			const finishedLesson = this.savedCourses[slug].upcomingLessons[index];
			this.savedCourses[slug].upcomingLessons = this.savedCourses[
				slug
			].upcomingLessons.filter((booking) => booking.id !== bookingId);
			this.savedCourses[slug].passedLessons.unshift({
				...finishedLesson,
				isPassed: true,
			});
			this.savedCourses = { ...this.savedCourses };
			this.emitChanges(slug);
		}
	},

	clearData() {
		this.cacheTimes = {};
		this.savedCourses = {};
		this.emitChanges();
	},

	emitChanges(slug) {
		for (const listener of this.listeners[slug] ?? []) {
			listener();
		}
	},

	removeEventListener(slug, cb) {
		if (this.listeners[slug]) {
			this.listeners[slug] = this.listeners[slug].filter(
				(listener) => listener !== cb
			);
		}
	},
};

export function useMyOwnedCoursesStudentInit(slug) {
	const [isNotFound, setIsNotFound] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const getCourseBySlug = async () => {
			try {
				setIsLoading(true);
				setIsNotFound(false);
				const response = await callApi({
					method: "GET",
					url: `${apis.MY_OWNED_COURSE_BY_SLUG}/${slug}`,
				});

				if (!response) {
					return;
				}

				MyOwnedCoursesStudentService.saveCourse(
					slug,
					response.course,
					response.passedLessons,
					response.upcomingLessons,
					response.unscheduledLessons,
					response.missingLessons
				);
			} catch (error) {
				if (error?.response?.status === 404) {
					setIsNotFound(true);
				}
			} finally {
				setIsLoading(false);
			}
		};

		const now = Date.now();

		if (
			MyOwnedCoursesStudentService.savedCourses[slug] &&
			MyOwnedCoursesStudentService.cacheTimes[slug] &&
			now - MyOwnedCoursesStudentService.cacheTimes[slug] < cacheTime
		) {
			return;
		}

		if (!slug) {
			return;
		}

		getCourseBySlug();
	}, [slug]);

	return { isNotFound, isLoading };
}

export function useMyOwnedCoursesStudent(slug) {
	const [course, setCourse] = useState(
		MyOwnedCoursesStudentService.savedCourses[slug]?.course ?? null
	);
	const [passedLessons, setPassedLessons] = useState(
		MyOwnedCoursesStudentService.savedCourses[slug]?.passedLessons ?? []
	);
	const [upcomingLessons, setUpcomingLessons] = useState(
		MyOwnedCoursesStudentService.savedCourses[slug]?.upcomingLessons ?? []
	);
	const [unscheduledLessons, setUnscheduledLessons] = useState(
		MyOwnedCoursesStudentService.savedCourses[slug]?.unscheduledLessons ?? []
	);
	const [missingLessons, setMissingLessons] = useState(
		MyOwnedCoursesStudentService.savedCourses[slug]?.missingLessons ?? []
	);

	useEffect(() => {
		const updateMyOwnedCourse = () => {
			setCourse(
				MyOwnedCoursesStudentService.savedCourses[slug]?.course ?? null
			);
			setPassedLessons(
				MyOwnedCoursesStudentService.savedCourses[slug]?.passedLessons ?? []
			);
			setUpcomingLessons(
				MyOwnedCoursesStudentService.savedCourses[slug]?.upcomingLessons ?? []
			);
			setUnscheduledLessons(
				MyOwnedCoursesStudentService.savedCourses[slug]?.unscheduledLessons ??
					[]
			);
			setMissingLessons(
				MyOwnedCoursesStudentService.savedCourses[slug]?.missingLessons ?? []
			);
		};

		updateMyOwnedCourse();

		MyOwnedCoursesStudentService.addEventListener(slug, updateMyOwnedCourse);

		return () => {
			MyOwnedCoursesStudentService.removeEventListener(
				slug,
				updateMyOwnedCourse
			);
		};
	}, [slug]);

	return {
		course,
		passedLessons,
		upcomingLessons,
		unscheduledLessons,
		missingLessons,
	};
}
