import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from "@mui/material";

import { lessonType } from "../../../../../../constants";
import { formatTimeRange, formatTimezone } from "../../../../../../utils";

export function PaidLesson({ booking }) {
	const lesson = booking.lesson;

	const renderLessonInfo = () => {
		let dateText = "";
		let timeText = "";

		if (
			lesson.type === lessonType.ONSITE &&
			booking.startTime &&
			booking.endTime
		) {
			const result = formatTimeRange(booking.startTime, booking.endTime);
			dateText = result.dateText;
			timeText = result.timeText;
		}

		return (
			<>
				<Typography className="normal-text">{`Description: ${lesson.description}`}</Typography>
				{lesson.type === lessonType.ONSITE &&
					booking.startTime &&
					booking.endTime && (
						<>
							<Typography className="normal-text">{`Date: ${dateText}`}</Typography>
							<Typography className="normal-text">{`Time: ${timeText}`}</Typography>
							<Typography className="normal-text">
								{`Time zone: ${formatTimezone(
									-new Date().getTimezoneOffset() / 60
								)}`}
							</Typography>
						</>
					)}
				<div className="separator" />
				{lesson.type === lessonType.ONSITE && (
					<>
						<Typography component="p" className="normal-text bold">
							Class summary
						</Typography>
						<Typography className="normal-text">{`Air hours: ${booking.airHours} hours`}</Typography>
						<Typography className="normal-text">{`Simulator hours: ${booking.simulatorHours} hours`}</Typography>
						<Typography className="normal-text">{`Miles flown: ${booking.milesFlown} miles`}</Typography>
						<Typography className="normal-text">{`Flight summary: ${booking.flightSummary}`}</Typography>
						<div className="separator" />
					</>
				)}
			</>
		);
	};

	return (
		<div className="course-lesson-item-container">
			<Accordion>
				<AccordionSummary>
					<div style={{ width: "100%" }}>
						<Typography component="p" className="normal-text bold lesson-title">
							{lesson.title}
						</Typography>
					</div>
				</AccordionSummary>
				<AccordionDetails style={{ padding: 0 }}>
					{renderLessonInfo()}
				</AccordionDetails>
			</Accordion>
		</div>
	);
}
