import { Typography } from "@mui/material";

import { courseType } from "../../../../../../constants";
import { useGoogleMap } from "../../../../../../hooks/useGoogleMap";
import { USDFormatter } from "../../../../../../utils";
import { usePreviewCourse } from "../../hooks/usePreviewCourse";
import { CourseInfo } from "../CourseInfo";
import { CourseLocation } from "./CourseLocation";

export function CourseBasicInformation() {
	const { course } = usePreviewCourse();

	const { isLoaded } = useGoogleMap();

	const calculatePrice = () => {
		if (!course) {
			return 0;
		}

		let price = 0;
		for (let i = 0; i < course.lesson.length; i++) {
			price += course.lesson[i].totalCost ?? 0;
		}
		return price;
	};

	const calculateTotalFlightTime = () => {
		if (!course) {
			return 0;
		}

		let totalFlightTime = 0;
		for (let i = 0; i < course.lesson.length; i++) {
			totalFlightTime += course.lesson[i].flightTime ?? 0;
		}
		return totalFlightTime / 60;
	};

	const calculateTotalTeachingTime = () => {
		if (!course) {
			return 0;
		}

		let totalTeachingTime = 0;
		for (let i = 0; i < course.lesson.length; i++) {
			totalTeachingTime += course.lesson[i].teachingTime ?? 0;
		}
		return totalTeachingTime / 60;
	};

	const courseBasicInfo = [
		{
			label: "COURSE NAME",
			labelClassName: "yellow-label",
			value: course?.name || "No information",
			valueClassName: "normal-text basic-info-value",
			endGroup: true,
		},
		{
			label: "COURSE DESCRIPTION",
			labelClassName: "yellow-label",
			value: course?.description || "No information",
			valueClassName: "normal-text basic-info-value",
		},
		{
			label: "Why is this important to you?",
			labelClassName: "normal-label",
			value: course?.important || "No information",
			valueClassName: "normal-text basic-info-value",
		},
		{
			label: "What is the Workload?",
			labelClassName: "normal-label",
			value: course?.workload || "No information",
			valueClassName: "normal-text basic-info-value",
		},
		{
			label: "How is the learning experience?",
			labelClassName: "normal-label",
			value: course?.experience || "No information",
			valueClassName: "normal-text basic-info-value",
			endGroup: true,
		},
		{
			label: "MEET YOUR INSTRUCTOR",
			labelClassName: "yellow-label",
			value: course?.instructor || "No information",
			valueClassName: "normal-text basic-info-value",
			endGroup: true,
		},
	];

	const whatIsIncluded = [
		{
			text: `${calculateTotalTeachingTime().toFixed(
				2
			)} hours of Knowledge Development.`,
			visible: true,
		},
		{
			text: `${calculateTotalFlightTime().toFixed(
				2
			)} hours of flight time to develop your skill.`,
			visible: course?.type === courseType.ONSITE,
		},
		{
			text: "Coaching via chat and your student portal.",
			visible: course?.coaching,
		},
		{
			text: "Unlimited access to ORKA Knowledge Base Academics, Hurray!",
			visible: course?.lifeTimeAccess,
		},
		{
			text: "Mentorship.",
			visible: course?.mentorship,
		},
	].filter((info) => info.visible);

	const renderWhatIsIncluded = () => {
		if (!course) {
			return null;
		}

		return (
			<>
				<Typography component="p" className="yellow-label">
					WHAT'S INCLUDED
				</Typography>
				{whatIsIncluded.map((info) => {
					return (
						<Typography className="normal-text" key={info.text}>
							{info.text}
						</Typography>
					);
				})}
				<div className="separator" />
			</>
		);
	};

	const renderCoursePricing = () => {
		if (!course) {
			return null;
		}

		return (
			<>
				<CourseInfo
					label="TOTAL COST"
					labelClassName="yellow-label"
					value={`${USDFormatter.format(calculatePrice())}`}
					valueClassName="normal-text"
				/>
				<div className="separator" />
			</>
		);
	};

	const renderCourseLocation = () => {
		if (!course) {
			return null;
		}

		if (course.type === courseType.ONSITE && isLoaded) {
			return (
				<>
					<CourseLocation />
					<div className="separator" />
				</>
			);
		}
	};

	if (!course) {
		return null;
	}

	return (
		<div className="course-basic-info-container">
			{courseBasicInfo.map((info) => {
				return (
					<div key={info.label}>
						<CourseInfo {...info} />
						{info.endGroup && <div className="separator" />}
					</div>
				);
			})}
			{renderWhatIsIncluded()}
			{renderCoursePricing()}
			{renderCourseLocation()}
		</div>
	);
}
