import { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import { IconButton, Modal, Tooltip } from "@mui/material";
import { IoClose } from "react-icons/io5";

import "./image_viewer.scss";

export const FileViewer = forwardRef((props, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const [url, setUrl] = useState(null);

	const open = useCallback(({ url: imageUrl }) => {
		setUrl(imageUrl);
		setIsOpen(true);
	}, []);

	const close = () => {
		setIsOpen(false);
	};

	useImperativeHandle(ref, () => ({ open, close }), [open]);

	const onClose = (event, reason) => {
		if (reason === "escapeKeyDown") {
			close();
		}
	};

	const renderMedia = () => {
		return <iframe className="iframe-container" src={url} title={url} />;
	};

	return (
		<Modal open={isOpen} onClose={onClose}>
			<div className="view-media-modal-container">
				<Tooltip title="Close">
					<IconButton className="close-button" onClick={close}>
						<IoClose />
					</IconButton>
				</Tooltip>
				{renderMedia()}
			</div>
		</Modal>
	);
});
