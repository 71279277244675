import { useEffect } from "react";

import { Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { useMyOwnedCoursesStudentInit } from "../../../../hooks/useMyOwnedCoursesStudent";
import {
	useStudentSummaries,
	useStudentSummariesInit,
} from "../../../../hooks/useStudentSummaries";
import NavbarOrka from "../../navegacion/Navbar";
import { Courses } from "./components/Courses/Courses";
import { CourseSummary } from "./components/CourseSummary/CourseSummary";
import { useStudentSelectSummaryInit } from "./hooks/useStudentSelectSummary";

import "./my_orka_student.scss";

export function MyOrkaStudent() {
	const [searchParams, setSearchParams] = useSearchParams();
	const { isLoading } = useStudentSummariesInit();
	const { summaries } = useStudentSummaries();
	const courseSlug =
		searchParams.get("course") ?? summaries[0]?.course?.courseSlug ?? "";
	const { isLoading: isLoadingOwnedCourse, isNotFound } =
		useMyOwnedCoursesStudentInit(courseSlug);
	useStudentSelectSummaryInit();

	useEffect(() => {
		if (isNotFound) {
			searchParams.delete("course");
			setSearchParams(searchParams);
		}
	}, [isNotFound, searchParams, setSearchParams]);

	if (isLoading) {
		return null;
	}

	return (
		<div style={{ height: "100vh" }}>
			<NavbarOrka chatScreen isHiThere isInHome />
			<div className="my-orka-student-content-container">
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
						<Typography className="section-title">CURRENT CLASSES</Typography>
						<Courses />
					</Grid>
					<Grid item xs={12} sm={12} md={7} lg={9} xl={9}>
						<Typography className="section-title">PROGRESS OVERVIEW</Typography>
						<CourseSummary
							isLoading={isLoadingOwnedCourse}
							courseSlug={courseSlug}
						/>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}
