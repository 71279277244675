import { Typography } from "@mui/material";

import { USDFormatter } from "../../../../../utils";

export function CourseLessonItem({ lesson }) {
	return (
		<div className="online-course-lesson-item">
			<Typography className="lesson-title normal-text">
				{lesson.title}
			</Typography>
			<Typography className="lesson-price normal-text">
				{USDFormatter.format(lesson.totalCost)}
			</Typography>
		</div>
	);
}
