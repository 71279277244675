import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as uuid from "uuid";

import { apis, callApi } from "../../../../../api";
import {
	courseStatus,
	courseType,
	lessonType,
	MAX_NUMBER_OF_COURSE_DOCUMENT_IMAGE,
	MAX_NUMBER_OF_COURSE_DOCUMENT_VIDEO,
	MIN_NUMBER_OF_COURSE_DOCUMENT_IMAGE,
	MIN_NUMBER_OF_COURSE_DOCUMENT_VIDEO,
} from "../../../../../constants";
import { useAccount } from "../../../../../hooks/useAccount";
import { validators } from "../../../../../utils";

const MyCourseInstructorService = {
	listeners: [],
	savedCourse: null,

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	saveCourse(newCourse) {
		this.savedCourse = newCourse;
		this.emitChanges();
	},

	clearData() {
		this.savedCourse = null;
		this.emitChanges();
	},

	/**
	 * Send new course data to all listeners
	 */
	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

const MyCourseDocumentsInstructorService = {
	listeners: [],
	mediaFiles: [],

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	saveFiles(newMediaFiles) {
		this.mediaFiles = newMediaFiles;
		this.emitChanges();
	},

	addNewFile(newMediaFile) {
		this.mediaFiles = [...this.mediaFiles, newMediaFile];
		this.emitChanges();
	},

	updateFileByUploadId(uploadId, newMediaFile) {
		const index = this.mediaFiles.findIndex(
			(file) => file.uploadId === uploadId
		);

		if (index === -1) {
			return;
		}

		this.mediaFiles[index] = newMediaFile;
		this.mediaFiles = [...this.mediaFiles];
		this.emitChanges();
	},

	deleteFileByUploadId(uploadId) {
		this.mediaFiles = this.mediaFiles.filter(
			(file) => file.uploadId !== uploadId
		);
		this.emitChanges();
	},

	removeFile(id) {
		this.mediaFiles = this.mediaFiles.filter((file) => file.id !== id);
		this.emitChanges();
	},

	clearData() {
		this.mediaFiles = [];
		this.emitChanges();
	},

	/**
	 * Send new media files to all listeners
	 */
	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

const MyCourseLessonsInstructorService = {
	listeners: [],
	lessons: [],

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	saveLessons(newLessons) {
		this.lessons = newLessons;
		this.emitChanges();
	},

	addNewLesson(newLesson) {
		this.lessons = [...this.lessons, newLesson];
		this.emitChanges();
	},

	deleteLesson(lessonId) {
		this.lessons = this.lessons.filter((lesson) => lesson.id !== lessonId);
		this.emitChanges();
	},

	updateLesson(lessonId, newLesson) {
		const index = this.lessons.findIndex((lesson) => lesson.id === lessonId);

		if (index === -1) {
			return;
		}

		this.lessons[index] = {
			...this.lessons[index],
			...newLesson,
		};
		this.lessons = [...this.lessons];
		this.emitChanges();
	},

	addLessonFile(lessonId, newFile) {
		const index = this.lessons.findIndex((lesson) => lesson.id === lessonId);

		if (index === -1) {
			return;
		}

		this.lessons[index].lessonDocuments.push(newFile);
		this.lessons = [...this.lessons];
		this.emitChanges();
	},

	deleteLessonFile(lessonId, fileId) {
		const index = this.lessons.findIndex((lesson) => lesson.id === lessonId);

		if (index === -1) {
			return;
		}

		this.lessons[index].lessonDocuments = this.lessons[
			index
		].lessonDocuments.filter((file) => file.id !== fileId);
		this.lessons[index].isOk = checkLessonInfo(this.lessons[index]);
		this.lessons = [...this.lessons];
		this.emitChanges();
	},

	deleteFileByUploadId(lessonId, uploadId) {
		const index = this.lessons.findIndex((lesson) => lesson.id === lessonId);

		if (index === -1) {
			return;
		}

		this.lessons[index].lessonDocuments = this.lessons[
			index
		].lessonDocuments.filter((file) => file.uploadId !== uploadId);
		this.lessons = [...this.lessons];
		this.emitChanges();
	},

	updateFileByUploadId(lessonId, uploadId, newFile) {
		const lessonIndex = this.lessons.findIndex(
			(lesson) => lesson.id === lessonId
		);

		if (lessonIndex === -1) {
			return;
		}

		const fileIndex = this.lessons[lessonIndex].lessonDocuments.findIndex(
			(file) => file.uploadId === uploadId
		);

		if (lessonIndex === -1) {
			return;
		}

		this.lessons[lessonIndex].lessonDocuments[fileIndex] = newFile;
		this.lessons = [...this.lessons];
		this.emitChanges();
	},

	updateUploadedFileByUploadId(lessonId, uploadId, newFile) {
		const lessonIndex = this.lessons.findIndex(
			(lesson) => lesson.id === lessonId
		);

		if (lessonIndex === -1) {
			return;
		}

		const fileIndex = this.lessons[lessonIndex].lessonDocuments.findIndex(
			(file) => file.uploadId === uploadId
		);

		if (lessonIndex === -1) {
			return;
		}

		this.lessons[lessonIndex].lessonDocuments[fileIndex] = newFile;
		this.lessons[lessonIndex].isOk = checkLessonInfo(this.lessons[lessonIndex]);
		this.lessons = [...this.lessons];
		this.emitChanges();
	},

	clearData() {
		this.lessons = [];
		this.emitChanges();
	},

	/**
	 * Send new lessons data to all listeners
	 */
	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

const checkLessonInfo = (lesson) => {
	if (lesson.type === lessonType.ONLINE) {
		if (!lesson.title.trim()) {
			return false;
		}

		if (!lesson.description.trim()) {
			return false;
		}

		if (!lesson.teachingTime) {
			return false;
		}

		if (!lesson.totalCost) {
			return false;
		}

		const video = lesson.lessonDocuments.find(
			(document) => document.documentType === "FILM"
		);

		if (!video) {
			return false;
		}

		return true;
	}

	if (lesson.type === lessonType.ONSITE) {
		if (!lesson.title.trim()) {
			return false;
		}

		if (!lesson.description.trim()) {
			return false;
		}

		if (!lesson.teachingTime) {
			return false;
		}

		if (!lesson.flightTime) {
			return false;
		}

		if (!lesson.airplane) {
			return false;
		}

		if (!lesson.totalCost) {
			return false;
		}

		return true;
	}

	return false;
};

const checkAviationStoryInfo = () => {
	if (!MyCourseInstructorService.savedCourse) {
		return false;
	}

	const files = MyCourseDocumentsInstructorService.mediaFiles;

	let countImage = 0;
	let countVideo = 0;

	for (let i = 0; i < files.length; i++) {
		if (files[i].contentType.startsWith("image")) {
			countImage++;
		} else if (files[i].contentType.startsWith("video")) {
			countVideo++;
		} else {
			return false;
		}
	}

	if (
		countImage > MAX_NUMBER_OF_COURSE_DOCUMENT_IMAGE ||
		countImage < MIN_NUMBER_OF_COURSE_DOCUMENT_IMAGE
	) {
		return false;
	}

	if (
		countVideo > MAX_NUMBER_OF_COURSE_DOCUMENT_VIDEO ||
		countVideo < MIN_NUMBER_OF_COURSE_DOCUMENT_VIDEO
	) {
		return false;
	}

	return true;
};

const checkBasicInfo = () => {
	const course = MyCourseInstructorService.savedCourse;

	if (!course) {
		return false;
	}

	if (!course.name.trim()) {
		return false;
	}

	if (!course.description.trim()) {
		return false;
	}

	if (!course.instructor.trim()) {
		return false;
	}

	if (course.type === courseType.ONSITE && !course.location) {
		return false;
	}

	return true;
};

const checkLessonsInfo = () => {
	if (!MyCourseInstructorService.savedCourse) {
		return false;
	}

	if (MyCourseLessonsInstructorService.lessons.length === 0) {
		return false;
	}

	for (let i = 0; i < MyCourseLessonsInstructorService.lessons.length; i++) {
		if (!checkLessonInfo(MyCourseLessonsInstructorService.lessons[i])) {
			return false;
		}
	}

	return true;
};

const checkCourseInfo = () => {
	if (!MyCourseInstructorService.savedCourse) {
		return false;
	}

	if (!checkAviationStoryInfo()) {
		return false;
	}

	if (!checkBasicInfo()) {
		return false;
	}

	if (!checkLessonsInfo()) {
		return false;
	}

	return true;
};

/**
 * This hook should call only once
 */
export function useMyCourseInstructorInit() {
	const params = useParams();
	const slugOrUuid = params.slugOrUuid ?? "";

	const [isNotFound, setIsNotFound] = useState(false);

	const getCourseDocuments = async (courseId) => {
		try {
			const response = await callApi({
				method: "GET",
				url: apis.COURSE_DOCUMENTS_BY_COURSE_ID,
				urlParams: {
					courseId,
				},
			});

			if (!response?.media) {
				return;
			}

			MyCourseDocumentsInstructorService.saveFiles(response.media);
		} catch (error) {}
	};

	const getCourseLessons = async (courseId) => {
		try {
			const response = await callApi({
				method: "GET",
				url: `${apis.GET_LESSONS_BY_COURSE}/${courseId}`,
			});

			if (!response?.lessons) {
				return;
			}

			const lessons = response.lessons.map((lesson) => ({
				...lesson,
				isOk: checkLessonInfo(lesson),
			}));

			MyCourseLessonsInstructorService.saveLessons(lessons);
		} catch (error) {}
	};

	/**
	 * Get course by uuid
	 * @param {string} uuid
	 */
	const getCourseByUuid = async (uuid) => {
		try {
			const response = await callApi({
				method: "GET",
				url: `${apis.MY_COURSES}/${uuid}`,
			});

			if (!response?.course) {
				return;
			}

			MyCourseInstructorService.saveCourse(response.course);
			getCourseDocuments(response.course.id);
			getCourseLessons(response.course.id);
		} catch (error) {
			if (error?.response?.status === 404) {
				setIsNotFound(true);
			}
		}
	};

	/**
	 * Get course by slug
	 * @param {string} slug
	 */
	const getCourseBySlug = async (slug) => {
		try {
			const response = await callApi({
				method: "GET",
				url: `${apis.MY_COURSES_BY_SLUG}/${slug}`,
			});

			if (!response?.course) {
				return;
			}

			MyCourseInstructorService.saveCourse(response.course);
			getCourseDocuments(response.course.id);
			getCourseLessons(response.course.id);
		} catch (error) {
			if (error?.response?.status === 404) {
				setIsNotFound(true);
			}
		}
	};

	useEffect(() => {
		const isUuidV4 = validators.uuidV4.test(slugOrUuid);
		if (isUuidV4) {
			getCourseByUuid(slugOrUuid);
		} else {
			getCourseBySlug(slugOrUuid);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slugOrUuid]);

	useEffect(() => {
		return () => {
			MyCourseInstructorService.clearData();
			MyCourseDocumentsInstructorService.clearData();
			MyCourseLessonsInstructorService.clearData();
		};
	}, []);

	return { isNotFound, slugOrUuid };
}

export function useMyCourseInstructor() {
	// NOTE that course can be null so you have to check null
	const [course, setCourse] = useState(MyCourseInstructorService.savedCourse);

	useEffect(() => {
		const updateCourse = () => {
			setCourse(MyCourseInstructorService.savedCourse);
		};

		MyCourseInstructorService.addEventListener(updateCourse);

		return () => {
			MyCourseInstructorService.removeEventListener(updateCourse);
		};
	}, []);

	const getCourseDocuments = async (courseId) => {
		try {
			const response = await callApi({
				method: "GET",
				url: apis.COURSE_DOCUMENTS_BY_COURSE_ID,
				urlParams: {
					courseId,
				},
			});

			if (!response?.media) {
				return;
			}

			MyCourseDocumentsInstructorService.saveFiles(response.media);
		} catch (error) {}
	};

	const getCourseLessons = async (courseId) => {
		try {
			const response = await callApi({
				method: "GET",
				url: `${apis.GET_LESSONS_BY_COURSE}/${courseId}`,
			});

			if (!response?.lessons) {
				return;
			}

			const lessons = response.lessons.map((lesson) => ({
				...lesson,
				isOk: checkLessonInfo(lesson),
			}));

			MyCourseLessonsInstructorService.saveLessons(lessons);
		} catch (error) {}
	};

	/**
	 * Get course by slug
	 * @param {string} slug
	 */
	const getCourseBySlug = async (slug) => {
		try {
			const response = await callApi({
				method: "GET",
				url: `${apis.MY_COURSES_BY_SLUG}/${slug}`,
			});

			if (!response?.course) {
				return;
			}

			MyCourseInstructorService.saveCourse(response.course);
		} catch (error) {}
	};

	const updateCourseBasicInformation = async (fieldsToUpdate) => {
		if (!MyCourseInstructorService.savedCourse) {
			return;
		}
		try {
			const response = await callApi({
				method: "PATCH",
				url: `${apis.COURSES}/${MyCourseInstructorService.savedCourse.id}`,
				body: fieldsToUpdate,
			});

			if (response) {
				MyCourseInstructorService.saveCourse({
					...MyCourseInstructorService.savedCourse,
					...fieldsToUpdate,
				});
				toast.success("Your course is up-to-date. Make learning awesome!");
			}
		} catch (error) {}
	};

	const changeCourseVisibility = async (newStatus) => {
		if (newStatus === MyCourseInstructorService.savedCourse.status) {
			return;
		}

		if (newStatus === courseStatus.FLYING) {
			if (!checkCourseInfo()) {
				toast.error(
					'Please fill all required fields to set your course as "Flying"!'
				);
				return;
			}
		}

		try {
			await callApi({
				method: "PATCH",
				url: `${apis.COURSE_STATUS}/${MyCourseInstructorService.savedCourse.id}`,
				body: {
					status: newStatus,
				},
			});
			MyCourseInstructorService.saveCourse({
				...MyCourseInstructorService.savedCourse,
				status: newStatus,
			});
		} catch (error) {
			getCourseBySlug(MyCourseInstructorService.savedCourse.courseSlug);
			getCourseLessons(MyCourseInstructorService.savedCourse.id);
			getCourseDocuments(MyCourseInstructorService.savedCourse.id);
			toast.error("Something went wrong, please try again!");
		}
	};

	return {
		course,
		updateCourseBasicInformation,
		changeCourseVisibility,
	};
}

export function useMyCourseDocumentsInstructor() {
	const [mediaFiles, setMediaFiles] = useState(
		MyCourseDocumentsInstructorService.mediaFiles
	);

	const { account } = useAccount();

	useEffect(() => {
		const updateMediaFiles = () => {
			setMediaFiles(MyCourseDocumentsInstructorService.mediaFiles);
		};

		MyCourseDocumentsInstructorService.addEventListener(updateMediaFiles);

		return () => {
			MyCourseDocumentsInstructorService.removeEventListener(updateMediaFiles);
		};
	}, []);

	/**
	 * Upload file
	 * @param {File} file
	 */
	const addFile = async (file) => {
		if (!account || !MyCourseInstructorService.savedCourse) {
			return;
		}

		const uploadId = uuid.v4();

		try {
			if (file.type.startsWith("image")) {
				if (
					mediaFiles.filter((file) => file.contentType.startsWith("image"))
						.length >= MAX_NUMBER_OF_COURSE_DOCUMENT_IMAGE
				) {
					return;
				}
			} else if (file.type.startsWith("video")) {
				if (
					mediaFiles.filter((file) => file.contentType.startsWith("video"))
						.length >= MAX_NUMBER_OF_COURSE_DOCUMENT_VIDEO
				) {
					return;
				}
			}

			const getSignedURLResponse = await callApi({
				method: "POST",
				url: apis.GET_SIGNED_URL,
				body: {
					fileName: file.name,
					docType: "courses",
					contentType: file.type,
					userId: account.userId,
				},
			});

			if (!getSignedURLResponse) {
				return;
			}

			const { signedURL, location } = getSignedURLResponse;
			MyCourseDocumentsInstructorService.addNewFile({
				uploadId,
				progress: 0,
				contentType: file.type,
			});

			await callApi({
				method: "PUT",
				baseURL: "",
				url: signedURL,
				body: file,
				onUploadProgress: ({ loaded, total }) => {
					MyCourseDocumentsInstructorService.updateFileByUploadId(uploadId, {
						uploadId,
						progress: Math.round((100 * loaded) / total),
						contentType: file.type,
					});
				},
			});

			let documentType;
			if (file.type.startsWith("image")) {
				documentType = "COURSE_IMG";
			} else if (file.type.startsWith("video")) {
				documentType = "TRAILER";
			}

			const response = await callApi({
				method: "POST",
				url: apis.COURSE_DOCUMENTS,
				body: {
					courseId: MyCourseInstructorService.savedCourse.id,
					fileName: file.name,
					contentType: file.type,
					location,
					documentType,
				},
			});

			if (response?.doc) {
				MyCourseDocumentsInstructorService.updateFileByUploadId(
					uploadId,
					response.doc
				);
			}
		} catch (error) {
			MyCourseDocumentsInstructorService.deleteFileByUploadId(uploadId);
		} finally {
		}
	};

	const removeFile = async (id) => {
		try {
			callApi({
				method: "DELETE",
				url: `${apis.COURSE_DOCUMENTS}/${id}`,
			});
			MyCourseDocumentsInstructorService.removeFile(id);
		} catch (error) {}
	};

	const getCourseDocuments = async () => {
		if (!MyCourseInstructorService.savedCourse) {
			return;
		}

		try {
			const response = await callApi({
				method: "GET",
				url: `${apis.COURSE_DOCUMENTS}?id=${MyCourseInstructorService.savedCourse.id}`,
			});

			if (!response?.media) {
				return;
			}

			MyCourseDocumentsInstructorService.saveFiles(response.media);
		} catch (error) {}
	};

	const reorderFiles = (newMediaFiles) => {
		if (!MyCourseInstructorService.savedCourse) {
			return;
		}

		const prevOrder = [...mediaFiles];
		MyCourseDocumentsInstructorService.saveFiles(newMediaFiles);

		try {
			callApi({
				method: "POST",
				url: apis.COURSE_REORDER_DOCUMENTS,
				body: {
					courseId: MyCourseInstructorService.savedCourse.id,
					newDocumentsOrder: newMediaFiles.map((file) => file.id),
				},
			});
		} catch (error) {
			MyCourseDocumentsInstructorService.saveFiles(prevOrder);
			if (error?.response?.status === 409) {
				getCourseDocuments();
			}
		}
	};

	return {
		mediaFiles,
		addFile,
		removeFile,
		reorderFiles,
	};
}

export function useMyCourseLessonsInstructor() {
	const [lessons, setLessons] = useState(
		MyCourseLessonsInstructorService.lessons
	);

	const { account } = useAccount();

	useEffect(() => {
		const updateLessons = () => {
			setLessons(MyCourseLessonsInstructorService.lessons);
		};

		MyCourseLessonsInstructorService.addEventListener(updateLessons);

		return () => {
			MyCourseLessonsInstructorService.removeEventListener(updateLessons);
		};
	}, []);

	const setFlyingCourseToGrounded = () => {
		if (!MyCourseInstructorService.savedCourse) {
			return;
		}

		const currentCourseStatus = MyCourseInstructorService.savedCourse.status;
		if (currentCourseStatus === courseStatus.FLYING) {
			MyCourseInstructorService.saveCourse({
				...MyCourseInstructorService.savedCourse,
				status: courseStatus.GROUNDED,
			});
		}
	};

	const createNewLesson = async (title) => {
		if (!MyCourseInstructorService.savedCourse) {
			return;
		}

		try {
			const response = await callApi({
				method: "POST",
				url: apis.LESSONS,
				body: {
					courseId: MyCourseInstructorService.savedCourse.id,
					title,
					type: MyCourseInstructorService.savedCourse.type,
				},
			});

			if (!response?.lesson) {
				return;
			}

			MyCourseLessonsInstructorService.addNewLesson({
				...response.lesson,
				isOk: false,
				lessonDocuments: [],
			});

			setFlyingCourseToGrounded();
		} catch (error) {}
	};

	const deleteLesson = async (lessonId) => {
		try {
			callApi({
				method: "DELETE",
				url: `${apis.LESSONS}/${lessonId}`,
			});
			MyCourseLessonsInstructorService.deleteLesson(lessonId);

			setFlyingCourseToGrounded();
		} catch (error) {}
	};

	/**
	 * Upload file
	 * @param {File} file
	 */
	const addDocument = async (lessonId, file, documentType) => {
		if (!account || !MyCourseInstructorService.savedCourse) {
			return;
		}

		const uploadId = uuid.v4();

		try {
			const getSignedURLResponse = await callApi({
				method: "POST",
				url: apis.GET_SIGNED_URL,
				body: {
					fileName: file.name,
					docType: "lessons",
					contentType: file.type,
					userId: account.userId,
				},
			});

			if (!getSignedURLResponse) {
				return;
			}

			const { signedURL, location } = getSignedURLResponse;
			MyCourseLessonsInstructorService.addLessonFile(lessonId, {
				uploadId,
				progress: 0,
				name: file.name,
				documentType,
			});

			await callApi({
				method: "PUT",
				baseURL: "",
				url: signedURL,
				body: file,
				onUploadProgress: ({ loaded, total }) => {
					MyCourseLessonsInstructorService.updateFileByUploadId(
						lessonId,
						uploadId,
						{
							uploadId,
							progress: Math.round((100 * loaded) / total),
							name: file.name,
							documentType,
						}
					);
				},
			});

			const response = await callApi({
				method: "POST",
				url: apis.LESSONS_DOCUMENTS,
				urlParams: {
					lessonId,
				},
				body: {
					fileName: file.name,
					contentType: file.type,
					location,
					documentType,
				},
			});

			if (!response?.doc) {
				return;
			}

			MyCourseLessonsInstructorService.updateUploadedFileByUploadId(
				lessonId,
				uploadId,
				response.doc
			);

			setFlyingCourseToGrounded();
		} catch (error) {
			MyCourseLessonsInstructorService.deleteFileByUploadId(lessonId, uploadId);
		}
	};

	const removeDocument = (lessonId, fileId) => {
		try {
			callApi({
				method: "DELETE",
				url: `${apis.LESSONS_DOCUMENTS}/${fileId}`,
				urlParams: {
					lessonId,
				},
			});
			MyCourseLessonsInstructorService.deleteLessonFile(lessonId, fileId);

			setFlyingCourseToGrounded();
		} catch (error) {}
	};

	const updateLessonInformation = async (lessonId, newData) => {
		try {
			const response = await callApi({
				method: "PATCH",
				url: `${apis.LESSONS}/${lessonId}`,
				body: newData,
			});

			if (!response?.lesson) {
				return;
			}

			const index = MyCourseLessonsInstructorService.lessons.findIndex(
				(lesson) => lesson.id === lessonId
			);

			if (index === -1) {
				return;
			}

			const newCourseData = {
				...MyCourseLessonsInstructorService.lessons[index],
				...response.lesson,
			};

			MyCourseLessonsInstructorService.updateLesson(lessonId, {
				...newCourseData,
				isOk: checkLessonInfo(newCourseData),
			});

			setFlyingCourseToGrounded();
		} catch (error) {
			if (error?.response?.status === 404) {
				MyCourseLessonsInstructorService.deleteLesson(lessonId);
			}
		}
	};

	return {
		lessons,
		createNewLesson,
		deleteLesson,
		addDocument,
		removeDocument,
		updateLessonInformation,
	};
}
