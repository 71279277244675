import React from "react";

import useScreenSize from "../../../hooks/useScreenSize";
import Help from "../navegacion/help";
import NavbarOrka from "../navegacion/Navbar";
import AccountInfo from "./AccountInfo";
import MenuProfileMobil from "./MenuProfileMobil";

export default function AccountInformationPageInstructor() {
	const { width } = useScreenSize();
	const isMobile = width <= 768;

	return (
		<div className="personal-detail">
			<NavbarOrka />

			{isMobile ? (
				<div className="container-profile-contents">
					<MenuProfileMobil active="Profile" />
				</div>
			) : (
				<div className="container-generic">
					<AccountInfo />
				</div>
			)}

			<div className="box-help-information">
				<Help />
			</div>
		</div>
	);
}
