import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export function CourseItem({ course }) {
	const navigate = useNavigate();

	const onClickGoToSyllabus = () => {
		navigate(`/my-courses/${course.courseSlug}`);
	};

	if (!course) {
		return null;
	}

	return (
		<Accordion className="course-item">
			<AccordionSummary className="course-item-summary">
				<Typography component="p" className="course-title">
					{course.name}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<div className="course-information-container">
					<Typography component="p" className="course-information">
						{`${course.hours ?? 0} Hours`}
					</Typography>
					<Typography component="p" className="course-information">
						{course.type}
					</Typography>
					<Typography component="p" className="course-information">
						{`${course.bought} Students`}
					</Typography>
				</div>
				<div className="course-information-container">
					<Typography component="p" className="course-information">
						{course.status}
					</Typography>
					<Typography
						component="p"
						onClick={onClickGoToSyllabus}
						className="view-syllabus-button"
					>
						View Syllabus
					</Typography>
				</div>
			</AccordionDetails>
		</Accordion>
	);
}
