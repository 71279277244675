import React, { useRef } from "react";

import { Button, Grid, IconButton, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

import { apis, callApi } from "../../../api/index.js";
import { roles } from "../../../constants/index.js";
import { AccountService } from "../../../hooks/useAccount.js";
import useScreenSize from "../../../hooks/useScreenSize.js";
import { StripeService } from "../../../hooks/useStripe.js";
import { TokenService } from "../../../hooks/useToken.js";
import { rules } from "../../../utils/index.js";
import { FileViewer } from "../../common/FileViewer/FileViewer.js";
import { CommonCheckbox, CommonInput } from "../../common/index.js";
import NavbarOrka from "../navegacion/Navbar.js";

import "./create_account.scss";

export function CreateAccount({ role }) {
	const navigate = useNavigate();
	const { width } = useScreenSize();
	const isMobile = width <= 768;

	const fileViewerRef = useRef(null);

	const defaultValues = {
		firstName: "",
		lastName: "",
		middleName: "",
		nickname: "",
		email: "",
		phoneNumber: "",
		password: "",
		confirmPassword: "",
		acceptTermsAndConditions: false,
	};

	const { handleSubmit, control } = useForm({
		defaultValues,
		mode: "all",
	});

	const initStripe = async (user, token) => {
		try {
			const stripeResponse = await callApi({
				method: "POST",
				url: apis.LINK_ACCOUNT,
				accessToken: token,
				body: {
					userCode: user.userId,
				},
			});

			StripeService.saveStripeData(stripeResponse.account);
			return true;
		} catch (error) {
			return false;
		}
	};

	const onClickCreateNewAccount = async (data) => {
		const {
			firstName,
			lastName,
			middleName,
			nickname,
			email,
			phoneNumber,
			password,
		} = data;

		try {
			const body = {
				firstName,
				lastName,
				middleName,
				nickName: nickname,
				email,
				phoneNumber,
				password,
			};
			if (role === "student") {
				body.role = roles.STUDENT;
			} else if (role === "instructor") {
				body.role = roles.INSTRUCTOR;
			}

			const response = await callApi({
				method: "POST",
				url: apis.REGISTER,
				body,
			});
			const { token, user } = response;

			if (role === "instructor" && user.requiredKYC) {
				const isSuccess = await initStripe(user, token);
				TokenService.saveToken(token);
				AccountService.saveAccount(user);
				if (isSuccess) {
					navigate("/stripe", {
						state: {
							nextRoute: "/what-can-i-do",
						},
					});
				} else {
					navigate("/what-can-i-do");
				}
			} else {
				TokenService.saveToken(token);
				AccountService.saveAccount(user);
				navigate("/what-can-i-do");
			}
		} catch (error) {
			if (error?.response?.data?.errors?.length > 0) {
				alert(error?.response?.data?.errors[0]?.msg);
			}
		}
	};

	return (
		<div style={{ height: "100vh" }}>
			<NavbarOrka isHiThere isInHome />
			<div className="create-account-container">
				<div className="create-account-content-container">
					<Grid container className="create-account-box">
						{!isMobile && (
							<Grid
								item
								xs={12}
								sm={12}
								md={3}
								lg={3}
								xl={3}
								className="left-column"
							>
								<Typography className="sign-up-text">SIGN UP</Typography>
							</Grid>
						)}

						<Grid
							item
							xs={12}
							sm={12}
							md={9}
							lg={9}
							xl={9}
							className="right-column"
						>
							<IconButton
								className="back-icon"
								onClick={() => {
									navigate("/sign-up");
								}}
							>
								<FaChevronLeft className="normal-icon" />
							</IconButton>
							<Typography className="header-profile-text">
								{role.toUpperCase()} PROFILE
							</Typography>
							<Typography className="normal-text create-account-description-text">
								Let's create your new {role} account. Tell us a little bit about
								yourself:
							</Typography>
							<form
								className="sign-up-form"
								onSubmit={handleSubmit(onClickCreateNewAccount)}
							>
								<Grid container spacing={2} style={{ marginTop: 16 }}>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<CommonInput
											control={control}
											name="firstName"
											placeholder="First name*"
											rules={{
												required: {
													value: true,
													message: "First name is required.",
												},
												minLength: rules.firstName.min,
												maxLength: rules.firstName.max,
											}}
										/>
									</Grid>

									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<CommonInput
											control={control}
											name="lastName"
											placeholder="Last name*"
											rules={{
												required: {
													value: true,
													message: "Last name is required.",
												},
												minLength: rules.lastName.min,
												maxLength: rules.lastName.max,
											}}
										/>
									</Grid>

									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<CommonInput
											control={control}
											name="middleName"
											placeholder="Middle name"
											rules={{
												minLength: rules.middleName.min,
												maxLength: rules.middleName.max,
											}}
										/>
									</Grid>

									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<CommonInput
											control={control}
											name="nickname"
											placeholder="Nickname"
											rules={{
												minLength: rules.nickname.min,
												maxLength: rules.nickname.max,
											}}
										/>
									</Grid>
								</Grid>

								<Grid container spacing={2} style={{ marginTop: 32 }}>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<CommonInput
											control={control}
											name="email"
											type="email"
											placeholder="Your email*"
											rules={{
												required: {
													value: true,
													message: "Email is required.",
												},
												pattern: rules.email.pattern,
											}}
										/>
									</Grid>

									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<CommonInput
											control={control}
											name="phoneNumber"
											type="tel"
											placeholder="Your phone number*"
											rules={{
												required: {
													value: true,
													message: "Phone number is required.",
												},
												pattern: rules.phoneNumber.pattern,
											}}
										/>
									</Grid>

									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<CommonInput
											control={control}
											name="password"
											type="password"
											placeholder="Password*"
											rules={{
												required: {
													value: true,
													message: "Password is required.",
												},
												pattern: rules.password.pattern,
											}}
										/>
									</Grid>

									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<CommonInput
											control={control}
											name="confirmPassword"
											type="password"
											placeholder="Confirm password*"
											rules={{
												required: {
													value: true,
													message: "Confirm password is required.",
												},
												pattern: rules.password.pattern,
												validate: (value, formValues) => {
													if (value === formValues.password) {
														// It must return undefined; or return; here
														return;
													}
													return "Confirm password is not match.";
												},
											}}
										/>
									</Grid>

									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<CommonCheckbox
											control={control}
											name="acceptTermsAndConditions"
											rules={{
												required: {
													value: true,
													message: (
														<p>
															You have to accept <b>Terms & Conditions</b>
														</p>
													),
												},
											}}
											label={
												<label style={{ fontSize: "12px", color: "#989898" }}>
													I have read and accepted the{" "}
													<span
														onClick={() => {
															fileViewerRef.current?.open({
																url: "https://flyorka-dev-pub.s3.amazonaws.com/flyorka-docs/Terms+of+use+and+service.pdf",
															});
														}}
														style={{
															fontWeight: "bold",
															textDecoration: "none",
															cursor: "pointer",
														}}
													>
														Terms & Conditions<sup>*</sup>
													</span>
												</label>
											}
										/>
									</Grid>

									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<div
											style={{ display: "flex", justifyContent: "flex-end" }}
										>
											<Button
												className="normal-button create-account-button"
												variant="contained"
												type="submit"
											>
												Continue
											</Button>
										</div>
									</Grid>
								</Grid>
							</form>
							<Typography className="normal-text note">
								All sections with * must be filled
							</Typography>
						</Grid>
					</Grid>
				</div>
			</div>
			<FileViewer ref={fileViewerRef} />
		</div>
	);
}
