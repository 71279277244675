import { useEffect } from "react";

import { Button, Grid, Typography } from "@mui/material";
import classNames from "classnames";
import { useForm, useWatch } from "react-hook-form";

import { useInstructorSummaries } from "../../../../../../hooks/useInstructorSummaries";
import { formatTimeRange, validators } from "../../../../../../utils";
import { SummaryInfoInput } from "./SummaryInfoInput";

function PassedBookingSummaryButtons({ control, onClickCancel, booking }) {
	const { airHours, simulatorHours, milesFlown, flightSummary } = useWatch({
		control,
	});

	const calculateIsDisabled = () => {
		if (Number(airHours) !== booking.airHours) {
			return false;
		}

		if (Number(simulatorHours) !== booking.simulatorHours) {
			return false;
		}

		if (Number(milesFlown) !== booking.milesFlown) {
			return false;
		}

		if (flightSummary.trim() !== booking.flightSummary.trim()) {
			return false;
		}

		return true;
	};

	const isDisabled = calculateIsDisabled();

	return (
		<div className="summary-buttons-container">
			<Button
				variant="contained"
				className="summary-button normal-button disabled-button"
				onClick={onClickCancel}
			>
				Cancel
			</Button>
			<Button
				variant="contained"
				className={classNames("summary-button normal-button", {
					"primary-button": !isDisabled,
					"disabled-button": isDisabled,
				})}
				type="submit"
				disabled={isDisabled}
			>
				Save
			</Button>
		</div>
	);
}

export function PassedBookingSummaryItem({ booking }) {
	const { control, handleSubmit, setValue } = useForm({
		defaultValues: {
			airHours: 0,
			simulatorHours: 0,
			milesFlown: 0,
			flightSummary: "",
		},
	});
	const { updateBooking } = useInstructorSummaries();

	useEffect(() => {
		setValue("airHours", booking.airHours);
		setValue("simulatorHours", booking.simulatorHours);
		setValue("milesFlown", booking.milesFlown);
		setValue("flightSummary", booking.flightSummary);
	}, [
		booking.airHours,
		booking.flightSummary,
		booking.milesFlown,
		booking.simulatorHours,
		setValue,
	]);

	const onClickSaveSummary = (data) => {
		const { airHours, simulatorHours, milesFlown, flightSummary } = data;
		updateBooking(booking.summaryId, booking.id, {
			airHours: Number(airHours),
			simulatorHours: Number(simulatorHours),
			milesFlown: Number(milesFlown),
			flightSummary,
		});
	};

	let dateText = "";
	let timeText = "";
	if (booking.startTime && booking.endTime) {
		const calculateResult = formatTimeRange(booking.startTime, booking.endTime);
		dateText = calculateResult.dateText;
		timeText = calculateResult.timeText;
	}

	return (
		<form onSubmit={handleSubmit(onClickSaveSummary)}>
			<div className="student-booking-item">
				<Typography component="p" className="lesson-name">
					{booking.lesson.title}
				</Typography>
				<div className="date-time-container">
					{dateText && (
						<Typography className="normal-text">{`Date: ${dateText}`}</Typography>
					)}
					{timeText && (
						<Typography className="normal-text">{`Time: ${timeText}`}</Typography>
					)}
				</div>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<SummaryInfoInput
							control={control}
							label="Air Hours"
							placeholder="Air Hours"
							name="airHours"
							rules={{
								pattern: {
									value: validators.time,
								},
							}}
							disallowInvalidInput
							suffix="hrs"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<SummaryInfoInput
							control={control}
							label="Simulator Hours"
							placeholder="Simulator Hours"
							name="simulatorHours"
							rules={{
								pattern: {
									value: validators.time,
								},
							}}
							disallowInvalidInput
							suffix="hrs"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<SummaryInfoInput
							control={control}
							label="Miles Flown"
							placeholder="Miles Flown"
							name="milesFlown"
							rules={{
								pattern: {
									value: validators.time,
								},
							}}
							disallowInvalidInput
							suffix="mi"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<SummaryInfoInput
							control={control}
							label="Follow-up"
							placeholder="Students love feedback, tips, and personal assignments. What can you pass on to the student to accelerate learning?"
							name="flightSummary"
							multiline
						/>
					</Grid>
				</Grid>
				<PassedBookingSummaryButtons control={control} booking={booking} />
			</div>
		</form>
	);
}
