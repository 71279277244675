import { Typography } from "@mui/material";

import { usePreviewCourse } from "../../hooks/usePreviewCourse";
import { CourseLesson } from "./CourseLesson";

export function CourseLessons() {
	const { course } = usePreviewCourse();

	if (!course) {
		return null;
	}

	return (
		<div className="course-lessons-container">
			<Typography component="p" className="yellow-label">
				CLASSES
			</Typography>

			{course.lesson.map((lesson) => {
				return (
					<CourseLesson lesson={lesson} key={lesson.id} />
				);
			})}
		</div>
	);
}
