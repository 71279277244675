import { Typography } from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";

import { usePreviewCourse } from "../../hooks/usePreviewCourse";

export function CourseLocation() {
	const { course } = usePreviewCourse();

	const renderMap = () => {
		if (!course || course.latitude === null || course.longitude === null) {
			return null;
		}

		return (
			<GoogleMap
				zoom={10}
				center={{lat: course.latitude, lng: course.longitude}}
				mapContainerClassName={"map"}
			>
				<Marker position={{lat: course.latitude, lng: course.longitude}} />
			</GoogleMap>
		);
	};

	if (!course) {
		return null;
	}

	return (
		<>
			<Typography component="p" className="yellow-label">
				LOCATION
			</Typography>
			<Typography component="p" className="normal-text">
				{course.location}
			</Typography>
			{renderMap()}
		</>
	);
}
