import { useLoadScript } from "@react-google-maps/api";

const libraries = ["places"];

export function useGoogleMap() {
	const { isLoaded } = useLoadScript({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		libraries,
	});

	return { isLoaded };
}
