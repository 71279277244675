import { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import { IconButton, Modal, Tooltip } from "@mui/material";
import { IoClose } from "react-icons/io5";
// Don't remove ReactZoomPanPinchRef
import {
	// eslint-disable-next-line no-unused-vars
	ReactZoomPanPinchRef,
	TransformComponent,
	TransformWrapper
} from "react-zoom-pan-pinch";

import "./image_viewer.scss";

export const ImageViewer = forwardRef((props, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const [scale, setScale] = useState(1);
	const [url, setUrl] = useState(null);

	const open = useCallback(({ url: imageUrl }) => {
		setUrl(imageUrl);
		setIsOpen(true);
	}, []);

	const close = () => {
		setIsOpen(false);
	};

	useImperativeHandle(ref, () => ({ open, close }), [open]);

	const onClose = (event, reason) => {
		if (reason === "escapeKeyDown") {
			close();
		}
	};

	/**
	 *
	 * @param {ReactZoomPanPinchRef} ref
	 * @param {MouseEvent | TouchEvent} event
	 */
	const onZoomStop = (ref, event) => {
		setScale(Math.round(ref.state.scale));
	};

	const renderMedia = () => {
		return (
			<TransformWrapper
				minScale={1}
				maxScale={5}
				onZoomStop={onZoomStop}
				smooth={false}
				doubleClick={{ step: 2, mode: scale === 5 ? "reset" : "zoomIn" }}
				key={url}
			>
				<TransformComponent
					wrapperClass="transform-wrapper"
					contentClass="image-container"
				>
					<img src={url} className="image" alt={url} />
				</TransformComponent>
			</TransformWrapper>
		);
	};

	return (
		<Modal open={isOpen} onClose={onClose}>
			<div className="view-media-modal-container">
				<Tooltip title="Close">
					<IconButton
						className="close-button"
						onClick={close}
					>
						<IoClose />
					</IconButton>
				</Tooltip>
				{renderMedia()}
			</div>
		</Modal>
	);
});
