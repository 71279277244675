import React from "react";

import useScreenSize from "../../../hooks/useScreenSize";
import MenuProfileMobil from "../Accont/MenuProfileMobil";
import Help from "../navegacion/help";
import NavbarOrka from "../navegacion/Navbar";
import ContentSafetyInstructor from "./ContentSafetyInstructor";


export default function SafetyInstructor() {
	const { width } = useScreenSize();
	const isMobile = width <= 768;

	return (
		<div className="personal-detail">
			<NavbarOrka />

			{isMobile ? (
				<div className="container-profile-contents">
					<MenuProfileMobil active="Safety" />
				</div>
			) : (
				<div className="container-generic">
					<ContentSafetyInstructor />
				</div>
			)}

			<div className="box-help-information">
				<Help />
			</div>
		</div>
	);
}
