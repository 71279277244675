import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apis, callApi } from "../../../../../api";
import { courseType } from "../../../../../constants";

const CheckoutCourseService = {
	listeners: [],
	savedCourse: null,

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	saveCourse(newCourse) {
		this.savedCourse = newCourse;
		this.emitChanges();
	},

	clearData() {
		this.savedCourse = null;
		this.emitChanges();
	},

	/**
	 * Send new course data to all listeners
	 */
	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

const BookingCourseService = {
	listeners: [],
	availableHours: [],
	bookings: [],

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	initData(newAvailableHours, newBookings) {
		this.availableHours = newAvailableHours;
		this.bookings = newBookings;
		this.emitChanges();
	},

	clearData() {
		this.availableHours = [];
		this.booking = [];
		this.emitChanges();
	},

	/**
	 * Send new course data to all listeners
	 */
	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

const OwnedLessonsService = {
	listeners: [],
	ownedLessons: [],
	nextLesson: null,

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	initData(newOwnedLesson, newNextLesson) {
		this.ownedLessons = newOwnedLesson;
		this.nextLesson = newNextLesson;
		this.emitChanges();
	},

	clearData() {
		this.ownedLessons = [];
		this.nextLesson = null;
		this.emitChanges();
	},

	/**
	 * Send new course data to all listeners
	 */
	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

/**
 * This hook should call only once
 */
export function useCheckoutCourseInit() {
	const params = useParams();
	const slug = params.slug ?? "";

	const [isNotFound, setIsNotFound] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const getSchedule = async (teacherId) => {
		try {
			const response = await callApi({
				method: "GET",
				url: `${apis.CLASSES_SCHEDULE}/${teacherId}`,
			});

			if (!response) {
				return;
			}

			const { availableHours, bookings } = response;
			BookingCourseService.initData(availableHours, bookings);
		} catch (error) {}
	};

	const getBoughtLessons = async (courseId) => {
		try {
			const response = await callApi({
				method: "GET",
				url: `${apis.CLASSES_BOUGHT}/${courseId}`,
			});

			if (!response) {
				return;
			}

			const { ownedLessons, nextLesson } = response;
			OwnedLessonsService.initData(ownedLessons, nextLesson);
		} catch (error) {}
	};

	/**
	 * Get course by slug
	 * @param {string} slug
	 */
	const getCourseBySlug = useCallback(async () => {
		try {
			const response = await callApi({
				method: "GET",
				url: `${apis.PREVIEW_COURSE_BY_SLUG}/${slug}`,
			});

			if (!response?.course) {
				return;
			}

			CheckoutCourseService.saveCourse(response.course);

			const promiseGetBoughtLessons = getBoughtLessons(response.course.id);
			if (response.course.type === courseType.ONSITE) {
				const promiseGetSchedule = getSchedule(response.course.teacherId);
				await promiseGetSchedule();
			}
			await promiseGetBoughtLessons;
		} catch (error) {
			if (error?.response?.status === 404) {
				setIsNotFound(true);
			}
		} finally {
			setIsLoading(false);
		}
	}, [slug]);

	useEffect(() => {
		getCourseBySlug();
	}, [getCourseBySlug]);

	useEffect(() => {
		return () => {
			CheckoutCourseService.clearData();
			BookingCourseService.clearData();
			OwnedLessonsService.clearData();
		};
	}, []);

	return { isNotFound, isLoading };
}

export function useCheckoutCourse() {
	const [course, setCourse] = useState(CheckoutCourseService.savedCourse);

	useEffect(() => {
		const updateCourse = () => {
			setCourse(CheckoutCourseService.savedCourse);
		};

		CheckoutCourseService.addEventListener(updateCourse);

		return () => {
			CheckoutCourseService.removeEventListener(updateCourse);
		};
	}, []);

	return { course };
}

export function useOwnedLessons() {
	const [ownedLessons, setOwnedLessons] = useState(
		OwnedLessonsService.ownedLessons
	);
	const [nextLesson, setNextLesson] = useState(OwnedLessonsService.nextLesson);

	useEffect(() => {
		const updateOwnedLessons = () => {
			setOwnedLessons(OwnedLessonsService.ownedLessons);
			setNextLesson(OwnedLessonsService.nextLesson);
		};

		OwnedLessonsService.addEventListener(updateOwnedLessons);

		return () => {
			OwnedLessonsService.removeEventListener(updateOwnedLessons);
		};
	}, []);

	return { ownedLessons, nextLesson };
}

export function useBookingCourse() {
	const [availableHours, setAvailableHours] = useState(
		BookingCourseService.availableHours
	);
	const [bookings, setBookings] = useState(BookingCourseService.bookings);

	useEffect(() => {
		const updateBooking = () => {
			setAvailableHours(BookingCourseService.availableHours);
			setBookings(BookingCourseService.bookings);
		};

		BookingCourseService.addEventListener(updateBooking);

		return () => {
			BookingCourseService.removeEventListener(updateBooking);
		};
	}, []);

	return { availableHours, bookings };
}
