import React from "react";

import { Navigate } from "react-router-dom";

import { useToken } from "../../../hooks/useToken";

export default function GuestGuard({ children }) {
	const { token } = useToken();

	if (token) {
		return <Navigate to="/" />;
	}

	return <>{children}</>;
}
