import { IconButton, Tooltip, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";

import { useMyCourseLessonsInstructor } from "../../hooks/useMyCourseInstructor";
import { IoClose } from "react-icons/io5";

export function CourseLessonVideo({ lesson }) {
	const { addDocument, removeDocument } = useMyCourseLessonsInstructor();

	const handleUploadFile = (files) => {
		addDocument(lesson.id, files[0], "FILM");
	};

	const dropzone = useDropzone({
		noKeyboard: true,
		accept: {
			"video/mp4": [],
			"video/quicktime": [],
		},
		onDropAccepted: handleUploadFile,
		maxFiles: 1,
	});

	const video = lesson.lessonDocuments.find(
		(document) => document.documentType === "FILM"
	);

	const onClickRemoveFile = () => {
		removeDocument(lesson.id, video.id);
	};

	const renderVideo = () => {
		if (!video) {
			return null;
		}

		if (video.uploadId) {
			return (
				<div className="upload-film-container normal-text">
					Uploading... ({video.progress}%)
				</div>
			);
		}

		return (
			<div className="film-container">
				<video className="film" controls>
					<source src={video.url} type="video/mp4" />
				</video>
				<Tooltip title="Remove">
					<IconButton className="icon-button film-remove-button" onClick={onClickRemoveFile}>
						<IoClose />
					</IconButton>
				</Tooltip>
			</div>
		);
	};

	return (
		<>
			<Typography component="p" className="lesson-input-title normal-text">
				FILM*
			</Typography>

			{renderVideo()}

			{!video && (
				<div {...dropzone.getRootProps({ className: "drop-zone" })}>
					<input {...dropzone.getInputProps()} />
					<Typography className="normal-text">
						{"Drag 'n' drop or click to select film. Class film will only be visible to enrolled students."}
					</Typography>
				</div>
			)}
		</>
	);
}
