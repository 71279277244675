import React from "react";
import Carousel from "react-bootstrap/Carousel";

function AboutCarousel({ data }) {
  return (
    <Carousel variant="dark" interval={null}>
      {data.map((item, index) => (
        <Carousel.Item key={index} className="about-carousel-item" >
          <Carousel.Caption>
            <div>
              <p className="about-carousel-comment">{item.comment}</p>              
              <p className="about-carousel-user">{item.user}</p>
              <p className="about-carousel-course">{item.course}</p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default AboutCarousel;
