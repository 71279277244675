import { useEffect, useState } from "react";

import { apis, callApi } from "../../../../../api";
import { InstructorAgendaService } from "../../../../../hooks/useInstructorAgenda";
import { StudentSummariesService } from "../../../../../hooks/useStudentSummaries";

const cacheTime = 10 * 60 * 1000;

export const BookingService = {
	teacherBookings: {},
	teacherAvailableHours: {},
	cacheTimes: {},
	listeners: {},

	addEventListener(teacherId, cb) {
		this.listeners[teacherId] = (this.listeners[teacherId] ?? []).filter(
			(listener) => listener !== cb
		);
		this.listeners[teacherId].push(cb);
	},

	saveTeacherBookingData(teacherId, bookings, availableHours) {
		this.teacherBookings[teacherId] = bookings;
		this.teacherAvailableHours[teacherId] = availableHours;
		this.cacheTimes[teacherId] = Date.now();
		this.emitChanges(teacherId);
	},

	addTeacherBooking(teacherId, booking) {
		if (this.teacherBookings[teacherId]) {
			this.teacherBookings[teacherId].push(booking);
		} else {
			this.teacherBookings[teacherId] = [booking];
		}
		this.emitChanges(teacherId);
	},

	clearData() {
		this.teacherBookings = {};
		this.cacheTimes = {};
		this.emitChanges();
	},

	emitChanges(teacherId) {
		if (!teacherId) {
			for (const id of Object.keys(this.listeners)) {
				for (const listener of this.listeners[id] ?? []) {
					listener();
				}
			}
		}
		for (const listener of this.listeners[teacherId] ?? []) {
			listener();
		}
	},

	removeEventListener(teacherId, cb) {
		if (this.listeners[teacherId]) {
			this.listeners[teacherId] = this.listeners[teacherId].filter(
				(listener) => listener !== cb
			);
		}
	},
};

export function useBookingInit(teacherId) {
	useEffect(() => {
		const getSchedule = async () => {
			try {
				const response = await callApi({
					method: "GET",
					url: `${apis.CLASSES_SCHEDULE}/${teacherId}`,
				});

				if (!response) {
					return;
				}

				BookingService.saveTeacherBookingData(
					teacherId,
					response.bookings,
					response.availableHours
				);
			} catch (error) {
				return [];
			}
		};

		const now = Date.now();

		if (
			BookingService.teacherBookings[teacherId] &&
			BookingService.cacheTimes[teacherId] &&
			now - BookingService.cacheTimes[teacherId] < cacheTime
		) {
			return;
		}

		getSchedule();
	}, [teacherId]);
}

export function useBooking(teacherId) {
	const [bookings, setBookings] = useState(BookingService.teacherBookings[teacherId] ?? []);
	const [availableHours, setAvailableHours] = useState(BookingService.teacherAvailableHours[teacherId] ?? []);

	useEffect(() => {
		const updateBookings = () => {
			setBookings(BookingService.teacherBookings[teacherId] ?? []);
			setAvailableHours(BookingService.teacherAvailableHours[teacherId] ?? []);
		};

		BookingService.addEventListener(teacherId, updateBookings);

		return () => {
			BookingService.removeEventListener(teacherId, updateBookings);
		};
	}, [teacherId]);

	const bookASlot = async (summaryId, bookingId, body, newBooking) => {
		try {
			const response = await callApi({
				method: "PATCH",
				url: `${apis.AGENDA}/${bookingId}`,
				body,
			});

			if (!response) {
				return;
			}

			BookingService.addTeacherBooking(newBooking.teacherId, newBooking);
			if (newBooking.isInstructor) {
				InstructorAgendaService.reschedule(newBooking);
			} else {
				StudentSummariesService.addNewBooking(summaryId, newBooking);
			}
		} catch (error) {}
	};

	return { bookings, availableHours, bookASlot };
}