import { Grid, Typography } from "@mui/material";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";

import { inboxType } from "../../../../constants";
import useScreenSize from "../../../../hooks/useScreenSize";
import NavbarOrka from "../../navegacion/Navbar";
import { ChatBox } from "./components/ChatBox";
import { useChatInit, useChatList } from "./hooks/useChat";

import "./chat.scss";

export function Chat() {
	const navigate = useNavigate();
	const { width } = useScreenSize();
	const isMobile = width <= 768;
	const params = useParams();
	const chatId = params.chatId;

	useChatInit();
	const { chatList } = useChatList();

	const getOpponentName = (chatItem) => {
		if (chatItem.inboxType === inboxType.STUDENT) {
			return `${chatItem.instructor.firstName} - ${chatItem.courseName}`;
		}
		
		if (chatItem.inboxType === inboxType.INSTRUCTOR) {
			return `${chatItem.student.firstName} - ${chatItem.courseName}`;
		}
	};

	return (
		<div style={{ height: "100%" }}>
			<NavbarOrka chatScreen isHiThere isInHome />
			<Grid container className="chat-content-container">
				{!(isMobile && chatId) && (
					<Grid
						item
						xs={12}
						sm={12}
						md={3}
						lg={3}
						xl={3}
						className="chat-list"
					>
						{chatList.map((chatItem) => (
							<div
								key={`${chatItem.CourseID}-${chatItem.inboxType}`}
								className={classNames("chat-item", {
									selected: chatId === chatItem.CourseID.split("#").join("_"),
								})}
								onClick={() => {
									navigate(
										`/messages/${chatItem.CourseID.split("#").join("_")}`
									);
								}}
							>
								<Typography className="normal-text">
									{getOpponentName(chatItem)}
								</Typography>
								<Typography className="normal-text recent-message">
									{chatItem.recentMessage.message}
								</Typography>
							</div>
						))}
					</Grid>
				)}
				{chatId && (
					<Grid item xs={12} sm={12} md={9} lg={9} xl={9} className="chat-box">
						<ChatBox />
					</Grid>
				)}
			</Grid>
		</div>
	);
}
