import { useEffect, useState } from "react";
import { useStudentSummaries } from "../../../../../hooks/useStudentSummaries";

export const StudentSelectSummaryService = {
	listeners: [],
	selectedIndex: null,
	selectedSummaryId: null,

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	saveSelectedSummary(selectedIndex, selectedSummaryId) {
		this.selectedIndex = selectedIndex;
		this.selectedSummaryId = selectedSummaryId;
		this.emitChanges();
	},

	clearData() {
		this.summaries = [];
		this.emitChanges();
	},

	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

export function useStudentSelectSummaryInit() {
	const { summaries } = useStudentSummaries();

	useEffect(() => {
		const savedSelectedIndex = StudentSelectSummaryService.selectedIndex;
		const savedSelectedSummaryId = StudentSelectSummaryService.selectedSummaryId;
		if (summaries.length === 0) {
			if (savedSelectedIndex !== null || savedSelectedSummaryId !== null) {
				StudentSelectSummaryService.saveSelectedSummary(null, null);
			}
			return;
		}

		if (savedSelectedIndex === null || savedSelectedSummaryId === null) {
			StudentSelectSummaryService.saveSelectedSummary(0, summaries[0].id);
			return;
		}

		if (
			summaries[savedSelectedIndex].id !== savedSelectedSummaryId
		) {
			const indexOfSavedSummary = summaries.findIndex((summary) => summary.id === savedSelectedSummaryId);

			if (indexOfSavedSummary === -1) {
				StudentSelectSummaryService.saveSelectedSummary(0, summaries[0].id);
				return;
			}

			StudentSelectSummaryService.saveSelectedSummary(
				indexOfSavedSummary,
				summaries[indexOfSavedSummary],
			);
		}
	}, [summaries]);
}

export function useStudentSelectSummary() {
	const [selectedIndex, setSelectedIndex] = useState(
		StudentSelectSummaryService.selectedIndex,
	);
	const [selectedSummaryId, setSelectedSummaryId] = useState(
		StudentSelectSummaryService.selectedSummaryId,
	);

	useEffect(() => {
		const updateSelectedSummary = () => {
			setSelectedIndex(StudentSelectSummaryService.selectedIndex);
			setSelectedSummaryId(StudentSelectSummaryService.selectedSummaryId);
		};

		StudentSelectSummaryService.addEventListener(updateSelectedSummary);

		return () => {
			StudentSelectSummaryService.removeEventListener(updateSelectedSummary);
		};
	}, []);

	return { selectedIndex, selectedSummaryId };
}
