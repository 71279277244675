import { useState } from "react";

import { Grid, Typography } from "@mui/material";
import classNames from "classnames";

import { useAccount } from "../../../../hooks/useAccount";
import NavbarOrka from "../../navegacion/Navbar";
import Courses from "./components/Courses/Courses";
import { CourseStudents } from "./components/CourseStudents/CourseStudents";
import { Schedule } from "./components/Schedule/Schedule";

import "./my_orka_instructor.scss";

export function MyOrkaInstructor() {
	const { account } = useAccount();

	const [selectedOption, setSelectedOption] = useState("CALENDAR");

	const openClasses = () => {
		setSelectedOption("CLASSES");
	};

	const openProgress = () => {
		setSelectedOption("PROGRESS");
	};

	const openCalendar = () => {
		setSelectedOption("CALENDAR");
	};

	return (
		<div style={{ height: "100vh" }}>
			<NavbarOrka chatScreen isHiThere isInHome />
			<Grid container className="my-orka-instructor-content-container">
				<Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
					<div className="my-orka-left-column">
						<Typography className="hi-there">Hi There!</Typography>
						<Typography className="user-name">
							{account.firstName} {account.lastName}
						</Typography>
						<Typography className="greeting">
							What would you like to do today?
						</Typography>
						<div
							style={{ marginTop: 20 }}
							className={classNames("my-orka-menu-button normal-text", {
								selected: selectedOption === "CALENDAR",
								"not-selected": selectedOption !== "CALENDAR",
							})}
							onClick={openCalendar}
						>
							VIEW CALENDAR
						</div>
						<div
							className={classNames("my-orka-menu-button normal-text", {
								selected: selectedOption === "CLASSES",
								"not-selected": selectedOption !== "CLASSES",
							})}
							style={{
								borderTopWidth: 0,
							}}
							onClick={openClasses}
						>
							VIEW / ADD / EDIT CLASSES
						</div>
						<div
							className={classNames("my-orka-menu-button normal-text", {
								selected: selectedOption === "PROGRESS",
								"not-selected": selectedOption !== "PROGRESS",
							})}
							style={{
								borderTopWidth: 0,
							}}
							onClick={openProgress}
						>
							UPLOAD STUDENT PROGRESS
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
					<div className="my-orka-right-column">
						<div hidden={selectedOption !== "CALENDAR"}>
							<Schedule />
						</div>
						<div hidden={selectedOption !== "CLASSES"}>
							<Courses />
						</div>
						<div hidden={selectedOption !== "PROGRESS"}>
							<CourseStudents />
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}
