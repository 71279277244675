import { Button } from "@mui/material";

import MenuProfile from "../Accont/MenuProfile";
import { useAccount } from "../../../hooks/useAccount";
import { apis, callApi } from "../../../api";

const ContentFinancesConfiguration = () => {
	const { account } = useAccount();

	const onClickOpenStripe = async () => {
		try {
			const response = await callApi({
				method: "GET",
				url: apis.GET_LOGIN_LINK,
			});

			if (response?.loginLink?.url) {
				window.open(response.loginLink.url, "blank");
			}
		} catch (error) {}
	};

	const onClickCreateStripeAccount = async () => {
		try {
			const response = await callApi({
				method: "GET",
				url: apis.GET_ONBOARD_LINK,
			});

			if (response?.accountLink?.url) {
				localStorage.setItem("nextRoute", "/finances");
				window.location.href = response.accountLink.url;
			}
		} catch (error) {}
	};

	return (
		<div
			className="container container-general-finances-configuration mb-5"
			id="GrisCalis"
		>
			<div className="row justify-content-md-between p-5">
				<div className="col-md-4 mb-4">
					<MenuProfile active="Finances" />
				</div>

				<div className="col-md-8 container-finances">
					<div className="finances-header">
						<h4 className="header-finances-pc">Payment Account</h4>
						<div className="box-before-document-missing-pc">
							{account.requiredKYC && (
								<div className="box-general-document-missing">
									<div className="box-image-warning">
										<img
											src="./imagenes/WarningIcon.jpg"
											id="WarningIcon"
											alt="WarningIcon"
										/>
									</div>
									<div className="box-text-document-missing">
										<p>Action Needed: Payment information update</p>
									</div>
								</div>
							)}
						</div>

						<h4 className="header-finances-mobil">Payment Account</h4>

						<div className="box-before-document-missing-mobil">
							{account.requiredKYC && (
								<div className="box-general-document-missing">
									<div className="box-image-warning">
										<img
											src="./imagenes/WarningIcon.jpg"
											id="WarningIcon"
											alt="WarningIcon"
										/>
									</div>
									<div className="box-text-document-missing">
										<p>Action Needed: Payment information update</p>
									</div>
								</div>
							)}
						</div>
					</div>

					<div className="container-content">
						<div>
							<p className="how-text">
								Add, change or update your payment information, and easily
								access a summary of your earnings.
							</p>
							{!account.requiredKYC && (
								<Button className="add-bank" onClick={onClickOpenStripe}>
									Manage Finances
								</Button>
							)}

							{account.requiredKYC && (
								<Button className="add-bank" onClick={onClickCreateStripeAccount}>
									Create stripe account
								</Button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContentFinancesConfiguration;
