import { Grid } from "@mui/material";
import useScreenSize from "../../../../hooks/useScreenSize";

import { NotFound } from "../../../common/NotFound/NotFound";
import NavbarOrka from "../../navegacion/Navbar";
import { CourseAviationStory } from "./components/CourseAviationStory/CourseAviationStory";
import { CourseBasicInformation } from "./components/CourseBasicInformation/CourseBasicInformation";
import { CourseDangerZone } from "./components/CourseDangerZone/CourseDangerZone";
import { CourseLessons } from "./components/CourseLessons/CourseLessons";
import { useMyCourseInstructorInit } from "./hooks/useMyCourseInstructor";

import "./my_course_information.scss";

export function MyCourseInformation() {
	const { slugOrUuid, isNotFound } = useMyCourseInstructorInit();
	const { width } = useScreenSize();
	const isMobile = width <= 768;

	if (!slugOrUuid) {
		return null;
	}

	if (isNotFound) {
		return <NotFound text="Course not found" />;
	}

	return (
		<>
			<NavbarOrka />
			<Grid container className="my-course-information-container">
				<Grid item xs={12} sm={12} md={6} lg={6} xl={7}>
					<CourseAviationStory />
					<CourseBasicInformation />
					{!isMobile && <CourseDangerZone />}
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
					<CourseLessons />
				</Grid>
				{isMobile && (
					<Grid item xs={12} sm={12}>
						<CourseDangerZone />
					</Grid>
				)}
			</Grid>
		</>
	);
}
