import { useMemo } from "react";

import { Typography } from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import Calendar from "react-calendar";
import { FaCircleCheck } from "react-icons/fa6";
import { Link, useSearchParams } from "react-router-dom";

import { courseType, lessonType } from "../../../../../../constants";
import { useMyOwnedCoursesStudent } from "../../../../../../hooks/useMyOwnedCoursesStudent";
import { useStudentSummaries } from "../../../../../../hooks/useStudentSummaries";

export function CourseSchedule({
	courseSlug,
	selectedLessonId,
	setSelectedLessonId,
	isOwned,
}) {
	const { summaries } = useStudentSummaries();
	const {
		course,
		passedLessons,
		upcomingLessons,
		unscheduledLessons,
		missingLessons,
	} = useMyOwnedCoursesStudent(courseSlug);
	const [searchParams, setSearchParams] = useSearchParams();

	const displayPassedLesson = [...passedLessons];
	displayPassedLesson.reverse();

	const summariesObject = useMemo(() => {
		if (!course || course.type === courseType.ONLINE) {
			return {};
		}

		return upcomingLessons
			.filter((booking) => booking.lesson.type === lessonType.ONSITE)
			.reduce((acc, cur) => {
				const dayDiff = moment(cur.endTime).diff(cur.startTime, "day");

				for (let i = 0; i <= dayDiff; i++) {
					const dateString = moment(cur.startTime)
						.add(i, "day")
						.format("YYYY-MM-DD");
					if (acc[dateString]) {
						acc[dateString].push(cur);
					} else {
						acc[dateString] = [cur];
					}
				}

				return acc;
			}, {});
	}, [course, upcomingLessons]);

	const isNoLessons = !course;

	return (
		<div className="course-schedule-container">
			{course?.type !== courseType.ONLINE && (
				<>
					<Calendar
						className="normal-text"
						locale="en-US"
						minDate={new Date()}
						tileClassName={({ date }) => {
							if (summariesObject[moment(date).format("YYYY-MM-DD")]) {
								return "marked-date";
							}

							return "";
						}}
					/>
					<div className="calendar-note">
						<div className="color-square" />
						<Typography className="note-text">You have appointments</Typography>
					</div>
				</>
			)}

			<Typography className="classes-text normal-text">CLASSES</Typography>

			{isNoLessons && (
				<Typography className="no-classes-or-events-text">
					No classes or events yet
				</Typography>
			)}

			{displayPassedLesson.map((booking) => (
				<div className="booking-container" key={booking.id}>
					<div className="icon-container">
						<FaCircleCheck className="normal-icon check-icon" />
					</div>
					<div
						className={classNames("booking-item", {
							selected: isOwned && selectedLessonId === booking.id,
						})}
						onClick={() => {
							searchParams.set("course", course.courseSlug);
							searchParams.set("class", booking.id);
							searchParams.set("o", 1);
							setSearchParams(searchParams);
							setSelectedLessonId(booking.id);
						}}
					>
						{booking.startTime && booking.endTime && (
							<div className="booking-item-date">
								<Typography className="normal-text date-text bold">
									{moment(booking.startTime).format("DD")}
								</Typography>
								<Typography className="month-text bold">
									{moment(booking.startTime).format("MMM")}
								</Typography>
							</div>
						)}
						<div className="booking-item-lesson-info">
							<Typography className="normal-text lesson-title bold">
								{booking.lesson.title}
							</Typography>
							{booking.startTime && (
								<Typography className="month-text bold">
									{moment(booking.startTime).format("HH:mm")}
								</Typography>
							)}
						</div>
					</div>
				</div>
			))}

			{upcomingLessons.map((booking) => (
				<div className="booking-container" key={booking.id}>
					<div className="icon-container" />
					<div
						className={classNames("booking-item", {
							selected: isOwned && selectedLessonId === booking.id,
						})}
						onClick={() => {
							searchParams.set("course", course.courseSlug);
							searchParams.set("class", booking.id);
							searchParams.set("o", 1);
							setSearchParams(searchParams);
							setSelectedLessonId(booking.id);
						}}
					>
						{booking.startTime && booking.endTime && (
							<div className="booking-item-date">
								<Typography className="normal-text date-text bold">
									{moment(booking.startTime).format("DD")}
								</Typography>
								<Typography className="month-text bold">
									{moment(booking.startTime).format("MMM")}
								</Typography>
							</div>
						)}
						<div className="booking-item-lesson-info">
							<Typography className="normal-text lesson-title bold">
								{booking.lesson.title}
							</Typography>
							{booking.startTime && (
								<Typography className="month-text bold">
									{moment(booking.startTime).format("HH:mm")}
								</Typography>
							)}
						</div>
					</div>
				</div>
			))}

			{unscheduledLessons.map((booking) => (
				<div className="booking-container" key={booking.id}>
					<div className="icon-container" />
					<div
						className={classNames("booking-item", {
							selected: isOwned && selectedLessonId === booking.id,
						})}
						onClick={() => {
							searchParams.set("course", course.courseSlug);
							searchParams.set("class", booking.id);
							searchParams.set("o", 1);
							setSearchParams(searchParams);
							setSelectedLessonId(booking.id);
						}}
					>
						<div className="booking-item-lesson-info">
							<Typography className="normal-text lesson-title bold">
								{booking.lesson.title}
							</Typography>
						</div>
					</div>
				</div>
			))}

			{missingLessons.map((lesson) => (
				<div className="booking-container" key={lesson.id}>
					<div className="icon-container" />
					<div
						className={classNames("booking-item", {
							selected: !isOwned && selectedLessonId === lesson.id,
						})}
						onClick={() => {
							searchParams.set("course", course.courseSlug);
							searchParams.set("class", lesson.id);
							searchParams.set("o", 0);
							setSearchParams(searchParams);
							setSelectedLessonId(lesson.id);
						}}
					>
						<div className="booking-item-lesson-info">
							<Typography className="normal-text lesson-title bold">
								{lesson.title}
							</Typography>
						</div>
					</div>
				</div>
			))}

			<div className="courses-button-container" style={{ marginLeft: 40 }}>
				{course?.type === courseType.ONSITE && (
					<Link className="normal-text browse-class-text" to="/booking">
						Schedule
					</Link>
				)}

				{(summaries.length === 0 || !course) && (
					<Link className="normal-text browse-class-text" to="/#experiences">
						Browse Classes
					</Link>
				)}
			</div>
		</div>
	);
}
