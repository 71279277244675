import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";
import { apis, callApi } from "../../../../../api";

export const PaymentSuccessService = {
	listeners: [],
	course: null,
	bookings: [],

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	initData(course, bookings) {
		this.course = course;
		this.bookings = bookings;
		this.emitChanges();
	},

	clearData() {
		this.course = null;
		this.bookings = [];
		this.emitChanges();
	},

	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

export function usePaymentSuccessInit() {
	const [searchParams] = useSearchParams();
	const stripeId = searchParams.get("session_id") ?? "";

	const [isNotFound, setIsNotFound] = useState(false);

	useEffect(() => {
		const getBillInfo = async () => {
			try {
				const response = await callApi({
					method: "GET",
					url: `${apis.BILL}/${stripeId}`,
				});
	
				if (!response) {
					return;
				}
	
				PaymentSuccessService.initData(response.course, response.bookings);
			} catch (error) {
				if (error?.response?.status === 404) {
					setIsNotFound(true);
				}
			}
		};

		getBillInfo();
	}, [stripeId]);

	useEffect(() => {
		return () => {
			PaymentSuccessService.clearData();
		};
	}, [])

	return { isNotFound };
}

export function usePaymentSuccess() {
	const [course, setCourse] = useState(PaymentSuccessService.course);
	const [bookings, setBookings] = useState(PaymentSuccessService.bookings);

	useEffect(() => {
		const updateData = () => {
			setCourse(PaymentSuccessService.course);
			setBookings(PaymentSuccessService.bookings);
		};

		PaymentSuccessService.addEventListener(updateData);

		return () => {
			PaymentSuccessService.removeEventListener(updateData);
		};
	}, []);

	return { course, bookings };
}
