import React from "react";

import { roles } from "../../../constants";
import { useAccount } from "../../../hooks/useAccount";
import ContentAccountInformationPageInstructor from "./ContentAccountInformationPageInstructor";
import MenuProfile from "./MenuProfile";
import ContentAccountInformationPageStudent from "./ContentAccountInformationPageStudent";

export default function AccountInfo() {
	const { account } = useAccount();

	if (!account) {
		return null;
	}

	return (
		<div
			className="container container-general-account-information-page-instructor  mb-5"
			id="GrisCalis"
		>
			<div className="row justify-content-md-between p-5">
				<div className="col-md-4 mb-4">
					<MenuProfile active="Profile" />
				</div>

				{account.role === roles.STUDENT ? (
					<ContentAccountInformationPageStudent />
				) : (
					<ContentAccountInformationPageInstructor />
				)}
			</div>
		</div>
	);
};
