import { useEffect, useState } from "react";

export const StripeService = {
	stripeData: null,
	listeners: [],

	addEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
		this.listeners.push(cb);
	},

	saveStripeData(newData) {
		this.stripeData = newData;
		StripeService.emitChanges();
	},

	updateStripeData(partialNewData) {
		this.stripeData = { ...this.stripeData, ...partialNewData };
		StripeService.emitChanges();
	},

	clearData() {
		this.stripeData = null;
		this.emitChanges();
	},

	emitChanges() {
		for (const listener of this.listeners) {
			listener();
		}
	},

	removeEventListener(cb) {
		this.listeners = this.listeners.filter((listener) => listener !== cb);
	},
};

export function useStripe() {
	const [stripeData, setStripeData] = useState(StripeService.stripeData);

	useEffect(() => {
		const updateStripeData = () => {
			setStripeData(StripeService.stripeData);
		};

		StripeService.addEventListener(updateStripeData);

		return () => {
			StripeService.removeEventListener(updateStripeData);
		};
	}, []);

	return { stripeData };
}
