import { useEffect, useState } from "react";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	IconButton,
	Tooltip,
	Typography,
} from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { FaRegTrashCan } from "react-icons/fa6";
import { GoAlert } from "react-icons/go";

import { lessonType } from "../../../../../../constants";
import { validators } from "../../../../../../utils";
import { useMyCourseLessonsInstructor } from "../../hooks/useMyCourseInstructor";
import { CourseInfoInput } from "../CourseInfoInput";
import { CourseLessonHelperDocuments } from "./CourseLessonHelperDocuments";
import { CourseLessonVideo } from "./CourseLessonVideo";
import classNames from "classnames";

const convertMinuteToHour = (minute) => {
	if (!minute) {
		return "";
	}

	return `${Math.round((minute * 20) / 60) / 20}`;
};

function CourseLessonButtons({ control, onClickCancel, lesson }) {
	const { title, description, teachingTime, flightTime, airplane, totalCost } =
		useWatch({ control });

	const calculateIsDisabled = () => {
		if (title.trim() !== lesson.title.trim()) {
			return false;
		}

		if (description.trim() !== lesson.description.trim()) {
			return false;
		}

		if (Number(teachingTime) * 60 !== (lesson.teachingTime ?? 0)) {
			return false;
		}
		if (teachingTime === "" && lesson.teachingTime !== null) {
			return false;
		}
		if (teachingTime === "0" && lesson.teachingTime === null) {
			return false;
		}

		if (lesson.type === lessonType.ONSITE) {
			if (Number(flightTime) * 60 !== (lesson.flightTime ?? 0)) {
				return false;
			}
			if (flightTime === "" && lesson.flightTime !== null) {
				return false;
			}
			if (flightTime === "0" && lesson.flightTime === null) {
				return false;
			}

			if (airplane.trim() !== lesson.airplane.trim()) {
				return false;
			}
		}

		if (totalCost !== `${lesson.totalCost ?? ""}`) {
			return false;
		}

		return true;
	};

	const isDisabled = calculateIsDisabled();

	return (
		<div className="lesson-buttons-container">
			<Button
				variant="contained"
				className="lesson-button normal-button disabled-button"
				onClick={onClickCancel}
			>
				Cancel
			</Button>
			<Button
				variant="contained"
				className={classNames("lesson-button normal-button", {
					"primary-button": !isDisabled,
					"disabled-button": isDisabled,
				})}
				type="submit"
				disabled={isDisabled}
			>
				Save
			</Button>
		</div>
	);
}

export function CourseLesson({ lesson }) {
	const [expanded, setExpanded] = useState(false);
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const { deleteLesson, updateLessonInformation } =
		useMyCourseLessonsInstructor();

	const formDefaultValues = {
		title: lesson.title ?? "",
		description: lesson.description ?? "",
		teachingTime: convertMinuteToHour(lesson.teachingTime),
		blockTime: convertMinuteToHour(lesson.blockTime) || "0",
		airplane: lesson.airplane ?? "",
		totalCost: `${lesson.totalCost ?? ""}`,
	};
	if (lesson.type === lessonType.ONSITE) {
		formDefaultValues.flightTime = convertMinuteToHour(lesson.flightTime);
	}
	const { control, handleSubmit, setValue, watch } = useForm({
		defaultValues: formDefaultValues,
	});
	const { teachingTime = 0, flightTime = 0 } = watch();

	useEffect(() => {
		setValue("blockTime", (Number(teachingTime) + Number(flightTime)).toFixed(2));
	}, [flightTime, setValue, teachingTime]);

	const onChangeExpanded = (event, expanded) => {
		setExpanded(expanded);
	};

	const onClickCancel = () => {
		setValue("title", lesson.title ?? "");
		setValue("description", lesson.description ?? "");
		setValue("teachingTime", convertMinuteToHour(lesson.teachingTime));
		if (lesson.type === lessonType.ONSITE) {
			setValue("flightTime", convertMinuteToHour(lesson.flightTime));
		}
		setValue("airplane", lesson.airplane ?? "");
		setValue("totalCost", `${lesson.totalCost ?? ""}`);
	};

	const onClickOpenAlert = (e) => {
		e.stopPropagation();
		setIsAlertOpen(true);
	};

	const closeAlert = () => {
		setIsAlertOpen(false);
	};

	const onClickDeleteLesson = () => {
		deleteLesson(lesson.id);
	};

	const onSubmitUpdateLesson = (data) => {
		const body = {};

		if (data.title.trim() !== lesson.title.trim()) {
			body.title = data.title.trim();
		}

		if (data.description.trim() !== lesson.description.trim()) {
			body.description = data.description.trim();
		}

		if (Number(data.teachingTime) * 60 !== (lesson.teachingTime ?? 0)) {
			if (data.teachingTime === "") {
				body.teachingTime = null;
			} else {
				body.teachingTime = Number(data.teachingTime) * 60;
			}
		} else if (data.teachingTime === "" && lesson.teachingTime !== null) {
			body.teachingTime = null;
		} else if (data.teachingTime === "0" && lesson.teachingTime === null) {
			body.teachingTime = 0;
		}

		if (data.totalCost !== (`${lesson.totalCost}` ?? "")) {
			if (data.totalCost === "") {
				body.totalCost = null;
			} else {
				body.totalCost = Number(data.totalCost);
			}
		}

		if (lesson.type === lessonType.ONSITE) {
			if (Number(data.flightTime) * 60 !== (lesson.flightTime ?? 0)) {
				if (data.flightTime === "") {
					body.flightTime = null;
				} else {
					body.flightTime = Number(data.flightTime) * 60;
				}
			} else if (data.flightTime === "" && lesson.flightTime !== null) {
				body.flightTime = null;
			} else if (data.flightTime === "0" && lesson.flightTime === null) {
				body.flightTime = 0;
			}

			if (data.airplane.trim() !== lesson.airplane.trim()) {
				body.airplane = data.airplane.trim();
			}
		}
		updateLessonInformation(lesson.id, body);
	};

	const renderLessonForm = () => {
		if (lesson.type === lessonType.ONSITE) {
			return (
				<>
					<CourseInfoInput
						control={control}
						name="title"
						placeholder="E.g. Turning Flight"
						label="CLASS NAME*"
						labelClassName="lesson-input-title normal-text"
						inputClassName="lesson-input-field normal-text"
						rules={{
							required: {
								value: true,
								message: "Class name is required.",
							},
							pattern: {
								value: validators.nonEmptyString,
								message: "Course name is required.",
							},
						}}
					/>
					<div style={{ height: 8 }} />
					<CourseInfoInput
						control={control}
						name="description"
						placeholder="This Class shows you how to..."
						label="CLASS DESCRIPTION*"
						labelClassName="lesson-input-title normal-text"
						inputClassName="lesson-input-field normal-text"
						multiline
					/>
					<div style={{ height: 8 }} />
					<CourseInfoInput
						control={control}
						name="teachingTime"
						placeholder="E.g. 2, 2.25, 2.5 (hrs)"
						label="TEACHING TIME*"
						description="The time you invest with your student to develop knowledge"
						labelClassName="lesson-input-title normal-text"
						inputClassName="lesson-input-field normal-text"
						descriptionClassName="lesson-input-description"
						disallowInvalidInput
						suffix="hrs"
						rules={{
							pattern: {
								value: validators.time,
								message: "E.g. 2, 2.25, 2.5 (hrs)",
							},
						}}
					/>
					<div style={{ height: 8 }} />
					<CourseInfoInput
						control={control}
						name="flightTime"
						placeholder="E.g. 2, 2.25, 2.5 (hrs)"
						label="FLIGHT TIME*"
						description="Time in the airplane or simulator"
						labelClassName="lesson-input-title normal-text"
						inputClassName="lesson-input-field normal-text"
						descriptionClassName="lesson-input-description"
						disallowInvalidInput
						suffix="hrs"
						rules={{
							pattern: {
								value: validators.time,
								message: "E.g. 2, 2.25, 2.5 (hrs)",
							},
						}}
					/>
					<div style={{ height: 8 }} />
					<CourseInfoInput
						control={control}
						name="blockTime"
						placeholder="E.g. 2, 2.25, 2.5 (hrs)"
						label="BLOCK TIME"
						description="This is the total time it takes to deliver this lesson"
						labelClassName="lesson-input-title normal-text"
						inputClassName="lesson-input-field normal-text"
						descriptionClassName="lesson-input-description"
						suffix="hrs"
						disabled
					/>
					<div style={{ height: 8 }} />
					<CourseInfoInput
						control={control}
						name="airplane"
						placeholder="Airplane tail number or simulator ID"
						label="AIRPLANE*"
						labelClassName="lesson-input-title normal-text"
						inputClassName="lesson-input-field normal-text"
					/>
					<div style={{ height: 8 }} />
					<CourseLessonHelperDocuments lesson={lesson} />
					<div style={{ height: 8 }} />
					<CourseInfoInput
						control={control}
						name="totalCost"
						placeholder="Total cost"
						label="TOTAL COST*"
						labelClassName="lesson-input-title normal-text"
						inputClassName="lesson-input-field normal-text"
						disallowInvalidInput
						prefix="$"
						rules={{
							pattern: {
								value: validators.price,
								message: "E.g. 300, 500, 1000",
							},
						}}
					/>
				</>
			);
		}

		if (lesson.type === lessonType.ONLINE) {
			return (
				<>
					<CourseInfoInput
						control={control}
						name="title"
						placeholder="E.g. Turning Flight"
						label="CLASS NAME*"
						labelClassName="lesson-input-title normal-text"
						inputClassName="lesson-input-field normal-text"
						rules={{
							required: {
								value: true,
								message: "Class name is required.",
							},
							pattern: {
								value: validators.nonEmptyString,
								message: "Course name is required.",
							},
						}}
					/>
					<div style={{ height: 8 }} />
					<CourseInfoInput
						control={control}
						name="description"
						placeholder="This Class shows you how to..."
						label="CLASS DESCRIPTION*"
						labelClassName="lesson-input-title normal-text"
						inputClassName="lesson-input-field normal-text"
						multiline
					/>
					<div style={{ height: 8 }} />
					<CourseInfoInput
						control={control}
						name="teachingTime"
						placeholder="E.g. 2, 2.25, 2.5 (hrs)"
						label="TEACHING TIME*"
						description="The time you invest with your student to develop knowledge"
						labelClassName="lesson-input-title normal-text"
						inputClassName="lesson-input-field normal-text"
						descriptionClassName="lesson-input-description"
						disallowInvalidInput
						suffix="hrs"
						rules={{
							pattern: {
								value: validators.time,
								message: "E.g. 2, 2.25, 2.5 (hrs)",
							},
						}}
					/>
					<div style={{ height: 8 }} />
					<CourseInfoInput
						control={control}
						name="blockTime"
						placeholder="E.g. 2, 2.25, 2.5 (hrs)"
						label="BLOCK TIME"
						description="This is the total time it takes to deliver this lesson"
						labelClassName="lesson-input-title normal-text"
						inputClassName="lesson-input-field normal-text"
						descriptionClassName="lesson-input-description"
						suffix="hrs"
						disabled
					/>
					<div style={{ height: 8 }} />
					<CourseLessonVideo lesson={lesson} />
					<div style={{ height: 8 }} />
					<CourseLessonHelperDocuments lesson={lesson} />
					<div style={{ height: 8 }} />
					<CourseInfoInput
						control={control}
						name="totalCost"
						placeholder="Total cost"
						label="TOTAL COST*"
						labelClassName="lesson-input-title normal-text"
						inputClassName="lesson-input-field normal-text"
						prefix="$"
						disallowInvalidInput
						rules={{
							pattern: {
								value: validators.price,
								message: "E.g. 300, 500, 1000",
							},
						}}
					/>
				</>
			);
		}

		return null;
	};

	return (
		<form onSubmit={handleSubmit(onSubmitUpdateLesson)}>
			<div className="course-lesson-item-container">
				<Accordion expanded={expanded} onChange={onChangeExpanded}>
					<AccordionSummary>
						<div
							style={{ display: "flex", alignItems: "center", width: "100%" }}
						>
							<Typography
								component="p"
								className="normal-text bold lesson-title"
							>
								{lesson.title}
							</Typography>
							{!lesson.isOk && (
								<Tooltip
									title={
										'You need to fill all required fields to set this course as "Flying"'
									}
								>
									<IconButton className="alert-icon" disableRipple>
										<GoAlert className="normal-icon" />
									</IconButton>
								</Tooltip>
							)}
							<IconButton onClick={onClickOpenAlert}>
								<FaRegTrashCan className="normal-icon" />
							</IconButton>
						</div>
					</AccordionSummary>
					<AccordionDetails style={{ padding: 0 }}>
						{renderLessonForm()}
						<CourseLessonButtons
							control={control}
							onClickCancel={onClickCancel}
							lesson={lesson}
						/>
					</AccordionDetails>
				</Accordion>
			</div>
			<Dialog open={isAlertOpen} onClose={closeAlert}>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{
							"Are you sure you want to delete this class? This action cannot be undone."
						}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeAlert}>No, thanks</Button>
					<Button onClick={onClickDeleteLesson}>Yes, I'm sure</Button>
				</DialogActions>
			</Dialog>
		</form>
	);
}
