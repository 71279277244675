import { Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";

import { useMyCourseLessonsInstructor } from "../../hooks/useMyCourseInstructor";
import { CourseLessonHelperDocumentItem } from "./CourseLessonHelperDocumentItem";

export function CourseLessonHelperDocuments({ lesson }) {
	const { addDocument } = useMyCourseLessonsInstructor();

	const handleUploadFile = (files) => {
		for (let i = 0; i < files.length; i++) {
			addDocument(lesson.id, files[i], "HELP_DOC");
		}
	};

	const dropzone = useDropzone({
		noKeyboard: true,
		accept: {
			"image/*": [],
			"application/pdf": [],
		},
		onDropAccepted: handleUploadFile,
	});

	const helperDocuments = lesson.lessonDocuments.filter(
		(document) => document.documentType === "HELP_DOC"
	);

	return (
		<>
			<Typography component="p" className="lesson-input-title normal-text">
				HELPFUL DOCUMENTS
			</Typography>

			{helperDocuments.map((document) => {
				return (
					<CourseLessonHelperDocumentItem
						key={document.id ?? document.uploadId}
						lessonId={lesson.id}
						document={document}
					/>
				);
			})}

			<div {...dropzone.getRootProps({ className: "drop-zone" })}>
				<input {...dropzone.getInputProps()} />
				<Typography className="normal-text">
					{
						"Drag 'n' drop or click to select supportive files to help your students."
					}
				</Typography>
			</div>
		</>
	);
}
