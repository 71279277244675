import { useState } from "react";

import {
	Button,
	Dialog,
	DialogContent,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { FaXmark } from "react-icons/fa6";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { apis, callApi } from "../../../api";
import { roles } from "../../../constants";
import { AccountService } from "../../../hooks/useAccount";
import useScreenSize from "../../../hooks/useScreenSize";
import { StripeService } from "../../../hooks/useStripe";
import { TokenService } from "../../../hooks/useToken";
import { rules } from "../../../utils";
import { CommonInput } from "../../common";
import NavbarOrka from "../navegacion/Navbar";

import "./login.scss";
import { toast } from "react-toastify";

export function Login() {
	const [isForgotPasswordModalOpen, setIsShowForgotPasswordModalOpen] =
		useState(false);
	const [searchParams] = useSearchParams();
	const { width } = useScreenSize();
	const isMobile = width <= 768;

	const { handleSubmit: handleSubmitSignIn, control: controlSignIn } = useForm({
		defaultValues: {
			email: "",
			password: "",
		},
		mode: "all",
	});

	const {
		handleSubmit: handleSubmitForgotPassword,
		control: controlForgotPassword,
		setValue: setValueForgotPassword,
	} = useForm({
		defaultValues: {
			emailRecovery: "",
		},
		mode: "all",
	});

	const navigate = useNavigate();

	const openForgotPasswordModal = () => {
		setIsShowForgotPasswordModalOpen(true);
	};

	const closeForgotPasswordModal = () => {
		setIsShowForgotPasswordModalOpen(false);
	};

	const initStripe = async (user, token) => {
		try {
			const stripeResponse = await callApi({
				method: "POST",
				url: apis.LINK_ACCOUNT,
				accessToken: token,
				body: {
					userCode: user.userId,
				},
			});

			StripeService.saveStripeData(stripeResponse.account);
		} catch (error) {}
	};

	const onClickLogin = async (data) => {
		try {
			const { email, password } = data;
			const response = await callApi({
				method: "POST",
				url: apis.SIGN_IN,
				body: { email, password },
			});

			const { user, token, stripeInfo } = response;
			const redirect = searchParams.get("redirect");

			if (redirect) {
				TokenService.saveToken(token);
				AccountService.saveAccount(user);
				navigate(redirect, { replace: true });
				return;
			}

			if (user.role === roles.INSTRUCTOR && user.requiredKYC) {
				if (!stripeInfo || stripeInfo.status !== "ACTIVE") {
					await initStripe(user, token);
					TokenService.saveToken(token);
					AccountService.saveAccount(user);
					navigate("/stripe", {
						state: {
							nextRoute: "/welcome-back",
						},
					});
				} else {
					TokenService.saveToken(token);
					AccountService.saveAccount(user);
					navigate("/stripe/success");
				}
			} else {
				TokenService.saveToken(token);
				AccountService.saveAccount(user);
				navigate("/welcome-back");
			}
		} catch (error) {
			if (error?.response?.status === 400) {
				alert("Wrong email or password, please check your information again!");
			}
		}
	};

	const onClickRequestRecoveryPassword = async (data) => {
		try {
			const { emailRecovery } = data;

			await callApi({
				method: "POST",
				url: apis.RECOVER_PASSWORD,
				body: { emailRecovery },
			});

			closeForgotPasswordModal();
			toast.success(
				"An email has been sent to you, please check your email and continue to reset your password!"
			);
			setValueForgotPassword("emailRecovery", "");
		} catch (error) {
			alert("Wrong email, please check your information again!");
		}
	};

	return (
		<div style={{ height: "100vh" }}>
			<NavbarOrka isHiThere isInHome />
			<div className="login-container">
				<Grid container>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<form
							className="login-form"
							onSubmit={handleSubmitSignIn(onClickLogin)}
						>
							<Typography className="login-text">SIGN IN</Typography>
							<CommonInput
								control={controlSignIn}
								name="email"
								type="email"
								placeholder="Email"
								rules={{
									required: {
										value: true,
										message: "Email is required.",
									},
									pattern: rules.email.pattern,
								}}
							/>
							<CommonInput
								control={controlSignIn}
								name="password"
								type="password"
								placeholder="Password"
								rules={{
									required: {
										value: true,
										message: "Password is required.",
									},
								}}
							/>

							<Typography
								className="normal-text forgot-password-text"
								onClick={openForgotPasswordModal}
							>
								Forgot your password?
							</Typography>

							<Button
								className="normal-button primary-button login-button"
								type="submit"
							>
								Sign in
							</Button>
							<Typography className="normal-text sign-up-text">
								Don't have an account?{" "}
								<Link to="/sign-up" className="sign-up-button">
									{" "}
									Sign up here
								</Link>
							</Typography>
						</form>
					</Grid>

					{!isMobile && (
						<Grid item md={8} lg={8} xl={8}>
							<div style={{ display: "flex", justifyContent: "flex-end" }}>
								<img
									src="../imagenes/botones2.png"
									alt="botones2"
									style={{ width: "91.667%" }}
								/>
							</div>
						</Grid>
					)}
				</Grid>
			</div>
			<Dialog
				open={isForgotPasswordModalOpen}
				onClose={closeForgotPasswordModal}
				className="modal-login-forgot-password"
			>
				<DialogContent>
					<div className="dialog-header">
						<IconButton>
							<FaXmark
								className="normal-icon close-icon"
								onClick={closeForgotPasswordModal}
							/>
						</IconButton>
					</div>

					<form
						className="forgot-password-form"
						onSubmit={handleSubmitForgotPassword(
							onClickRequestRecoveryPassword
						)}
					>
						<Typography className="account-recovery">
							Account Recovery
						</Typography>
						<Typography className="normal-text enter-email-text">
							Enter the email you use for ORKA, and we'll help you recover your
							password.
						</Typography>
						<CommonInput
							control={controlForgotPassword}
							name="emailRecovery"
							type="email"
							placeholder="Email"
							rules={{
								required: {
									value: true,
									message: "Email is required.",
								},
								pattern: rules.email.pattern,
							}}
						/>
						<Button
							className="normal-button primary-button submit-email-recovery-button"
							type="submit"
						>
							Next
						</Button>
					</form>
				</DialogContent>
			</Dialog>
		</div>
	);
}
