import { useEffect, useState } from "react";

import { Autocomplete, TextField, Typography } from "@mui/material";
import { useController } from "react-hook-form";
import usePlacesAutocomplete, {
	getGeocode,
	getLatLng,
} from "use-places-autocomplete";

export function CourseLocation({
	control,
	name,
	latitudeName,
	longitudeName,
	label,
	labelClassName,
	inputClassName,
	placeholder,
	setFormValue,
}) {
	const { field } = useController({ name, control });
	const [inputValue, setInputValue] = useState(field.value ?? "");

	const {
		suggestions: { data },
		setValue,
	} = usePlacesAutocomplete({
		debounce: 500,
		defaultValue: field.value,
		initOnMount: true,
	});

	useEffect(() => {
		setInputValue(field.value ?? "");
	}, [field.value, setValue]);

	const getLocation = async (address) => {
		const results = await getGeocode({ address });
		const { lat, lng } = getLatLng(results[0]);
		setFormValue(latitudeName, lat);
		setFormValue(longitudeName, lng);
	};

	return (
		<>
			<Typography component="p" className={labelClassName}>
				{label}
			</Typography>
			<Autocomplete
				inputValue={inputValue}
				onInputChange={(e, newInputValue, reason) => {
					if (reason === "reset") {
						return;
					}
					setInputValue(newInputValue);
					setValue(newInputValue);
				}}
				onChange={(e, value) => {
					if (value?.description) {
						setFormValue(name, value.description);
						setInputValue(value.description);
						getLocation(value.description);
					} else {
						setFormValue(name, "");
						setInputValue("");
					}
				}}
				options={data}
				isOptionEqualToValue={(option1, option2) =>
					option1.place_id === option2.place_id
				}
				getOptionLabel={(option) => option.description ?? ""}
				className="input-field-inner"
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={placeholder}
						variant="standard"
						multiline
						InputProps={{
							...params.InputProps,
							disableUnderline: true,
							className: inputClassName,
							classes: {
								input: "input-field-inner",
							},
							onKeyDown(e) {
								if (e.key === "Enter") {
									e.preventDefault();
								}
							},
						}}
					/>
				)}
			/>
		</>
	);
}
