import React from "react";

import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { apis, callApi } from "../../../api/index";
import { roles } from "../../../constants";
import { AccountService, useAccount } from "../../../hooks/useAccount";
import { StripeService, useStripe } from "../../../hooks/useStripe";
import ContentFinancesConfiguration from "../Profile/ContentFinancesConfiguration";
import ContentReviews from "../Profile/ContentReviews";
import ContentSafetyInstructor from "../Profile/ContentSafetyInstructor";
import ContentSafetyStudents from "../Profile/ContentSafetyStudents";
import ContentSubmitDocumentsInstructor from "../Profile/ContentSubmitDocumentsInstructor";
import ContentSubmitDocumentStudent from "../Profile/ContentSubmitDocumentStudent";
import ContentAccountInformationPageInstructor from "./ContentAccountInformationPageInstructor";
import ContentAccountInformationPageStudent from "./ContentAccountInformationPageStudent";

const MenuProfileMobil = ({ active }) => {
	const { account } = useAccount();
	const { stripeData } = useStripe();

	const navigate = useNavigate();

	const initStripe = async () => {
		try {
			const stripeResponse = await callApi({
				method: "POST",
				url: apis.LINK_ACCOUNT,
				body: {
					userCode: account.userId,
				},
			});

			StripeService.saveStripeData(stripeResponse.account);
		} catch (error) {}
	};

	const updateUserRole = async (changedData) => {
		try {
			if (!account) {
				return;
			}

			const response = await callApi({
				method: "PUT",
				url: apis.UPDATE_USER_ROLE,
				body: changedData,
			});

			if (!response) {
				return;
			}

			if (changedData.role === roles.INSTRUCTOR && account.requiredKYC) {
				if (!stripeData || stripeData.status !== "ACTIVE") {
					await initStripe();
					AccountService.saveAccount({...account, ...changedData});
					navigate("/stripe", {
						state: {
							nextRoute: "/what-can-i-do",
						},
					});
				} else {
					AccountService.saveAccount({...account, ...changedData});
					localStorage.setItem("nextRoute", "/what-can-i-do");
					navigate("/stripe/success");
				}
			} else {
				AccountService.saveAccount({...account, ...changedData});
				navigate("/what-can-i-do");
			}
		} catch (error) {}
	};

	const logOut = () => {
		AccountService.signOut();
		navigate("/");
	};

	const switchRole = () => {
		const data = {
			role: account.role === roles.INSTRUCTOR ? "USER" : "TEACHER",
		};
		updateUserRole(data);
	};

	if (!account) {
		return null;
	}

	return (
		<>
			<div>
				<div className="box-name-profile-student-mobil">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 448 512"
						className="nav-user-icon icon-user-main"
					>
						<path
							fill="currentcolor"
							d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
						/>
					</svg>
					<p className="hi-there">Hi There!</p>
					<h4 className="full-name-person">
						{account.firstName} {account.lastName}
					</h4>
				</div>

				<div className="accordion-general-section">
					<Accordion defaultActiveKey="0" flush>
						<Accordion.Item eventKey="0" style={{ marginBottom: "1rem" }}>
							<Accordion.Header>Account</Accordion.Header>

							<Accordion.Body className="accordion-menu-mobil">
								{account.role === roles.STUDENT ? (
									<ContentAccountInformationPageStudent />
								) : (
									<ContentAccountInformationPageInstructor />
								)}
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item
							eventKey="1"
							style={{
								marginBottom: "1rem",
							}}
						>
							<Accordion.Header>Submit Documents</Accordion.Header>

							<Accordion.Body className="accordion-menu-mobil">
								{account.role === roles.STUDENT ? (
									<ContentSubmitDocumentStudent />
								) : (
									<ContentSubmitDocumentsInstructor />
								)}
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey="2" style={{ marginBottom: "0rem" }}>
							{account.role === roles.INSTRUCTOR && (
								<>
									<Accordion.Header>Finances</Accordion.Header>
									<Accordion.Body className="accordion-menu-mobil item-finances">
										<ContentFinancesConfiguration />
									</Accordion.Body>
								</>
							)}
						</Accordion.Item>

						<Accordion.Item eventKey="4" style={{ marginBottom: "1rem" }}>
							<Accordion.Header>Safety</Accordion.Header>

							<Accordion.Body className="accordion-menu-mobil">
								{account.role === roles.STUDENT ? (
									<ContentSafetyStudents />
								) : (
									<ContentSafetyInstructor />
								)}
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey="5" style={{ marginBottom: "1rem" }}>
							{account.role === roles.MODERATOR && (
								<>
									<Accordion.Header>Reviews</Accordion.Header>
									<Accordion.Body className="accordion-menu-mobil item-finances">
										<ContentReviews />
									</Accordion.Body>
								</>
							)}
						</Accordion.Item>

						<Accordion.Item eventKey="6" style={{ marginBottom: "1rem" }}>
							<Accordion.Header onClick={logOut}>Sign out</Accordion.Header>
						</Accordion.Item>

						<Accordion.Item eventKey="7">
							<Accordion.Header onClick={switchRole}>
								Switch to {account.role === roles.INSTRUCTOR ? "Student" : "Instructor"}
							</Accordion.Header>
						</Accordion.Item>
					</Accordion>
				</div>
			</div>
		</>
	);
};

export default MenuProfileMobil;
