import axios from "axios";

import { redirect } from "react-router-dom";
import { TokenService } from "../hooks/useToken";

// const defaultBaseURL = "http://localhost:8080/api";
const defaultBaseURL =  "https://back.prod.flyorka.com/api";
// const defaultBaseURL =  "https://back.dev.remotegrove.com/api";
// const defaultBaseURL = "https://back.prod.flyorka.com/api";

export const reqApi = axios.create({
	baseURL: defaultBaseURL,
});

axios.interceptors.request.use((config) => {
	if (!config.url) {
		return config;
	}

	// parse pathName to implement variables
	Object.entries(config.urlParams ?? {}).forEach(([k, v]) => {
		config.url = config.url.replace(`:${k}`, encodeURIComponent(v));
	});

	return {
		...config,
		baseURL: config.baseURL,
		url: config.url,
	};
});

/**
 * Function is used for calling api
 * @param {{
 * 		method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
 * 		accessToken: string;
 * 		url: string;
 * 		baseURL?: string;
 * 		params?: Record<string, string>;
 * 		urlParams?: Record<string, string>;
 * 		body: any;
 * 		onUploadProgress?: (progressEvent: any) => void;
 * }} requestParam
 * @returns {any}
 */
export const callApi = async ({
	method = "GET",
	accessToken,
	url,
	baseURL = defaultBaseURL,
	params,
	urlParams,
	body,
	responseType,
	onUploadProgress,
}) => {
	const headers = {
		"Content-Type": "application/json",
	};

	const token = accessToken ?? window.localStorage.getItem("token");
	if (token && baseURL === defaultBaseURL) {
		headers.Authorization = `Bearer ${token}`;
	}

	if (body instanceof FormData) {
		headers["Content-Type"] = "multipart/form-data";
	}

	if (body instanceof File) {
		headers["Content-Type"] = body.type;
		// We use aws s3 so in this case we don't need to provide Authorization
		delete headers.Authorization;
	}

	try {
		const response = await axios({
			method,
			url,
			baseURL,
			params,
			urlParams,
			data: body,
			headers,
			onUploadProgress,
			responseType,
		});

		return response.data;
	} catch (error) {
		if (error.response) {
			// Handle token expired
			if (
				error.response.status === 401 &&
				("No token provided" === error.response.data?.message ||
					"Invalid token, expired or incorrects" ===
						error.response.data?.errors)
			) {
				TokenService.clearToken();
				redirect(`/sign-in?redirect=${window.location.pathname}`);
				return;
			}
		}

		// Another error => throw error
		throw error;
	}
};

export const apis = Object.freeze({
	REGISTER: "/auth/create",
	SIGN_IN: "/auth/user",
	RECOVER_PASSWORD: "/auth/recoverPassword",
	RESET_PASSWORD: "/auth/updatePassword",

	GET_USER_BY_TOKEN: "/users/getByToken",
	UPDATE_USER_INFORMATION: "/users/update",
	UPDATE_USER_ROLE: "/users/update/role",

	REPORT_CASE: "/reports",

	GET_ALL_MY_DOCUMENTS: "/userDocs/get",
	CREATE_MY_DOCUMENT: "/userDocs/create",
	DELETE_MY_DOCUMENT: "/userDocs/delete",

	GET_FAVORITE_COURSES: "/open/getfavorites",
	SEARCH_COURSES: "/open/search",
	GET_IN_TOUCH: "/open/getInTouch",
	PREVIEW_COURSE_BY_SLUG: "/open/preview",

	GET_SIGNED_URL: "/media/getSignedURL",
	GET_DOWNLOAD_URL: "/media/getDownloadURL",

	COURSES: "/courses",
	MY_COURSES: "/courses/myCourses",
	MY_COURSES_BY_SLUG: "/courses/myCoursesBySlug",
	COURSE_DOCUMENTS: "/courses/docs",
	COURSE_DOCUMENTS_BY_COURSE_ID: "/courses/:courseId/docs",
	COURSE_REORDER_DOCUMENTS: "/courses/docs/reorder",
	COURSE_STATUS: "/courses/status",
	MY_OWNED_COURSE_BY_SLUG: "courses/myOwnedCourseBySlug",

	LESSONS: "/lessons",
	GET_LESSONS_BY_COURSE: "/lessons/getByCourseId",
	LESSONS_DOCUMENTS: "/lessons/:lessonId/docs",

	LINK_ACCOUNT: "/payment/linkAccount",
	GET_ONBOARD_LINK: "/payment/getOnboardLink",
	ONBOARD_ACCOUNT: "/payment/onboardAccount",
	GET_LOGIN_LINK: "/payment/getLoginLink",

	CLASSES_BOUGHT: "/classes/bought",
	CLASSES_SCHEDULE: "/classes/schedule",
	GET_TEACHER_AGENDA: "/classes/getTeacherAgenda",
	UPDATE_MY_CLASSES: "/classes/review",
	AGENDA: "/classes/agenda",
	BILL: "/classes/history",
	PROGRESS: "/classes/progress",

	CHECKOUT: "/payment/checkout",

	SUMMARY_BY_TEACHER_TOKEN: "/summary/getByTeacher",
	SUMMARY_BY_STUDENT_TOKEN: "/summary/getByStudent",

	MESSAGES: "/messages",
	INBOX: "/messages/inbox",

	GOOGLE_MAP_TIME_ZONE: "https://maps.googleapis.com/maps/api/timezone/json",
});
