import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";

export function CreateCourseInfoInput({
	control,
	name,
	label,
	labelClassName,
	multiline,
	placeholder,
	inputClassName,
	rules,
}) {
	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({ field: { onChange, value }, fieldState: { error } }) => {
				return (
					<>
						<Typography className={labelClassName}>{label}</Typography>
						<TextField
							helperText={error ? error.message : null}
							size="small"
							autoComplete="off"
							id={name}
							error={!!error}
							onChange={onChange}
							value={value}
							fullWidth
							maxRows={5}
							multiline={multiline}
							placeholder={placeholder}
							variant="standard"
							InputProps={{
								disableUnderline: true,
								classes: {
									input: inputClassName,
								}
							}}
						/>
					</>
				);
			}}
		/>
	);
}
