import { useState } from "react";

import { Typography } from "@mui/material";
import classNames from "classnames";
import { Link, useSearchParams } from "react-router-dom";

import { useStudentSummaries } from "../../../../../../hooks/useStudentSummaries";
import { StudentSelectSummaryService } from "../../hooks/useStudentSelectSummary";

export function Courses() {
	const { summaries } = useStudentSummaries();
	const [isShowMore, setIsShowMore] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	if (summaries.length === 0) {
		return (
			<div className="courses-empty-container">
				<Typography className="normal-text courses-empty-text">
					You're currently not enrolled in any classes
				</Typography>
				<Link className="normal-text browse-class-text" to="/#experiences">
					Browse Classes
				</Link>
			</div>
		);
	}

	const courseSlug = searchParams.get("course") ?? summaries[0].course.courseSlug ?? "";

	let textShowMore = "Show more";
	if (isShowMore) {
		textShowMore = "Show less";
	}

	let showingSummaries = summaries;
	if (!isShowMore) {
		showingSummaries = summaries.slice(0, 3);
	}

	return (
		<>
			{showingSummaries.map((summary, index) => (
				<div
					key={summary.id}
					className={classNames("course-item", {
						selected: courseSlug === summary.course.courseSlug,
					})}
					onClick={() => {
						StudentSelectSummaryService.saveSelectedSummary(index, summary.id);
						searchParams.set("course", summary.course.courseSlug);
						setSearchParams(searchParams);
					}}
				>
					<Typography className="course-name">{summary.course.name}</Typography>
					<div className="summary-information-container">
						{summary.airHours + summary.simulatorHours > 0 && (
							<div>
								<Typography className="summary-title normal-text">
									Hours
								</Typography>
								<Typography className="summary-value">
									{summary.airHours + summary.simulatorHours}
								</Typography>
							</div>
						)}
						{summary.milesFlown > 0 && (
							<div>
								<Typography className="summary-title normal-text">
									Miles
								</Typography>
								<Typography className="summary-value">
									{`${summary.milesFlown} mi`}
								</Typography>
							</div>
						)}
						<div>
							<Typography className="summary-title normal-text">
								Classes left
							</Typography>
							<Typography className="summary-value">
								{summary.upcomingBookings.length}
							</Typography>
						</div>
					</div>
				</div>
			))}
			<div className="courses-button-container">
				{summaries.length > 3 && (
					<div
						onClick={() => {
							setIsShowMore(!isShowMore);
						}}
					>
						<Typography className="normal-text show-more-text">
							{textShowMore}
						</Typography>
					</div>
				)}
				<Link className="normal-text browse-class-text" to="/#experiences">
					Browse Classes
				</Link>
			</div>
		</>
	);
}
