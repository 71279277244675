import React from "react";

import { Button, Grid, Typography } from "@mui/material";
import { FaLocationDot } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";

import { courseType } from "../../../constants";
import useScreenSize from "../../../hooks/useScreenSize";
import { USDFormatter } from "../../../utils";

import "./home_course_item.scss";

const LearnMore = ({ courseSlug }) => {
	return (
		<Link
			className="learn-more bold normal-text"
			to={`/courses/${courseSlug}/preview`}
		>
			Learn More
		</Link>
	);
};

const CourseDescription = ({ title, description, courseSlug }) => {
	return (
		<>
			<div className="course-title">{title}</div>
			<p className="course-description normal-text">{description}</p>
			<LearnMore courseSlug={courseSlug} />
		</>
	);
};

const CourseBoxDescription = ({ course }) => {
	const navigate = useNavigate();

	let total = course.price;
	let hoursFirstLesson = 0;

	if (
		course.lesson &&
		course.lesson.length !== 0 &&
		course.lesson[0].totalCost !== null
	) {
		let { totalCost = 0, teachingTime = 0, flightTime = 0 } = course.lesson[0];
		hoursFirstLesson = (teachingTime + flightTime) / 60;
		total = totalCost;
	}

	const showWhatIsIncluded = course.lifeTimeAccess || course.mentorship || course.coaching;

	return (
		<div className="course-box-description">
			<Typography className="normal-text">
				Starting at
			</Typography>
			<Typography className="price">
				{USDFormatter.format(total)}
			</Typography>
			{course.type !== courseType.ONLINE && (
				<div className="hour">
					{`${hoursFirstLesson.toFixed(2)} Hours`}
				</div>
			)}
			{showWhatIsIncluded && (
				<>
					<div className="what-is-included">
						What's included?
					</div>
					<ul className="what-is-included-list">
						{course.lifeTimeAccess && <li>Lifetime Access</li>}
						{course.mentorship && <li>Mentorship</li>}
						{course.coaching && <li>Live Support</li>}
					</ul>
				</>
			)}
			<Button
				style={{ backgroundColor: "#000000", textTransform: "none" }}
				variant="contained"
				fullWidth
				onClick={() => {
					navigate(`/courses/${course.courseSlug}/checkout`);
				}}
			>
				Enroll Now
			</Button>
		</div>
	);
};

const CourseBoxCarousel = ({ course }) => {
	let document = null;
	if (course.courseDocuments?.length > 0) {
		document = course.courseDocuments[0];
	}
	let renderedElement = null;

	if (document && document.documentType === "COURSE_IMG") {
		renderedElement = (
			<img
				className="course-media"
				src={document.url}
				alt={document.id}
			/>
		);
	} else if (document && document.documentType === "TRAILER") {
		renderedElement = (
			<video className="course-media" controls>
				<source src={document.url} type="video/mp4" />
			</video>
		);
	}

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
			{renderedElement}
			{course.type === "Onsite" && (
				<div style={{ display: "flex", alignItems: "center", gap: 4 }}>
					<div>
						<FaLocationDot className="normal-icon" />
					</div>
					<Typography
						component="div"
						style={{ flex: 1 }}
						className="normal-text"
					>
						{course.location}
					</Typography>
				</div>
			)}
		</div>
	);
};

const CourseBox = ({ course }) => {
	return (
		<div className="course-box">
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6} md={12} lg={12} xl={6}>
					<CourseBoxDescription course={course} />
				</Grid>
				<Grid item xs={12} sm={6} md={12} lg={12} xl={6}>
					<CourseBoxCarousel course={course} />
				</Grid>
			</Grid>
		</div>
	);
};

export const CourseItem = ({ course, index }) => {
	const { width } = useScreenSize();

	if (width <= 768) {
		return (
			<Grid container spacing={2} className="home-course-item-container">
				<Grid item xs={12} sm={12}>
					<CourseBox course={course} />
				</Grid>
				<Grid item xs={12} sm={12}>
					<CourseDescription
						title={course.name}
						description={course.description}
						courseSlug={course.courseSlug}
					/>
				</Grid>
			</Grid>
		);
	}

	if (index % 2 === 1) {
		return (
			<Grid container spacing={2} className="home-course-item-container">
				<Grid item md={7} lg={7} xl={7}>
					<CourseBox course={course} />
				</Grid>
				<Grid item md={5} lg={5} xl={5}>
					<CourseDescription
						title={course.name}
						description={course.description}
						courseSlug={course.courseSlug}
					/>
				</Grid>
			</Grid>
		);
	}
	return (
		<Grid container spacing={2} className="home-course-item-container">
			<Grid item md={5} lg={5} xl={5}>
				<CourseDescription
					title={course.name}
					description={course.description}
					courseSlug={course.courseSlug}
				/>
			</Grid>
			<Grid item md={7} lg={7} xl={7}>
				<CourseBox course={course} />
			</Grid>
		</Grid>
	);
};
